import React from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { baseThemeProps, spacing } from 'commons/theme'

export default function DialogThemeProvider (props) {
  const palette = getPalette(props.color || 'primary')
  return (
    <ThemeProvider theme={createNewTheme(palette, props.color)}>
      { props.children }
    </ThemeProvider>
  )
}

DialogThemeProvider.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

function getPalette (color) {
  switch (color) {
    // 重要事項
    case 'primary':
      return {
        main: '#423BC7',
        contrastText: '#ffffff',
      }
    // 常設チュートリアル
    case 'secondary':
      return {
        main: '#616AFA',
        contrastText: '#ffffff',
        containedButton: '#423BC7',
      }
    // 常設チュートリアル
    case 'advice':
      return {
        main: '#DBDCFE',
        contrastText: '#ffffff',
        containedButton: '#423BC7',
      }
    // Aillからのアナウンス等
    case 'default':
      return {
        main: '#ffffff',
        contrastText: '#423BC7',
      }
    // 初回ブースト
    case 'first-boost':
      return {
        main: '#ffffff',
        contrastText: '#F55083',
      }
    // ウィンターイベント
    case 'winter-event':
      return {
        main: '#C73C51',
        contrastText: '#ffffff',
      }
    // バレンタインイベント
    case 'valentine-event':
      return {
        main: '#E55E7E',
        contrastText: '#ffffff',
      }
    case 'special-offer-event':
      return {
        main: '#F8608B',
        containedButton: '#F8608B',
        contrastText: '#ffffff',
      }
    case 'meeting-boost':
      return {
        main: '#fffff',
        contrastText: '#0CBCFF',
      }
    case 'free-term-extension-challenge-notif':
      return {
        main: '#ffffff',
        contrastText: '#FC7C4E',
      }
    case 'free-term-extension-challenge':
      return {
        main: '#FC7C4E',
        contrastText: '#ffffff',
      }
    case 'first-approve':
      return {
        main: '#ffffff',
        contrastText: '#8C57F7',
      }
    // カグラ
    case 'angel':
      return {
        main: '#E7D6F7',
        contrastText: '#1A1667',
        containedButton: '#ffffff',
      }
    // サカキ
    case 'devil':
      return {
        main: '#D1E0FC',
        contrastText: '#060179',
        containedButton: '#ffffff',
      }
    default:
      return {
        main: '#ffffff',
        contrastText: '#423BC7',
      }
  }
}

function createNewTheme (palette, color) {
  const komainu = color === 'angel' || color === 'devil'
  const event = color.includes('event')
    || color.includes('boost')
      || color.includes('free-term-extension-challenge')
        || color.includes('first-approve')
  const offer = color.includes('special-offer')
  const textStyle = {
    color: palette.contrastText,
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
  }
  const overrides = {
    MuiDialog: {
      paper: {
        maxHeight: 550,
        backgroundColor: palette.main,
        borderRadius: 12,
        boxShadow: '0px 1px 6px #00000029',
        position: 'relative',
      },
      paperWidthSm: {
        maxWidth: komainu ? 338 : event ? '328px' : 289,
        width: komainu ? '100%' : '',
        margin: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(5px) brightness(70%)',
        // Muiデフォルト色を打ち消し（transparent or 0 => safari可, chrome不可）
        backgroundColor: 'rgba(0, 0, 0, 0.003)',
      },
    },
    MuiIconButton: {
      root: {
        color: palette.contrastText,
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: 18,
        fontWeight: 600,
        color: komainu ? palette.main : palette.contrastText,
        lineHeight: '25px',
        textAlign: 'center',
        paddingTop: spacing(4),
        paddingRight: spacing(3.75),
        paddingBottom: spacing(2),
        paddingLeft: spacing(3.75),
        whiteSpace: 'pre-wrap'
      }
    },
    MuiDialogContent: {
      root: {
        fontSize: 16,
        fontWeight: 600,
        color: palette.contrastText,
        paddingTop: 0,
        paddingRight: spacing(3.75),
        paddingBottom: 0,
        paddingLeft: spacing(3.75),
        overflowX: 'hidden',
        '& img': {
          maxWidth: 230,
        },
        '-webkit-overflow-scrolling': 'touch',
        overflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }
    },
    MuiDialogContentText: {
      root: textStyle,
    },
    MuiTypography: {
      root: {
        maxWidth: 230,
        color: palette.contrastText,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        margin: 'auto',
      },
      // サブタイトル
      subtitle1: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '23px',
      },
      // 本文
      body1: {
        color: palette.contrastText,
        fontSize: 13,
        fontWeight: 600,
        lineHeight: '20px',
      },
      // 注意書き
      body2: {
        fontSize: 11,
        fontWeight: 300,
        textAlign: 'left',
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: spacing(4),
        paddingRight: spacing(3.75),
        paddingBottom: spacing(4),
        paddingLeft: spacing(3.75),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& button:nth-of-type(n+2)': {
          marginTop: spacing(1),
          marginBottom: spacing(-2),
        },
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
      },
      contained: {
        width: komainu ? 270 : 230,
        height: komainu ? 45 : 44,
        fontSize: 16,
        color: palette.containedButton ? palette.contrastText : palette.main,
        backgroundColor: palette.containedButton ? `${palette.containedButton} ${offer ? '' : '!important'}` : `${palette.contrastText} !important`,
        borderRadius: 26,
        boxShadow: 'none !important',
        padding: 0,
        '&:disabled': {
          color: '#ffffff',
          backgroundColor: '#e0e0e0 !important',
        },
      },
      outlined: {
        width: 230,
        height: 44,
        fontSize: 16,
        color: palette.contrastText,
        backgroundColor: palette.main,
        border: `${palette.contrastText} 2px solid`,
        borderRadius: 26,
        boxShadow: 'none !important',
        padding: 0,
      },
      text: {
        width: 160,
        height: 42,
        fontSize: 12,
        color: palette.contrastText,
        backgroundColor: palette.main,
        padding: 0,
      }
    }
  }
  return createTheme(
    Object.assign(baseThemeProps(), { overrides })
  )
}
