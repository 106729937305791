import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { calculateHourDiff, calculateMinuteDiff } from 'commons/utility'
import MeetingBoostDialog from 'components/meeting-boost/MeetingBoostDialog'
import BaseComponent from 'components/parts/BaseComponent'
import BannerImg from 'images/meeting-boost/banner_img.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    fontWeight: 600,
    color: '#ffffff',
    background: '#0CBCFF',
    paddingTop: theme.spacing(2.5),
    position: 'relative',
    boxSizing: 'border-box',
  },
  img: {
    position: 'absolute',
    top: 10,
    left: 11.5,
  },
  endAt: {
    fontSize: 18,
  },
  msg: {
    fontSize: 12,
  },
}))

export default function MeetingBoostBanner (props) {
  const { user, mission } = props
  const classes = useStyles()
  const [openMeetingBoostDialog, setOpenMeetingBoostDialog] = useState(false)

  if (!mission?.start_at) { return null }
  if (!mission?.end_at) { return null }

  // 初回ブースト残り時間
  const formatFirstBoostRemainingTime = () => {
    const now = new Date()
    const endTime = new Date(mission?.end_at)
    const hourDiff = calculateHourDiff(now, endTime)
    if (0 < hourDiff) { return `残り${hourDiff}時間！` }
    const minDiff = calculateMinuteDiff(now, endTime)
    return `残り${minDiff}分！`
  }

  return (
    <>
      <div className={classes.root} onClick={() => setOpenMeetingBoostDialog(true)}>
        <img className={classes.img} src={BannerImg} width={40.94} />
        <span className={classes.endAt}>
          {formatFirstBoostRemainingTime()}
        </span>
        <br />
        <span className={classes.msg}>3人にいいね！で、最大9名追加紹介！</span>
      </div>
      <MeetingBoostDialog
        open={openMeetingBoostDialog}
        onClose={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Info.MeetingBoost')
          setOpenMeetingBoostDialog(false)
        }}
        user={user}
        mission={mission}
        fromBanner
      />
    </>
  )
}

MeetingBoostBanner.propTypes = {
  user: PropTypes.object,
  mission: PropTypes.object,
}