import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addSeconds } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
// import BGImg from 'images/free-term-extension-challenge/bg.png'
// import FlagLeft from 'images/free-term-extension-challenge/flag_left.png'
// import FlagRight from 'images/free-term-extension-challenge/flag_right.png'
// import Komainu from 'images/free-term-extension-challenge/koma.png'
// import Ribbon from 'images/free-term-extension-challenge/ribbon.png'
// import Text from 'images/free-term-extension-challenge/text.png'
import Benefit from 'images/first-approve/benefit.png'
import BGImg from 'images/first-approve/bg.png'
import FlagLeft from 'images/first-approve/flag_left.png'
import FlagRight from 'images/first-approve/flag_right.png'
import Komainu from 'images/first-approve/koma.png'
import People3 from 'images/first-approve/people_3.png'
import Title from 'images/first-approve/title.png'
// import TopText from 'images/free-term-extension-challenge/top_text.png'

const useStyles = makeStyles(theme => ({
  content: {
    width: 328,
    backgroundColor: '#E8E1FF',
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    position: 'relative',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  term: {
    width: 328,
    height: 34,
    background: '#A981F8',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& p': {
      fontSize: 14,
      fontWeight :600,
      lineHeight: 1,
      letterSpacing: '1.4px',
      color: '#ffffff',
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    color: '#7A44E6',
    marginBottom: theme.spacing(1.75),
  },
  button: {
    width: 268,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(-3.25),
  },
}))

export default function FirstApproveNotifyDialog (props) {
  const { open, onConfirm, onClose, mission } = props
  const classes = useStyles()

  if (!mission) { return null }
  if (!mission?.end_at) { return null }

  const endAt = new Date(mission.end_at)

  return (
    <DialogThemeProvider color="first-approve">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogContent className={classes.content}>
          <img src={Title} width={312} style={{ marginBottom: 21 }} />
          <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
            <img src={People3} width={67.15} style={{ margin: 0, position: 'relative', top: 8, right: 4 }} />
            <img src={Benefit} width={154} style={{ margin: 0 }} />
            <img src={People3} width={67.15} style={{ margin: 0, position: 'relative', top: 8, left: 4 }} />
          </Grid>
          <Grid className={classes.term} container justifyContent="center" alignItems="center">
            <Typography>{dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} まで</Typography>
          </Grid>
          <Typography className={classes.text}>
            まずは気軽に「いいね！」返信してね♪
          </Typography>
          <img src={FlagLeft} width={27.66} style={{ position: 'absolute', top: 464, left: 82 }} />
          <img src={FlagRight} width={27.66} style={{ position: 'absolute', top: 464, right: 82 }} />
          <img src={Komainu} width={108.45} />
          <Button className={classes.button} variant="contained" onClick={onConfirm}>
            チャレンジ詳細を見る
          </Button>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FirstApproveNotifyDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  mission: PropTypes.object,
}