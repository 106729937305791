import React from 'react'
import PropTypes from 'prop-types'
import WheelPicker from 'react-simple-wheel-picker'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& ul': {
      padding: 0,
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    '& li': {
      paddingRight: theme.spacing(2.5),
      justifyContent: 'flex-end',
      '& p': {
        fontSize: '14px !important',
      },
      '& span': {
        display: 'none',
      },
    },
    '& li[aria-selected="true"]': {
      borderTop: '1px solid #9D9D9E',
      borderBottom: '1px solid #9D9D9E',
    },
    '& li[aria-selected="false"]': {
      opacity: 0.3,
    },
  }
}))

export default function MatchingFilterWheelPicker (props) {
  const { data, selectedID, onChange, ...others } = props
  const classes = useStyles()
  return (
    <div className={classes.root} {...others}>
      <WheelPicker
        data={data}
        selectedID={selectedID}
        onChange={onChange}
        height={55 * 3}
        width={90}
        itemHeight={55}
        color="#414141"
        activeColor="#423BC7"
        backgroundColor="#ffffff"
        shadowColor="none"
      />
    </div>
  )
}

MatchingFilterWheelPicker.propTypes = {
  data: PropTypes.object.isRequired,
  selectedID: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}