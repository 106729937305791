import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addSeconds } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import BGImg from 'images/free-term-extension-challenge/bg.png'
import FlagLeft from 'images/free-term-extension-challenge/flag_left.png'
import FlagRight from 'images/free-term-extension-challenge/flag_right.png'
import Komainu from 'images/free-term-extension-challenge/koma.png'
import Ribbon from 'images/free-term-extension-challenge/ribbon.png'
import Text from 'images/free-term-extension-challenge/text.png'
import Title from 'images/free-term-extension-challenge/title.png'
import TopText from 'images/free-term-extension-challenge/top_text.png'

const useStyles = makeStyles(theme => ({
  content: {
    width: 328,
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    position: 'relative',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  term: {
    width: 328,
    height: 34,
    background: '#FD6356',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& p': {
      fontSize: 14,
      fontWeight :600,
      lineHeight: 1,
      letterSpacing: '1.4px',
      color: '#ffffff',
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: '1.2px',
    color: '#F46E3E',
    marginBottom: theme.spacing(1.75),
    '& span': {
      background: '#ffffff',
      border: '1px solid #F46E3E',
      borderRadius: 14,
      paddingTop: theme.spacing(0.25),
      paddingRight: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
    },
  },
  button: {
    width: 268,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(-3.25),
  },
}))

export default function FreeTermExtensionChallengeNotifyDialog (props) {
  const { open, onConfirm, onClose, friendApplyMission } = props
  const classes = useStyles()

  if (!friendApplyMission) { return null }
  if (!friendApplyMission?.end_at) { return null }

  const endAt = new Date(friendApplyMission.end_at)

  return (
    <DialogThemeProvider color="free-term-extension-challenge-notif">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogContent className={classes.content}>
          <img src={TopText} width={198} style={{ marginBottom: 10 }} />
          <img src={Ribbon} width={290} style={{ marginBottom: 22 }}/>
          <img src={Text} width={85.62} style={{ position: 'absolute', top: 134, left: 26 }} />
          <img src={Title} width={278} />
          <Grid className={classes.term} container justifyContent="center" alignItems="center">
            <Typography>{dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} まで</Typography>
          </Grid>
          <Typography className={classes.text}>
            気軽に
            <span>送信</span>
            ＆
            <span>返信</span>
            してね♪
          </Typography>
          <img src={FlagLeft} width={27.66} style={{ position: 'absolute', top: 434, left: 76 }} />
          <img src={FlagRight} width={27.66} style={{ position: 'absolute', top: 434, right: 76 }} />
          <img src={Komainu} width={108.45} />
          <Button className={classes.button} variant="contained" onClick={onConfirm}>
            チャレンジ詳細を見る
          </Button>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeNotifyDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  friendApplyMission: PropTypes.object,
}
