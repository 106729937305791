import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import HelpIcon from '@material-ui/icons/HelpOutline'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Api from 'commons/api'
import Const from 'commons/constant'
import * as Storage from 'commons/storage'
import {
  httpNotFound,
  httpBadRequest,
  httpForbidden,
  httpConflict,
  fetchResponseErrorMessage,
  expiredUrl,
  validateStringCode,
} from 'commons/utility'
import NaviPopper from 'components/first-navigation/NaviPopper'
import InterestedThingsProfile from 'components/interested-things/InterestedThingsProfile'
import FriendCountExceededDialog from 'components/notifications/FriendCountExceededDialog'
import BaseComponent from 'components/parts/BaseComponent'
import BasicProfile from 'components/parts/BasicProfile.jsx'
import CommonProfile from 'components/parts/CommonProfile'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import EntryButtons from 'components/parts/EntryButtons'
import FriendEstablishedDialog from 'components/parts/FriendEstablishedDialog'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import LoveProfile from 'components/parts/LoveProfile.jsx'
import Rating from 'components/parts/Rating'
import RequestMessageInput from 'components/parts/RequestMessageInput'
import PhotoIssueDialog from 'components/photo-tutorial/PhotoIssueDialog'
import ToMessageTemplateDialog from 'components/to-message/ToMessageTemplateDialog'
import firstBoostLabelImg from 'images/first-boost/first_boost_label.png'
import firstEntryNaviImg from 'images/Komainu/firstEntryNaviImgx2.png'
import firstImpressionImg from 'images/Komainu/firstImpressionNaviImgx2.png'
import firstLikeNaviImg from 'images/Komainu/firstLikeNaviImgx2.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  ratingContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
  },
  likeMessage: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  profileContainer: {
    width: '100%',
  },
  basicProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  loveProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  profileTitle: {
    ...theme.styles.fontSize(14),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.375),
  },
  buttonContainer: {
    width: '100%',
    position: 'fixed',
    bottom: theme.spacing(4.375),
  },
  helpIcon: {
    paddingTop: theme.spacing(0.5),
    fontSize: 22,
    opacity: 0.5,
  },
  backButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: theme.spacing(8),
    height: theme.spacing(6.375),
    padding: 0,
    zIndex: '999'
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
  firstImpression: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  firstImpressionText: {
    ffontSize: 16,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  firstImpressionIcons: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  impressionCheckbox: {
    height: theme.spacing(2.5),
    padding: 0,
  },
  favoFill: {
    color: '#3D45C2',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'inline',
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  favoBorder: {
    color: '#AFB7F8',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'inline',
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  otherButton: {
    width: 268,
    height: 44,
    fontSize: 14,
    color: '#423BC7',
    background: '#ffffff',
    display: 'block',
    margin: 'auto',
    border: '1px solid #423BC7',
    borderRadius: 22,
  },
  templateButton: {
    ...theme.styles.entryButton,
    fontSize: 14,
    fontWeight: 700,
    width: 268,
    height: 44,
    display: 'block',
    marginTop: theme.spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  selfButton: {
    fontSize: 14,
    color: '#423CC7',
    background: '#ffffff',
    border: '1px solid #423CC7',
    fontWeight: 700,
    width: 268,
    height: 44,
    display: 'block',
    marginTop: theme.spacing(1.5),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  note: {
    fontSize: 12,
    fontWeight: 300,
    color: '#423BC7',
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
  },
  requestMessage: {
    fontSize: 12,
    fontWeight: 300,
    ...theme.styles.messageColor,
    background: '#EFF0FF',
    borderRadius: 10,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
    '& strong': {
      fontWeight: 600,
      lineHeight: 1,
      marginBottom: theme.spacing(1.5),
      display: 'block',
    },
    '& div': {
      fontWeight: 600,
      color: '#423BC7',
      lineHeight: 1,
      textAlign: 'right',
    },
  },
  short: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 7,
    '& div': {
      fontWeight: 600,
      color: '#423BC7',
      lineHeight: 1,
      textAlign: 'right',
      position: 'absolute',
      right: 16,
      bottom: 16,
    },
    '& label': {
      width: '100%',
      height: 100,
      position: 'absolute',
      left: 0,
      bottom: 0,
      background: 'linear-gradient(to bottom, rgba(239, 240, 255, 0) 0%, rgba(239, 240, 255, 0.85) 70%)',
    },
  },
}))

export default function Profile (props) {
  const classes = useStyles()
  const { user, friends, matchingState, subscription, tutorialReadStates, tutorialView, ...other } = props
  // ########## state ##########
  const [friendState, setFriendState] = useState(null)
  const [fav, setFav] = useState(null)
  const [isGraduated, setIsGraduated] = useState(false)
  const [favSuggestOpen, setFavSuggestOpen] = useState(false)
  const [likes, setLikes] = useState(null)
  const [impression, setImpression] = useState(0)
  const [openFriendEstablished, setOpenFriendEstablished] = useState(false)
  const [message, setMessage] = useState('')
  const [sendError, setSendError] = useState(false)
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false)
  const [openFriendCountExceededDialog, setOpenFriendCountExceededDialog] = useState(false)
  const [entryMessageFull, setEntryMessageFull] = useState(false)
  const [openPhotoIssueDialog, setOpenPhotoIssueDialog] = useState(false)
  // ########## 初回ナビ関連state ##########
  const [navi02Area, setNavi02Area] = useState(false)
  const [navi03Area, setNavi03Area] = useState(false)

  useLayoutEffect(() => {
    (async () => {
      window.scrollTo(0, 0)
      try {
        const friend = props.friend || Storage.getFriend()
        if (!friend) {
          props.setScreen('Home')
          return
        }
        getLikes(friend.id)
        const grad = await BaseComponent.isGraduated(props)
        setIsGraduated(grad)
        setImpression(friend.impression || Storage.getFriendImpression(friend.id) || 0)
        loadFriend(friend)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [])

  useEffect(() => {
    const friend = props.friend || Storage.getFriend()
    if (!friend) { return }
    BaseComponent.addVisitPageLog({ friend_user_id: friend.id })

    if (friend.type === 'daily') {
      const params = { target: 'Daily', read_ids: [friend.id] }
      Api.createReadState(params)
    }

    if (friend.type === 'friend_receiving' || friend.type === 'friend_pending') {
      const params = { target: 'Entry', read_ids: [friend.id] }
      Api.createReadState(params)
    }

    // スクロール位置監視
    window.addEventListener('scroll', setScroll)

    return () => {
      BaseComponent.addLeavePageLog({ friend_user_id: friend.id })
      window.removeEventListener('scroll', setScroll)
    }
  }, [])

  useEffect(() => {
    if (!tutorialReadStates) { return }
    if (!tutorialReadStates.length === 0) { return }
    if (tutorialReadStates.includes('like')) { return }
    props.setTutorialView('like-01')
  }, [tutorialReadStates])

  const getLikes = async (friendId) => {
    try {
      const res = await Api.getLikes(friendId)
      setLikes(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const loadFriend = async (friend) => {
    if (!friend) return
    props.setLoading(true)
    try {
      if (expiredUrl(friend.photo_icon)) {
        props.setFriend(await BaseComponent.loadFriendAndUpdateFriends(props, friend.id))
      }
      if (!friend.profile_displayed) {
        props.setFriend(await Api.updateFriend(friend.id, { profile_displayed: true }))
      }
      setFriendState(friend)

      if (friend.type !== 'daily' && friend.type !== 'friend_applying' && friend.type !== 'friend_receiving') {
        const favorability = await Api.getFavorability(friend.id)
        setFav(favorability)
      }
    } catch (error) {
      if (httpNotFound(error)) {
        const msg = `${friend.nick_name}さんから友達解消されています。`
        BaseComponent.showErrorMessage(props, msg, () => props.setScreen('Home'))
      } else {
        BaseComponent.handleApiError(props, error)
      }
    } finally {
      props.setLoading(false)
    }
  }

  // スクロール位置を参照し、ナビを表示する領域を設定
  const setScroll = () => {
    setNavi02Area((100 < window.scrollY && window.scrollY < 300))
    setNavi03Area(400 < window.scrollY)
  }

  // ↑の副作用hook 各初回ナビ02,03を表示
  useEffect(() => {
    if (navi02Area && !tutorialReadStates.includes('like')) {
      props.setTutorialView('like-02')
    } else if (navi03Area && !tutorialReadStates.includes('first_impression')) {
      props.setTutorialView('first-impression-01')
    }
  }, [navi02Area, navi03Area])

  const handleChangeImpression = (imp) => {
    if (friendState.type === 'friend_applying') { return }
    if (tutorialView === 'first-impression-01') {
      BaseComponent.gaEvent('初回ナビ', 'click', '第一印象')
    }
    props.setTutorialView(null)
    Storage.setFriendImpression(friendState.id, imp)
    setImpression(imp)
  }

  // setMessageの副作用hook スクロールしてNavi04の表示位置調整
  useEffect(() => {
    if (tutorialView !== 'entry-01') { return }
    window.scrollBy({ top: -1, left: 0, behavior: 'smooth' })
  }, [tutorialView])

  const displayFavPoint = (fav_point_to_me) => {
    if (friendState.type === 'daily') return false
    if (friendState.type === 'friend_applying') return false
    if (isGraduated) return false
    if (friendState.type === 'friend_receiving' && fav_point_to_me <= 50) return false
    return true
  }

  const validateMessage = (msg) => {
    if (!msg) { return true }
    if (Const.minFirstChatMessageLength > msg.length) { return true }
    if (Const.maxChatMessageLength < msg.length) { return true }
    if (!validateStringCode(msg)) { return true }
    return false
  }

  const sendEntries = async () => {
    if (!friendState) { return }
    if (tutorialView === 'entry-01') {
      BaseComponent.gaEvent('初回ナビ', 'click', '友達申請')
      props.setTutorialView(null)
    }
    const type = friendState.type
    const entryType = type === 'daily' ? 'apply_friend' : type === 'friend_receiving' ? 'approve_friend' : null
    if (validateMessage(message)) {
      setSendError(true)
      return
    }

    props.setLoading(true)
    try {
      const friendId = friendState.id
      const opts = {
        impression: (entryType === 'apply_friend' || entryType === 'approve_friend') && impression,
        to_message: message || '',
      }
      await BaseComponent.postEntry(props, friendId, entryType, opts)
      await BaseComponent.loadFriends(props)
      Storage.clearFriendImpression(friendId)
      Storage.clearFriendEntryMessage(friendId)
      // 「いいね！」承認時: FriendEstablishedDialog
      setOpenFriendEstablished(entryType === 'approve_friend')
      // 「いいね！」送信時: /Homeに遷移
      if (entryType === 'apply_friend') {
        BaseComponent.showRequestSuccessMessage(props, '「いいね！」を送信しました', null, tutorialView === 'entry-01')
        props.setScreen('/Home')
      }
    } catch (error) {
      const msg = await getEntryErrorMessage(error)
      if (msg) {
        BaseComponent.showErrorMessage(props, msg)
      } else if (msg === null) {
        BaseComponent.handleApiError(props, error)
      }
    } finally {
      const firstBoostEnabled = BaseComponent.isFirstMatchingBoostEnabled(props)
      if (firstBoostEnabled) {
        BaseComponent.gaEvent('初回紹介ブースト', 'click', '対象者への友達申請')
      }
      if (friendState.boost_title) {
        BaseComponent.gaEvent('初回紹介ブースト', 'click', '特典紹介者への友達申請')
      }
      props.setLoading(false)
    }
  }

  // 申請失敗時のエラーメッセージ
  const getEntryErrorMessage = async (error) => {
    if (httpConflict(error)) {
      const maxFriendCount = BaseComponent.getMaxFriendCount(subscription)
      const nowFriendCount = BaseComponent.getNowFriendCount(friends)
      if (maxFriendCount <= nowFriendCount) {
        setOpenFriendCountExceededDialog(true)
        return ''
      }
    } else if (httpForbidden(error)) {
      return '「いいね！」はすでに送信済みです。'
    } else if (httpBadRequest(error)) {
      return (await fetchResponseErrorMessage(error))
    } else {
      return null
    }
  }

  const closeFriendEstablishedDialog = () => {
    setOpenFriendEstablished(false)
    props.setScreen('FriendRequest')
  }

  if (!friendState) { return null }

  const receiving = friendState.type === 'friend_receiving' || friendState.type === 'lover_receiving'
  const pending = friendState.type === 'friend_pending'
  const msg = receiving ? friendState.entry_request_message : pending ? friendState.entry_response_message : ''

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={favSuggestOpen}
        onClose={() => setFavSuggestOpen(false)}
        onOk={() => setFavSuggestOpen(false)}
        onCancel={() => setFavSuggestOpen(false)}
        tutorial="yes"
        title="好感度"
        message={BaseComponent.getFavSuggestMessage()}
      />
      <FriendCountExceededDialog
        {...props}
        open={openFriendCountExceededDialog}
        onClose={() => setOpenFriendCountExceededDialog(false)}
        friend={friendState}
        subscription={subscription}
      />
      <div className={classes.profileContainer}>
        <IconButton className={classes.backButton} onClick={() => BaseComponent.goBack(props)} data-testid="back-button">
          <LeftIcon className={classes.backIcon} />
        </IconButton>
        <CommonProfile
          {...other}
          user={user}
          component={this}
          friend={friendState}
        />
        {displayFavPoint(fav ? fav.point_to_me : friendState.fav_point_to_me) && (
          <Grid
            className={classes.ratingContainer}
            container
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography>
              好感度：
            </Typography>
            <Rating point={fav ? fav.point_to_me : friendState.fav_point_to_me} />
            <IconButton onClick={() => setFavSuggestOpen(true)} >
              <HelpIcon className={classes.helpIcon} color="secondary" />
            </IconButton>
          </Grid>
        )}
        {(likes || isGraduated) && (
          <div>
            <div className={classes.basicProfileContainer}>
              <Typography className={classes.profileTitle}>
                興味のあること
              </Typography>
              <InterestedThingsProfile {...other} user={friendState} />
            </div>
            <div className={classes.basicProfileContainer}>
              <Typography className={classes.profileTitle}>
                基本情報
              </Typography>
              <BasicProfile
                {...props}
                isLikes={isGraduated ? false : true}
                likes={likes}
                friend={friendState}
              />
            </div>
            <div className={classes.loveProfileContainer}>
              <Typography className={classes.profileTitle}>
                恋愛情報
              </Typography>
              <LoveProfile
                isLikes={isGraduated ? false : true}
                likes={likes}
                {...other}
                friend={friendState}
              />
            </div>
            {(friendState.type === 'friend_receiving' && friendState.entry_request_message) && (
              <Typography className={`${classes.requestMessage} ${entryMessageFull ? '' : classes.short}`} onClick={() => setEntryMessageFull(!entryMessageFull)}>
                <strong>♥あなたへのメッセージ♥</strong>
                {BaseComponent.textConvertMultiline(msg)}
                <label />
                <div>{entryMessageFull ? '短く表示する' : '全文表示する'}</div>
              </Typography>
            )}
            {(friendState.type === 'daily' || friendState.type === 'friend_receiving' || friendState.type === 'friend_applying') && (
              <>
                <div className={classes.firstImpression}>
                  <Typography id="first-impression-tutorial-target" className={classes.firstImpressionText}>
                    {friendState.nick_name}さんに対する第一印象{friendState.type !== 'friend_applying' && 'は？'}
                  </Typography>
                  <Grid
                    className={classes.firstImpressionIcons}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {[1, 2, 3, 4, 5].map(imp => {
                      return (
                        <Checkbox
                          key={imp}
                          onChange={() => handleChangeImpression(imp)}
                          icon={<FavoriteBorderIcon className={classes.favoBorder} />}
                          checkedIcon={<FavoriteIcon className={classes.favoFill} />}
                          checked={impression >= imp}
                          className={classes.impressionCheckbox}
                          inputProps={{ 'data-testid': `checkbox-impression-${imp}` }}
                        />
                      )
                    })}
                  </Grid>
                </div>
                <RequestMessageInput
                  friend={friendState}
                  value={message}
                  onChange={(v) => setMessage(v)}
                  error={validateMessage(message)}
                />
              </>
            )}
            {(friendState.type === 'daily') && (
              <>
                <Button
                  className={classes.templateButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenTemplateDialog(true)}
                >
                  テンプレートで作成する
                </Button>
                <ToMessageTemplateDialog
                  {...other}
                  open={openTemplateDialog}
                  onClose={async () => {
                    await setOpenTemplateDialog(false)
                    // スクロールして初回ナビ再表示時の表示位置調整
                    window.scrollBy({ top: -1, left: 0, behavior: 'smooth' })
                  }}
                  onChange={(v) => setMessage(v)}
                  user={user}
                  friend={friendState}
                  master={props.master}
                />
              </>
            )}
            {(friendState.type === 'daily' || friendState.type === 'friend_receiving' || friendState.type === 'friend_applying') && (
              <>
                <Button
                  className={classes.selfButton}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenPhotoIssueDialog(true)}
                >
                  いいね！一覧での見え方を確認
                </Button>
                <Typography className={classes.note}>
                  ＊入力済みのメッセージは保存されています
                </Typography>
                <PhotoIssueDialog
                  {...props}
                  open={openPhotoIssueDialog}
                  onClose={() => setOpenPhotoIssueDialog(false)}
                  user={user}
                  type="FriendRequest"
                  message={message}
                />
              </>
            )}
            <ImportantConfirmDialog
              open={sendError}
              onClose={() => setSendError(false)}
              confirm="yes"
              title={'いいね！送信に失敗しました'}
              message={`メッセージが${Const.maxChatMessageLength}文字を超えています`}
            />
            <FriendEstablishedDialog
              {...other}
              open={openFriendEstablished}
              onClose={closeFriendEstablishedDialog}
            />
            <NaviPopper
              {...props}
              id="like-01"
              open={tutorialView === 'like-01' && !openTemplateDialog}
              anchorEl={document.getElementById('like-tutorial-target-01')}
              title={'詳細ページで\nスクロールしてみよう！\n↓'}
              onClose={() => props.setTutorialView(null)}
            />
            <NaviPopper
              {...props}
              id="like-02"
              open={tutorialView === 'like-02' && !openTemplateDialog}
              anchorEl={document.getElementById('like-tutorial-target-02')}
              title={'いいなと思った項目の\n好印象ボタンを押そう！'}
              text={'下までスクロールしてください'}
              img={firstLikeNaviImg}
              placement={'bottom'}
              onClose={() => props.setTutorialView(null)}
              canselButton
            />
            <NaviPopper
              {...props}
              id="first-impression-01"
              open={tutorialView === 'first-impression-01' && !openTemplateDialog}
              anchorEl={document.getElementById('first-impression-tutorial-target')}
              title={'第一印象を入力しよう！'}
              text={'第一印象を押して\n一言メッセージを入力すると\n「いいね！」を送ることができるよ'}
              img={firstImpressionImg}
              placement={'top'}
              onClose={() => props.setTutorialView(null)}
              arrow
              canselButton
            />
            <NaviPopper
              {...props}
              id="entry-01"
              open={tutorialView === 'entry-01' && !openTemplateDialog}
              anchorEl={document.getElementById('entry-tutorial-target')}
              title={'気軽に「いいね！」を\n送ってみよう！'}
              text={`10文字以上のメッセージを添えよう\n\n${subscription?.content === Const.planTypes.STANDARD_ORG_PAID ? ''
                : '7日間無料の間に\n'}たくさんの異性とトークしてみよう\n\n相手も「いいね！」をすると\nトークが開設`}
              img={firstEntryNaviImg}
              child={(matchingState && matchingState.first_boost_enabled) ? (
                <img src={firstBoostLabelImg} style={{ width: 216 }} />
              ) : null}
              placement={'top'}
              onClose={() => props.setTutorialView(null)}
              arrow
              canselButton
            />
          </div>
        )}
      </div>
      {!isGraduated && (
        <div className={classes.buttonContainer}>
          <EntryButtons
            {...other}
            user={user}
            friend={friendState}
            friends={friends}
            subscription={subscription}
            sendEntries={sendEntries}
            disabled={impression === 0 || validateMessage(message)}
          />
          {tutorialView === 'entry-01' && (
            <Button
              variant="outlined"
              className={classes.otherButton}
              onClick={() => props.setScreen('Home')}
            >
              他の人を見る
            </Button>
          )}
        </div>
      )}
      <div style={{
        height: (friendState.type === 'friend_receiving' || friendState.type === 'lover_receiving' || tutorialView === 'entry-01') ? 160 : 135,
      }} />
      <div id="like-tutorial-target-01" style={{ position: 'absolute', top: '50%', left: '50%' }} />
    </div>
  )
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  friend: PropTypes.object,
  friends: PropTypes.array,
  matchingState: PropTypes.object,
  subscription: PropTypes.object,
  setLoading: PropTypes.func,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
  master: PropTypes.object,
  tutorialReadStates: PropTypes.array,
  tutorialView: PropTypes.string,
  setTutorialView: PropTypes.func,
}
