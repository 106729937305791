import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Kagura01 from 'images/komanavi/kagura01.png'
import Kagura02 from 'images/komanavi/kagura02.png'
import Kagura03 from 'images/komanavi/kagura03.png'
import Kagura04 from 'images/komanavi/kagura04.png'
import Sakaki01 from 'images/komanavi/sakaki01.png'
import Sakaki02 from 'images/komanavi/sakaki02.png'
import Sakaki03 from 'images/komanavi/sakaki03.png'
import Sakaki04 from 'images/komanavi/sakaki04.png'

const useStyles = makeStyles(() => ({
  primary: {
    backgroundColor: '#423BC7',
    border: '3px solid #616AFA',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: 'none',
    },
    '& #komanavi-text': {
      fontSize: 12,
      maxWidth: 192,
      color: '#ffffff',
      fontWeight: 600,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
  },
  secondary: {
    backgroundColor: '#EFF0FF',
    border: '3px solid #B0B4FC',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: '0px 1px 8px #00000033',
    },
    '& #komanavi-text': {
      fontSize: 12,
      maxWidth: 192,
      color: '#423BC7',
      fontWeight: 600,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
  },
  request: {
    backgroundColor: '#DBDCFE',
    border: '3px solid #423CC7',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: '0px 1px 8px #00000033',
    },
    '& #komanavi-text': {
      fontSize: 12,
      maxWidth: 192,
      color: '#423BC7',
      fontWeight: 600,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
  },
  komanavi: {
    height: 70,
    position: 'relative',
  },
  kagura: {
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: primary => primary ? 22 : 26,
    left: 8,
  },
  sakaki: {
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: primary => primary ? 30 : 26,
    right: 8,
  },
}))

export default function KomaNavi (props) {
  const { friend, index, children, readIds } = props
  if (!friend) { return children }
  const message = friend?.recommend_message || 'プロフィールを要チェック！'
  const primary = (friend.type === 'daily' && index === 0)
  const secondary = (friend.type === 'daily' && index !== 0 && !(readIds || []).includes(friend.id))
  const request = (friend.type === 'friend_receiving' && !(readIds || []).includes(friend.id))
  const classes = useStyles(Boolean(primary))

  // 何番目のKomanaviかによって表示する狛犬の画像を判定
  const createKaguraOrSakaki = () => {
    const imgs = [Kagura02, Sakaki02, Kagura03, Sakaki03, Kagura04, Sakaki04]
    const navis = document.querySelectorAll('[id^="komanavi-root-"]')
    const i = Array.from(navis).map(n => n.id).indexOf(`komanavi-root-${index}`)
    if (i < 0) { return null }
    const img = imgs[i < 6 ? i : i - 6]
    const className = i % 2 == 0 ? classes.kagura : classes.sakaki
    return <img src={img} className={className} />
  }

  // 紹介画面一番上
  if (primary) {
    return (
      <Grid id={`komanavi-${index}`} className={classes.primary} data-testid='komanavi'>
        <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
          <img src={Kagura01} className={classes.kagura} />
          <div id="komanavi-text"><span>{message}</span></div>
          <img src={Sakaki01} className={classes.sakaki} />
        </Grid>
        {children}
      </Grid>
    )
  }

  // 紹介画面2番目以降 && プロフィール詳細未読
  if (secondary) {
    return (
      <Grid id={`komanavi-root-${index}`} className={classes.secondary}>
        <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
          {createKaguraOrSakaki()}
          <div id="komanavi-text"><span>{message}</span></div>
        </Grid>
        {children}
      </Grid>
    )
  }

  // いいね！受信一覧画面 && プロフィール詳細未読
  if (request) {
    return (
      <Grid id={`komanavi-root-${index}`} className={classes.request}>
        <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
          {createKaguraOrSakaki()}
          <div id="komanavi-text"><span>{message}</span></div>
        </Grid>
        {children}
      </Grid>
    )
  }

  return children
}

KomaNavi.propTypes = {
  friend: PropTypes.object,
  index: PropTypes.number,
  children: PropTypes.object,
  readIds: PropTypes.array,
}
