import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FirstApproveProgressDialog from 'components/first-approve/FirstApproveProgressDialog'
import BaseComponent from 'components/parts/BaseComponent'
import BannerImg from 'images/first-approve/banner_img.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#8C57F7',
    backgroundImage: `url(${BannerImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(2.5),
    position: 'relative',
    boxSizing: 'border-box',
  },
  msg1: {
    fontSize: 18,
    fontWeight: 600,
    color: '#ffffff',
  },
  msg2: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
}))

export default function FirstApproveBanner (props) {
  const { mission } = props
  const classes = useStyles()
  const [openFirstApproveProgressDialog, setOpenFirstApproveProgressDialog] = useState(false)

  if (!mission?.start_at) { return null }
  if (!mission?.end_at) { return null }

  return (
    <>
      <div className={classes.root} onClick={() => setOpenFirstApproveProgressDialog(true)}>
        <Typography className={classes.msg1}>いいね！返信で出会いを増やそう！</Typography>
        <Typography className={classes.msg2}>2人にいいね！返信で、最大6名追加紹介！</Typography>
      </div>
      <FirstApproveProgressDialog
        open={openFirstApproveProgressDialog}
        onClose={async () => {
          await BaseComponent.createReadStateDialogDisplayed(props, 'Progress.FirstApprove')
          setOpenFirstApproveProgressDialog(false)
        }}
        mission={mission}
        fromBanner
      />
    </>
  )
}

FirstApproveBanner.propTypes = {
  mission: PropTypes.object,
}
