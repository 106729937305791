import React, { useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { getYobiString } from 'commons/utility'
// import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import RugbyImg from 'images/rugby_ticket_present_img.png'

const useStyles = makeStyles(theme => ({
  content: {
    height: 400,
    paddingTop: `${theme.spacing(3.5) }px !important`,
    paddingLeft: theme.spacing(3.75),
    position: 'relative',
    overflow: 'unset',
    backgroundImage: `url(${RugbyImg})`,
    backgroundSize: 308,
    backgroundPositionX: -10,
    backgroundPositionY: -8,
  },
  subtitle: {
    fontSize: 22,
    fontWeight: 800,
    color: '#000000',
    lineHeight: '30px',
    letterSpacing: '0.44px',
    textAlign: 'left',
  },
  text: {
    width: '100%',
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: 1,
    textAlign: 'left',
    marginLeft: '0 !important',
    marginRight: '0 !important',
  },
  bunnerText: {
    maxWidth: 245,
    height: 38,
    fontWeight: 800,
    color: '#000000',
    background: '#ffffff',
    lineHeight: 1,
    textAlign: 'left',
    position: 'absolute',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(4.25),
    borderBottom: '4px solid #FF0319',
    left: 0,
  },
  actions: {
    paddingTop: theme.spacing(4.5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
  },
}))

export default function DocomoRugbyTicketPresentDialog (props) {
  const { open, onClose, pair, gameInfo } = props
  const classes = useStyles()

  useEffect(() => {
    (async () => {
      if (!open) { return }
      try {
        // await BaseComponent.createReadStateDialogDisplayed(props, 'RugbyTicketPresent20250208')
      } catch {
        // ignore
      }
    })()
  }, [open])

  if (!gameInfo) { return null }

  const onApply = () => {
    window.open(gameInfo.form_url)
    onClose()
  }

  const startAt = gameInfo.start_at

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogThemeProvider color="default">
          <span style={{ zIndex: 1 }}>
            <DialogCloseButton onClick={onClose} />
          </span>
        </DialogThemeProvider>
        <DialogContent className={classes.content}>
          <Typography className={classes.text} style={{ fontSize: 12, fontWeight: 900, marginBottom: 29 }}>
            NTTドコモ x Aill 企画
          </Typography>
          <Typography className={classes.text} style={{ fontSize: 12, marginBottom: 12 }}>
            {pair ? ' ' : 'あなたへのスペシャルオファー'}
          </Typography>
          <Typography className={classes.subtitle}>
            {pair ? '次のデートは\nふたりで熱狂しよう。' : 'ラグビー観戦に\n出かけよう！'}
          </Typography>
          <Typography className={classes.bunnerText} style={{ width: pair ? 213 : 245, fontSize: 20, top: 341 }}>
            {pair ? '観戦ペアチケット' : 'ラグビー観戦チケット'}
          </Typography>
          <Typography className={classes.bunnerText} style={{ width: 228, top: 387, paddingTop: 0 }}>
            <span style={{ fontSize: 14 }}>先着</span>&nbsp;
            <span style={{ fontSize: 30, position: 'relative', top: 4 }}>10</span>&nbsp;
            <span style={{ fontSize: 14 }}>{pair ? '組' : '名'}</span>
            <span style={{ fontSize: 18 }}>プレゼント!!</span>
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Typography className={classes.text} style={{ fontSize: 12, lineHeight: '16px', marginBottom: 12 }}>
            {gameInfo.league}<br />
            {gameInfo.division}<br />
            {gameInfo.section}<br />
            {gameInfo.match_up}
          </Typography>
          <Typography className={classes.text}>
            <u style={{ fontSize: 16 }}>
              {dateFormat(startAt, 'm/d')}
              &nbsp;
              ({getYobiString(new Date(startAt))})
              &nbsp;
              {dateFormat(startAt, 'HH:MM')}
            </u>
            &nbsp;&nbsp;
            <span style={{ fontSize: 14 }}>試合開始</span>
          </Typography>
          <Typography className={classes.text} style={{ fontSize: 12, lineHeight: '16px', marginTop: 10, marginBottom: 16 }}>
            {gameInfo.venue}<br />
            {gameInfo.nearest_stations.map((station => `${station}\n`))}
          </Typography>
          <Button variant="contained" onClick={onApply}>
            応募する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

DocomoRugbyTicketPresentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pair: PropTypes.string,
  gameInfo: PropTypes.object,
}
