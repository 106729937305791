import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AillClubButton from 'components/aill-club/AillClubButton'
import BottomImg from 'images/aill-club/bottom_photo_sp.png'
import KomaBottom from 'images/aill-club/komainu_bottom_sp.png'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#E9EEF4',
    minHeight: 619,
    position: 'relative',
  },
  bgimg: {
    backgroundImage: `url(${BottomImg})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: '40px',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: sp => sp ? 14 : 15,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '22px',
    textAlign: 'center',
  },
  img: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  container: {
    width: 795,
    height: 615,
    margin: 'auto',
    position: 'relative',
  },
  leftbox: {
    width: 309,
    height: '100%',
    position: 'absolute',
    left: 0,
  },
  rightbox: {
    width: 446,
    height: '100%',
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundImage: `url(${BottomImg})`,
    backgroundSize: 446,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '100%',
  },
}))

export default function BottomContainer (props) {
  const { onClose } = props
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  if (spMatches) {
    return (
      <div className={`${classes.root} ${classes.bgimg}`}>
        <Typography className={classes.title} style={{ paddingTop: 44, marginBottom: 28 }}>
          ずっとこれからも<br />
          二人の日々を応援
        </Typography>
        <Typography className={classes.subtitle}>
          Aill Clubではお二人を応援する企画を<br />
          どんどん検討中！お楽しみに♪
        </Typography>
        <img className={classes.img} src={KomaBottom} width={274.47} style={{
          position: 'absolute',
          transform: 'translate(-50%, -0%)',
          left: '50%',
          bottom: 20,
        }} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftbox}>
          <Typography className={classes.title} style={{ paddingTop: 108, marginBottom: 28 }}>
            ずっとこれからも<br />
            二人の日々を応援
          </Typography>
          <Typography className={classes.subtitle} style={{ marginBottom: 21 }}>
            Aill Clubではお二人を応援する企画を<br />
            どんどん検討中！お楽しみに♪
          </Typography>
          <img className={classes.img} src={KomaBottom} width={241.66} style={{ marginBottom: 21 }} />
          <AillClubButton onClose={onClose} />
        </div>
        <div className={classes.rightbox}>
        </div>
      </div>
    </div>
  )
}

BottomContainer.propTypes = {
  onClose: PropTypes.func,
}
