import React from 'react'
import { isNullOrUndefined } from 'util'
import * as Sentry from '@sentry/react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { FormControl, FormHelperText , InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import Config from 'commons/config'
import Const from 'commons/constant'
import ExternalApi from 'commons/external_api'
import * as Storage from 'commons/storage'
import storage from 'commons/storage'
import { fetchResponseErrorMessage, isGlobalNotificationType, getFriendTopicSuggestion } from 'commons/utility'
import {
  getFileExt,
  expiredUrl,
  httpNotFound,
  httpNotModified,
  networkConnectionError,
  isProfilePublicFieldName,
  isProfilePrivateFieldName,
  isEmpty,
  isCompletedLoveSheet,
  addDate,
} from 'commons/utility'
import AIcon from 'images/img_ai_chat.png'
import history from '../../history'

//カタカナ全角→半角変換
function kanaZenkakuToHankaku (str) {
  let zenkaku = [
    'ア','イ','ウ','エ','オ','カ','キ','ク','ケ','コ'
    ,'サ','シ','ス','セ','ソ','タ','チ','ツ','テ','ト'
    ,'ナ','ニ','ヌ','ネ','ノ','ハ','ヒ','フ','ヘ','ホ'
    ,'マ','ミ','ム','メ','モ','ヤ','ヰ','ユ','ヱ','ヨ'
    ,'ラ','リ','ル','レ','ロ','ワ','ヲ','ン'
    ,'ガ','ギ','グ','ゲ','ゴ','ザ','ジ','ズ','ゼ','ゾ'
    ,'ダ','ヂ','ヅ','デ','ド','バ','ビ','ブ','ベ','ボ'
    ,'パ','ピ','プ','ペ','ポ'
    ,'ァ','ィ','ゥ','ェ','ォ','ャ','ュ','ョ','ッ'
    ,'゛','°','、','。','「','」','ー','・'
  ]
  let hankaku = [
    'ｱ','ｲ','ｳ','ｴ','ｵ','ｶ','ｷ','ｸ','ｹ','ｺ'
    ,'ｻ','ｼ','ｽ','ｾ','ｿ','ﾀ','ﾁ','ﾂ','ﾃ','ﾄ'
    ,'ﾅ','ﾆ','ﾇ','ﾈ','ﾉ','ﾊ','ﾋ','ﾌ','ﾍ','ﾎ'
    ,'ﾏ','ﾐ','ﾑ','ﾒ','ﾓ','ﾔ','ｲ','ﾕ','ｴ','ﾖ'
    ,'ﾗ','ﾘ','ﾙ','ﾚ','ﾛ','ﾜ','ｦ','ﾝ'
    ,'ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ'
    ,'ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ'
    ,'ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ'
    ,'ｧ','ｨ','ｩ','ｪ','ｫ','ｬ','ｭ','ｮ','ｯ'
    ,'ﾞ','ﾟ','､','｡','｢','｣','ｰ','･'
  ]
  let strAfter = ''

  for (let i = 0; i < str.length; i++) {
    let strchar = str.charAt(i)
    let index = zenkaku.indexOf(strchar)
    if (index >= 0){
      strchar = hankaku[index]
    }
    strAfter += strchar
  }

  return strAfter
}

function findLastUpdatedTime (objects) {
  if (objects && objects.length > 0) {
    let updatedTimes = objects.map(obj => obj.updated_at).sort()
    return updatedTimes[updatedTimes.length - 1]
  } else {
    return null
  }
}

// 共通基底Component
// TODO API呼び出しはapi.jsへ、その後のprops更新はここへ移動する。
export default class BaseComponent extends React.Component {
  // ----- history -----
  static goBack (props) {
    if (!history?.length || history.length <= 2 || history?.location?.pathname === '/Facebook') {
      props.setScreen('Home')
    } else {
      history.goBack()
    }
  }
  // ----- API系 -----

  // API共通エラーハンドリング
  handleApiError (error) {
    return BaseComponent.handleApiError(this.props, error)
  }

  static async handleApiError (props, error) {
    console.log(error)

    if (!error.response) {
      if (networkConnectionError(error)) {
        BaseComponent.showErrorMessage(props, '通信に失敗しました。電波状況の良い所で再度お試しください。')
      } else {
        Sentry.captureException(error)
        try {
          Api.addErrorLog(error)
        } catch {
          //
        }
        BaseComponent.showErrorMessage(props, '予期しないエラーが発生しました。')
      }
      return true
    }

    switch (error.response.status) {
      case 401:
        // セッション無効
        await BaseComponent.clearSession(props)
        return true
      case 404:
        Sentry.captureException(error)
        BaseComponent.showErrorMessage(
          props, '対象のリソースが存在しません。ホームへ戻ります。',
          () => props.setScreen('Home')
        )
        return true
      default:
        Sentry.captureException(error)
        return BaseComponent.handleDefaultApiError(props, error)
    }
  }

  static handleError (props, error, msg = '予期しないエラーが発生しました。') {
    Sentry.captureException(error)
    BaseComponent.showErrorMessage(props, msg)
  }

  static handleDefaultApiError (props, error) {
    const contentType = error.response.headers.get('Content-Type')
    if (contentType && contentType.indexOf('application/json') === 0) {
      fetchResponseErrorMessage(error).then(errorMessage => {
        if (errorMessage) {
          BaseComponent.showErrorMessage(props, errorMessage)
        }
      }).catch(e => { console.log(e) })
      return true
    }
    return false
  }

  showErrorMessage (message, onClose = null) {
    BaseComponent.showErrorMessage(this.props, message, onClose)
  }

  static showErrorMessage (props, message, onClose = null) {
    props.setErrorMessage(message, onClose)
  }

  showImportantErrorMessage (message, onClose = null) {
    BaseComponent.showImportantErrorMessage(this.props, message, onClose)
  }

  static showImportantErrorMessage (props, message, onClose = null) {
    props.setImportantErrorMessage(message, onClose)
  }

  showRequestSuccessMessage (message, onClose = null, firstNaviFlag = false) {
    BaseComponent.showRequestSuccessMessage(this.props, message, onClose, firstNaviFlag)
  }

  static showRequestSuccessMessage (props, message, onClose = null, firstNaviFlag = false) {
    props.setRequestSuccessMessage(message, onClose, firstNaviFlag)
  }

  // 保存してある認証トークン等を削除
  async clearSession () {
    await BaseComponent.clearSession(this.props)
  }

  static async clearSession (props) {
    props.setAuthId('')
    props.setMailAddress('')
    props.setPassword('')
    props.setUserId('')

    // AppAuth#render()により '/' へリダイレクトされる
    await Storage.clearToken()
    Storage.clearFriend()
    Storage.clearResources()
    Storage.clearConfigs()
    Storage.clearDisplayedHearingDialogCount()
    props.setIsLogin(false)
  }

  // ログイン
  async login (mailAddress, password, userType = 'user', mode = 'app') {
    return BaseComponent.login(this.props, mailAddress, password, userType, mode)
  }

  static async login (props, mailAddress, password, userType = 'user', mode = 'app') {
    const data = await Api.login(mailAddress, password, userType, mode)
    props.setAuthId(data.id)
    props.setMailAddress(mailAddress)
    props.setPassword(password)
    props.setUserId(data.user?.id)
    // アプリの場合LoginComponent側でTokenをセットする
    if (Config.deployMode !== 'app') {
      data?.token && await BaseComponent.setToken(props, data.token)
    }
    return { user: data.user, token: data.token, needSmsVerification: data?.sms_verification_code_sent }
  }

  // ログアウト
  async logout () {
    this.props.setLoading(true)
    try {
      BaseComponent.gaEvent('menu', 'click', 'logout')
      await Api.addActionLog('logout')
      await Api.logout()
      Sentry.setUser({})
      this.props.setLoading(false)
      await this.clearSession()
    } catch (error) {
      this.props.setLoading(false)
      if (!this.handleApiError(error)) {
        await this.clearSession()
      }
    }
  }

  async setToken (token) {
    await BaseComponent.setToken(this.props, token)
  }

  static async setToken (props, token) {
    try {
      await Storage.setToken(token)
      await Api.addActionLog('login')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // セッション有効確認
  async validateSession () {
    let auth = await Api.getAuth()
    this.props.setIsLogin(true)
    this.props.setAuthId(auth.id)
    this.props.setMailAddress(auth.mail_address)
    this.props.setUserId(auth.user_id)
    this.props.setAdmin(auth.admin)
    this.props.setPhoneNumber(auth?.phone_number, auth?.is_phone_verified)
    this.props.setDisableSmsVerification(auth?.temporary_disable_sms_verification)
    return auth
  }

  // ページを表示するときのログを追加
  async addVisitPageLog (params = {}) {
    BaseComponent.addVisitPageLog(params)
  }

  static async addVisitPageLog (params = {}) {
    try {
      const log = await Api.addActionLog('visit_page', null, params)
      if (log) {
        BaseComponent.location = log.location
      }
    } catch {
      //
    }
  }

  // ページを離れるときのログを追加
  async addLeavePageLog (params = {}) {
    BaseComponent.addLeavePageLog(params)
  }

  static async addLeavePageLog (params = {}) {
    if (BaseComponent.location) {
      Api.addActionLog('leave_page', BaseComponent.location, params)
      BaseComponent.location = null
    }
  }

  // 自分のUserレコードが更新されていれば取得
  // アイコン画像URLが期限切れなら強制取得
  async loadUser (forceLoad = false) {
    return BaseComponent.loadUser(this.props, forceLoad)
  }

  static async loadUser (props, forceLoad = false) {
    let lastUpdatedTime = null
    if (!forceLoad && props.user && !expiredUrl(props.user.photo_icon)) {
      lastUpdatedTime = props.user.updated_at
    }
    try {
      const user = await Api.getUser(lastUpdatedTime)
      props.setUser(user)
      return user
    } catch (error) {
      if (httpNotModified(error)) {
        return props.user
      } else {
        throw error
      }
    }
  }

  // 自分のUserレコードを強制的に再取得
  static async forceLoadUser (props) {
    await BaseComponent.loadUser(props, true)
  }

  // 自分のUserレコード更新
  async updateUser (params) {
    return await BaseComponent.updateUser(this.props, params)
  }

  static async updateUser (props, params) {
    const user = await Api.updateUser(params)
    props.setUser(user)
    return user
  }

  // マスターデータ取得
  async loadMasters (auth_id = null) {
    return BaseComponent.loadMasters(this.props, auth_id)
  }

  static async loadMasters (props, auth_id = null) {
    let names = [
      'profile',
      'want_to_know',
      'shop_category',
    ]
    let map = {}
    for (let name of names) {
      let masters = await Api.getMasters(name, auth_id)
      for (let master of masters) {
        let recs = map[master.type]
        if (!recs) {
          recs = {}
          map[master.type] = recs
        }
        recs[master.name] = master
      }
    }
    props.setMaster(map)
    return map
  }

  // 自分が送信したフレンド申請取得
  async loadAppliedEntries () {
    return BaseComponent.loadAppliedEntries(this.props)
  }

  static async loadAppliedEntries (props) {
    const since = props.user.last_entries_fetch_time
    const entries = await Api.getEntries(since)
    props.setEntries(entries)

    if (entries.sent.length > 0 || entries.received.length > 0) {
      const now = dateFormat(new Date(), 'isoDateTime')
      await BaseComponent.updateUser(props, { last_entries_fetch_time: now })
    }

    return entries
  }

  async loadMatchings () {
    return BaseComponent.loadMatchings(this.props)
  }

  static async loadMatchings (props) {
    const matchings = props.matchings || Storage.getMatchings()
    const lastUpdatedTime = findLastUpdatedTime(matchings)
    try {
      let newMatchings = []
      if (isCompletedLoveSheet(props.user)) {
        newMatchings = await Api.getLatestMatchings(lastUpdatedTime)
      }
      props.setMatchings(newMatchings)
      Storage.setMatchings(newMatchings)
      return newMatchings
    } catch (error) {
      if (httpNotModified(error)) {
        if (!props.matchings) {
          props.setMatchings(matchings)
        }
        return matchings
      } else {
        throw error
      }
    }
  }

  // 全てのFriendをAPIから取得してpropsとstorageへキャッシュ
  // キャッシュ済みのものから更新がなければAPIからBodyは返さない(304 Not Modified)
  // 但し、いずれかのアイコンURLが期限切れなら強制リロード
  async loadFriends (forceLoad = false) {
    return await BaseComponent.loadFriends(this.props, forceLoad)
  }

  static async loadFriends (props, forceLoad = false) {
    const friends = props.friends || Storage.getFriends()
    let lastUpdatedTime = forceLoad ? null : findLastUpdatedTime(friends)
    let currentFriendIds = null

    if (friends && lastUpdatedTime) {
      const someUrlExpired = friends.some(f => expiredUrl(f.photo_icon))
      if (someUrlExpired) {
        lastUpdatedTime = null
      } else {
        currentFriendIds = friends.map(f => f.friend_user_id).join(',')
      }
    }

    try {
      const newFriends = await Api.getFriends(lastUpdatedTime, currentFriendIds)
      props.setFriends(newFriends)
      Storage.setFriends(newFriends)
      return newFriends
    } catch (error) {
      if (httpNotModified(error)) {
        if (!props.friends) {
          props.setFriends(friends)
        }
        return friends
      } else {
        throw error
      }
    }
  }

  // Redux管理もしくはStorageに永続化してあるfriend.idからFriend詳細を取得
  async loadCurrentFriendDetail () {
    let friend = this.props.friend
    if (!friend) {
      friend = Storage.getFriend()
    }
    if (friend) {
      if (friend.type === 'ai') {
        this.props.setFriend(friend)
        return friend
      } else {
        let friendDetail = await Api.getFriend(friend.id)
        Storage.setFriend(friendDetail)
        this.props.setFriend(friendDetail)
        return friendDetail
      }
    } else {
      return null
    }
  }

  // Friend.idよりFriend詳細を取得
  async loadFriendDetailWithId (friendId) {
    return BaseComponent.loadFriendDetailWithId(this.props, friendId)
  }

  static async loadFriendDetailWithId (props, friendId) {
    const friendDetail = await Api.getFriend(friendId)
    Storage.setFriend(friendDetail)
    props.setFriend(friendDetail)
    return friendDetail
  }

  // Friend詳細を取得し、Friendsを更新
  async loadFriendAndUpdateFriends (friendId) {
    return await BaseComponent.loadFriendAndUpdateFriends(this.props, friendId)
  }

  static async loadFriendAndUpdateFriends (props, friendId) {
    const friendDetail = await Api.getFriend(friendId)
    const friends = props.friends
    if (friends) {
      const index = friends.findIndex(data => (data.id === friendDetail.id))
      if (index >= 0) {
        friends[index] = friendDetail
        props.setFriends(friends)
        Storage.setFriends(friends)
      }
    }
    return friendDetail
  }

  // 友達申請・承認の送信
  // 送信後、Friendレコードの変更があれば取得してキャッシュ
  async postEntry (friendId, type, opts = null) {
    return await BaseComponent.postEntry(this.props, friendId, type, opts)
  }

  static async postEntry (props, friendId, type, opts = null) {
    const res = await Api.postEntry(friendId, type, opts)
    await BaseComponent.loadFriends(props, true)
    return res
  }

  // 保留中の異性とトーク開通 → トーク画面へ遷移
  async startTalk () {
    await BaseComponent.startTalk(this.props)
  }

  static async startTalk (props) {
    const { friend } = props
    props.setLoading(true)
    try {
      await BaseComponent.postEntry(props, friend.id, 'establish_friend')
      props.setFriend(friend)
      props.setScreen('Chat')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // 自分が送信したフレンド申請をキャンセル
  async cancelEntries (friendId) {
    BaseComponent.cancelEntries(this.props, friendId)
  }

  static async cancelEntries (props, friendId) {
    await Api.cancelEntries(friendId)
    await BaseComponent.loadFriends(props, true)
  }

  // 卒業済み判定 (誰かとお付き合い成立しているか否か)
  async isGraduated () {
    return await BaseComponent.isGraduated(this.props)
  }

  static async isGraduated (props) {
    let friends = props.friends
    if (isNullOrUndefined(friends)) {
      friends = await BaseComponent.loadFriends(props)
    }

    return friends.some(friend => (friend.type === 'lover'))
  }

  // お付き合い状態のフレンドを返す, なければundefined
  async findLoverFriend () {
    return BaseComponent.findLoverFriend(this.props)
  }

  static async findLoverFriend (props) {
    const friends = await BaseComponent.loadFriends(props, true)
    return friends.find(friend => (friend.type === 'lover'))
  }

  // プロフィール写真アップロードしてUserレコード更新
  async updatePhoto (file, imgFilters = null) {
    return BaseComponent.updatePhoto(this.props, file, imgFilters)
  }

  static async updatePhoto (props, file, imgFilters = null) {
    const userId = props.userId
    const ext = getFileExt(file)
    const s3Key = `${Const.s3RootDir}/${userId}/photo_${Date.now()}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)

    const params = { photo: s3Key }
    if (imgFilters && imgFilters.brightness) {
      params.photo_filter_brightness = imgFilters.brightness
    }
    const user = await BaseComponent.updateUser(props, params)
    return user
  }

  // 健康保険証をアップロードしてUserレコード更新
  async updateIdentification (file) {
    let userId = this.props.userId
    let ext = getFileExt(file)
    let s3Key = `${Const.s3RootDir}/${userId}/identification_${Date.now()}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    let user = await this.updateUser({ identification: s3Key })
    return user
  }

  // 会員証をアップロードしてUserレコード更新
  static async updateAffiliation (props, file) {
    const userId = props.userId
    const ext = getFileExt(file)
    const s3Key = `${Const.s3RootDir}/${userId}/affiliation_${Date.now()}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    const user = await BaseComponent.updateUser(props, { affiliation: s3Key })
    return user
  }

  // 自分のその他の写真が更新されていれば取得
  // アイコン画像URLが期限切れなら強制取得
  async loadMyGalleryItems () {
    return BaseComponent.loadMyGalleryItems()
  }

  static async loadMyGalleryItems () {
    const items = Storage.getGalleryItems()
    let lastUpdatedTime = null
    if (items) {
      const someUrlExpired = items.some(i => expiredUrl(i.icon_url))
      if (!someUrlExpired) {
        lastUpdatedTime = findLastUpdatedTime(items)
      }
    }
    try {
      const newItems = await Api.getMyGalleryItems(lastUpdatedTime)
      Storage.setGalleryItems(newItems)
      return newItems
    } catch (error) {
      if (httpNotModified(error)) {
        return items
      } else {
        throw error
      }
    }
  }

  // 自分のその他の写真を再取得
  async reloadMyGalleryItems () {
    try {
      let newItems = await Api.getMyGalleryItems()
      Storage.setGalleryItems(newItems)
      return newItems
    } catch (error) {
      const e = error
      throw e
    }
  }

  static async reloadMyGalleryItems () {
    try {
      let newItems = await Api.getMyGalleryItems()
      Storage.setGalleryItems(newItems)
      return newItems
    } catch (error) {
      const e = error
      throw e
    }
  }

  // ギャラリー写真追加
  async addGalleryItem (file, imgFilters = null) {
    const userId = this.props.userId
    const item = await Api.createMyGalleryItem()
    const ext = getFileExt(file)
    const s3Key = `${Const.s3RootDir}/${userId}/${item.id}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    const updatedItem = await Api.updateMyGalleryItem(item, s3Key, imgFilters)
    return updatedItem
  }

  static async addGalleryItem (props, file, imgFilters = null) {
    const userId = props.userId
    const item = await Api.createMyGalleryItem()
    const ext = getFileExt(file)
    const s3Key = `${Const.s3RootDir}/${userId}/${item.id}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    const updatedItem = await Api.updateMyGalleryItem(item, s3Key, imgFilters)
    return updatedItem
  }

  // トークに画像添付
  async addChatImage (roomId, file) {
    return BaseComponent.addChatImage(roomId, file)
  }

  static async addChatImage (roomId, file) {
    const item = await Api.createChatPhotoItem(roomId)
    const ext = getFileExt(file)
    const s3Key = `${Const.s3ChatDir}/${roomId}/${item.id}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    const updatedItem = await Api.updateChatPhotoItem(roomId, item, s3Key)
    return updatedItem
  }

  // 前回取得時以降のお知らせレコード取得
  async loadNotifications () {
    return BaseComponent.loadNotifications(this.props)
  }

  static async loadNotifications (props) {
    const data = await Api.getNotifications()
    const notifs = data.notifications
    // 全体お知らせの未読を追加
    const globalNotifs = await Api.getGlobalNotifications()
    const readIds = await Api.getReadIds('GlobalNotification')
    const noReadGlobalNotifs = globalNotifs.filter(notif => {
      return !readIds.includes(notif.id)
    })
    const allNotifs = notifs.concat(noReadGlobalNotifs)
    props.setNotifications(allNotifs)

    if (notifs.length > 0) {
      const now = dateFormat(new Date(), 'isoDateTime')
      await BaseComponent.updateUser(props, { last_notifications_fetch_time: now })
    }

    return data.notifications
  }

  // Friendsよりトークルーム情報を取得
  async loadChatRoomsBy () {
    if (!this.props.friends) return
    const rooms = this.props.friends.map(friend => {
      if (!friend.chat_room_id) {  return null }
      return this.loadChatRoom(friend.chat_room_id)
    }).filter(v => v)
    try {
      const result = await Promise.all(rooms)
      this.props.setChatRooms(result)
      Storage.setChatRooms(result)
    } catch (error) {
      BaseComponent.handleError(this.props, error)
    }
  }

  // chatroomを取得
  async loadChatRoom (roomId) {
    return BaseComponent.loadChatRoom(roomId)
  }

  static async loadChatRoom (roomId) {
    try {
      const room = await Api.getChatRoom(roomId)
      return room
    } catch (error) {
      if (httpNotFound(error)) {
        return null
      } else {
        throw error
      }
    }
  }

  // chatroomを取得し、chatroomsを更新
  async loadChatRoomAndUpdateChatRooms (roomId) {
    let room = await Api.getChatRoom(roomId)
    let chatrooms = this.props.chatrooms
    if (chatrooms) {
      let index = chatrooms.findIndex(data => (data.chat_room_id === room.chat_room_id))
      if (index >= 0) {
        chatrooms[index] = room
      } else {
        chatrooms.push(room)
      }
    } else {
      chatrooms = [room]
    }
    this.props.setChatRooms(chatrooms)
    Storage.setChatRooms(chatrooms)
    return room
  }

  // PartnerUserレコード取得
  async loadPartnerUser () {
    try {
      let user = await Api.getPartnerUser(this.props.userId)
      this.props.setUser(user)
      return user
    } catch (error) {
      const e = error
      throw e
    }
  }

  // Partnerレコード取得
  async loadPartners () {
    try {
      let partners = await Api.getPartners()
      this.setStateIfMounted({ partners: partners })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  // 狛犬トークルーム取得
  async getAiFriend (ai_chat_room_id) {
    return BaseComponent.getAiFriend(this.props, ai_chat_room_id)
  }

  static async getAiFriend (props, ai_chat_room_id) {
    try {
      const room = await BaseComponent.loadChatRoom(ai_chat_room_id)
      const chat_updated_at = room.histories.length > 0 ? room.histories[room.histories.length - 1].created_at : null
      const recent_message = room.histories.length > 0 ? room.histories[room.histories.length - 1].message : null
      const aiFriend = {
        type: 'ai',
        nick_name: '狛犬（AI）',
        chat_room_id: ai_chat_room_id,
        photo_icon: AIcon,
        fav_point_to_me: 100,
        chat_updated_at: chat_updated_at,
        user_chat_updated_at: null,
        recent_message: recent_message,
        ai_unread_count: room.ai_unread_count
      }
      return aiFriend
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // ダイアログ表示済みレコード作成
  async createReadStateDialogDisplayed (read_id) {
    await BaseComponent.createReadStateDialogDisplayed(this.props, read_id)
  }

  static async createReadStateDialogDisplayed (props, read_id) {
    try {
      const params = {
        target: 'DialogDisplayed',
        read_ids: [read_id],
      }
      await Api.createReadState(params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // ダイアログ表示済みチェック
  async isReadStateDialogDisplayed (read_id) {
    return await BaseComponent.isReadStateDialogDisplayed(this.props, read_id)
  }

  static async isReadStateDialogDisplayed (props, read_id) {
    try {
      const read_ids = await Api.getReadIds('DialogDisplayed')
      return read_ids.includes(read_id)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // 食レポ用写真をアップロード
  static async updateFoodReportPhoto (foodReport, target, file) {
    const ext = getFileExt(file)
    const s3Key = `${Const.s3FoodReportDir}/${foodReport.id}/photo_${Date.now()}.${ext}`
    await ExternalApi.uploadFile(file, s3Key)
    const key = target === 'malePhoto' ? 'male_photo_s3_key' : 'female_photo_s3_key'
    const params = {
      food_report: {
        visited_at: foodReport.visited_at,
        shop_id: foodReport.shop_id,
        [key]: s3Key,
        impression: foodReport.impression,
        submitted: true,
      }
    }
    const updatedFoodReport = await Api.updateFoodReport(foodReport.id, params)
    return updatedFoodReport
  }

  static async loadMatchingState (props) {
    try {
      const matchingState = await Api.getMatchingState()
      props.setMatchingState(matchingState)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  static isFirstMatchingBoostEnabled (props) {
    const { matchingState } = props
    return (
      matchingState
      && matchingState.first_boost_enabled
      && matchingState.first_boost_end_at
      && new Date() < new Date(matchingState.first_boost_end_at)
    )
  }

  static isFreeExtensionChallengeEnabled (props) {
    const { matchingState } = props
    return (
      matchingState?.first_matching_updated_at
      && new Date() < addDate(new Date(matchingState.first_matching_updated_at), 14)
    )
  }

  static getMaxFriendCount (subscription) {
    if (!subscription) { return 0 }
    if (subscription.content === Const.planTypes.FREE) { return Const.maxFriendCountTrial }
    if (subscription.content === Const.planTypes.STANDARD) { return Const.maxFriendCountStandard }
    if (subscription.content === Const.planTypes.STANDARD_INITIAL) { return Const.maxFriendCountStandard }
    if (subscription.content === Const.planTypes.STANDARD_FREE) { return Const.maxFriendCountStandard }
    if (subscription.content === Const.planTypes.STANDARD_ORG_PAID) { return Const.maxFriendCountStandard }
    if (subscription.content === Const.planTypes.SPECIAL_FREE) { return Const.maxFriendCountSpecialFree }
    if (subscription.content === Const.planTypes.BASIC) { return Const.maxFriendCountSpecialFree }
    return 0
  }

  static getNowFriendCount (friends) {
    if (!friends) { return 0 }
    const targetTypes = ['friend', 'lover_receiving', 'lover_applying', 'lover']
    const filteredFriends = friends.filter(friend => targetTypes.includes(friend.type))
    return filteredFriends.length
  }

  // 残り友達可能人数
  getRestFriendCount () {
    return BaseComponent.getRestFriendCount(this.props)
  }

  static getRestFriendCount (props) {
    const { subscription, friends } = props
    if (!subscription) { return 0 }
    if (!friends) { return 0 }

    const maxFriendCount = BaseComponent.getMaxFriendCount(subscription)
    const nowFriendCount = BaseComponent.getNowFriendCount(friends)
    return Math.max(maxFriendCount - nowFriendCount, 0)
  }

  static getPlanName (subscription) {
    if (!subscription) { return '' }
    if (subscription.content === Const.planTypes.FREE) { return 'フリープラン' }
    if (subscription.content === Const.planTypes.STANDARD) { return 'スタンダードプラン' }
    if (subscription.content === Const.planTypes.STANDARD_INITIAL) { return 'スタンダードプラン' }
    if (subscription.content === Const.planTypes.STANDARD_FREE) { return 'スタンダードプラン' }
    if (subscription.content === Const.planTypes.SPECIAL_FREE) { return '特別フリープラン' }
    return ''
  }

  // 月額利用料(通常価格)取得
  static async getMonthlyPrice (props, orgId = null) {
    try {
      const fees = await Api.getMonthlyFees(orgId)
      if (!fees || fees.length === 0) { return null }
      const fee = fees.find(f => f.payment_months === 1)
      if (!fee) { return null }
      return fee.price
    } catch (error) {
      BaseComponent.handleApiError(props, error)
      return null
    }
  }

  // キャンペーンの値引き価格取得
  static async getIncentivePrice (props, campaign) {
    if (!campaign) { return null }
    try {
      const master = await Api.getMaster('incentive', campaign.incentive_name)
      if (!master) { return null }
      const detail = JSON.parse(master.detail)
      return detail.model_attributes.incentive_param
    } catch (error) {
      BaseComponent.handleApiError(props, error)
      return null
    }
  }

  static async loadSubscription (props) {
    try {
      const subsc = await Api.getMySubscription()
      props.setSubscription(subsc)
      return subsc
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  isUsingStandardPlan () {
    return BaseComponent.isUsingStandardPlan(this.props)
  }

  static isUsingStandardPlan (props) {
    const { subscription } = props
    if (!subscription) { return false }
    if (!subscription.content) { return false }
    return /^standard(_.+)?$/.test(subscription.content)
  }

  static canUseSpecialFreePlan (pref, subscription) {
    return pref?.special_free_plan_target && subscription?.next_plan_id === Const.planTypes.SPECIAL_FREE
  }

  static async loadSpecialOffers (props) {
    try {
      const res = await Api.getSpecialOffers()
      props.setSpecialOffers(res)
      return res
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  async loadIdTexts (org) {
    return await BaseComponent.loadIdTexts(this.props, org)
  }

  static async loadIdTexts (props, org) {
    try {
      const res = []
      const idText = await Api.getIdText(org.identification_text_id)
      props.setIdText(idText)
      res.push(idText)
      if (org.affiliation_text_id) {
        const affiText = await Api.getIdText(org.affiliation_text_id)
        props.setAffiliationText(affiText)
        res.push(affiText)
      }
      return res
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  static isCompletedEntrySheet (user) {
    // 基本シートの必須項目
    return [
      user.sex,
      user.nationality,
      user.birthday,
      user.last_name,
      user.first_name,
      user.last_kana_name,
      user.first_kana_name,
      user.nick_name,
      user.postal_code,
    ].every(v => !isEmpty(v))
  }

  static isCompletedLoveSheet (user) {
    // 恋愛シートの必須項目
    return [
      user.marital_status,
      user.height,
    ].every(v => !isEmpty(v))
  }

  // 会員登録フローの次のステップに進む
  toEntryNextStep (user) {
    return BaseComponent.toEntryNextStep(this.props, user)
  }

  static toEntryNextStep (props, user) {
    if (Config.deployMode !== 'entry' && Config.deployMode !== 'test') { return }
    if (!user) { return }

    const { identification_status } = user
    switch (identification_status) {
      // 再提出OK（他のpending_reasons無し）
      case 'checking':
        Storage.clearShowPendingDialog()
        props.setScreen('IdChecking')
        break
      // 再提出NG（他のpending_reasons有り）
      case 'pending':
        this.toEntryNextStepPending(props, user)
        break
      default:
        this.toEntryNextStepDefault(props)
    }
  }

  toEntryNextStepPending (user) {
    BaseComponent.toEntryNextStepPending(this.props, user)
  }

  static toEntryNextStepPending (props, user) {
    const { pending_reasons } = user
    if (pending_reasons.includes('profile')) {
      props.setScreen('Entry')
    } else if (pending_reasons.includes('photo')) {
      props.setScreen('Photo')
    } else if (pending_reasons.includes('gallery_item')) {
      props.setScreen('Photo')
    } else if (pending_reasons.includes('identification')) {
      props.setScreen('Identify')
    } else if (pending_reasons.includes('affiliation')) {
      props.setScreen('Affiliation')
    }
  }

  static toEntryNextStepDefault (props) {
    switch (window.location.pathname) {
      case '/Entry':
        props.setScreen('PhoneNumber')
        break
      case '/PhoneNumber':
        props.setScreen('PhoneNumberVerification')
        break
      case '/PhoneNumberVerification':
        props.setScreen('/Love')
        break
      case '/Love':
        props.setScreen('Photo')
        break
      case '/PhotoConfirm':
        // メイン写真登録 -> Photo に戻る -> サブ写真登録して「次へ」
        storage.entrySiteSubmitPhotoFlag.value = true
        BaseComponent.goBack(props)
        break
      case '/Photo':
        props.setScreen('Identify')
        break
      case '/IdentifyConfirm':
        if (props.myOrganization?.affiliation_text_id) {
          // 支払方法画面の前に会員証登録画面へ
          props.setScreen('Affiliation')
        } else {
          // 支払方法画面へ
          props.setScreen('PaymentRegistration')
        }
        break
      case '/AffiliationConfirm':
        props.setScreen('PaymentRegistration')
        break
      default:
        console.error(`Unexpected current pathname: ${window.location.pathname}`)
    }
  }

  static async updateAlreadyRead (props, notification) {
    try {
      if (isGlobalNotificationType(notification.notification_type)) {
        const params = { target: 'GlobalNotification', read_ids: [notification.id] }
        const res = await Api.createReadState(params)
        // GOEN-142 ReadState更新のラグで、表示済みダイアログが一瞬出てしまう問題の解決策
        // ダイアログからの画面遷移を阻害しないように await はしない。
        BaseComponent.loadNotifications(props)
        return res
      } else {
        const res = await Api.updateAlreadyReadNotifications([notification.created_time])
        BaseComponent.loadNotifications(props)
        return res
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // 表示済みチュートリアル取得
  static async loadTutorialReadStates (props) {
    try {
      const res = await Api.getReadIds('Tutorial')
      props.setTutorialReadStates(res)
      return res
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // チュートリアル表示済みフラグ作成
  static async updateAlreadyReadTutorial (props, id) {
    try {
      await props.setTutorialReadStates([...props.tutorialReadStates, id])
      const params = { target: 'Tutorial', read_ids: [id] }
      const res = await Api.createReadState(params)
      return res
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // チュートリアル進行
  static async toNextTutorial (props, id) {
    switch (id) {
      case 'matching-01':
        props.setTutorialView('matching-02')
        break
      case 'matching-02':
        props.setTutorialView('matching-03')
        break
      case 'matching-03':
        await BaseComponent.updateAlreadyReadTutorial(props, 'matching')
        props.setTutorialView(null)
        break
      case 'like-01':
        break
      case 'like-02':
        await BaseComponent.updateAlreadyReadTutorial(props, 'like')
        break
      case 'first-impression-01':
        await BaseComponent.updateAlreadyReadTutorial(props, 'first_impression')
        props.setTutorialView('entry-01')
        break
      case 'entry-01':
        await BaseComponent.updateAlreadyReadTutorial(props, 'entry')
        props.setTutorialView(null)
        break
      case 'talk-list-01':
        props.setTutorialView('talk-room-01')
        break
      case 'talk-room-01':
        await BaseComponent.updateAlreadyReadTutorial(props, 'talk_list')
        props.setTutorialView(getFriendTopicSuggestion() ? 'talk-room-02' : 'talk-room-03')
        break
      case 'talk-room-02':
        props.setTutorialView('talk-room-03')
        break
      case 'talk-room-03':
        await BaseComponent.updateAlreadyReadTutorial(props, 'talk_room')
        props.setTutorialView(null)
        break
      case 'talk-list-ai-01':
        props.setTutorialView('talk-room-ai-01')
        break
      case 'talk-room-ai-01':
        props.setTutorialView('talk-room-ai-02')
        break
      case 'talk-room-ai-02':
        props.setTutorialView('talk-room-ai-03')
        break
      case 'talk-room-ai-03':
        props.setTutorialView('talk-room-ai-04')
        await BaseComponent.updateAlreadyReadTutorial(props, 'talk_list_ai')
        break
      case 'talk-room-ai-04':
        await BaseComponent.updateAlreadyReadTutorial(props, 'talk_room_ai')
        props.setTutorialView(null)
        break
      case 'fav-bar-01':
        props.setTutorialView('fav-bar-02')
        break
      case 'fav-bar-02':
        props.setTutorialView('fav-bar-03')
        break
      case 'fav-bar-03':
        props.setTutorialView('fav-update-01')
        break
      case 'fav-update-01':
        props.setTutorialView('fav-update-02')
        break
      case 'fav-update-02':
        props.setTutorialView('fav-update-03')
        await BaseComponent.updateAlreadyReadTutorial(props, 'fav_bar')
        break
      case 'fav-update-03':
        await BaseComponent.updateAlreadyReadTutorial(props, 'fav_update')
        props.setTutorialView(null)
        break
      case 'interested-things-01':
        await BaseComponent.updateAlreadyReadTutorial(props, 'interested_things')
        props.setTutorialView(null)
        break
      default:
        break
    }
  }

  // ----- ReactGA系 -----

  // GAトラッキング User-ID機能
  static gaSet (params) {
    if (window.location.hostname === 'localhost') { return }
    ReactGA.set(params)
    ReactGA4.set(params)
  }

  // GAトラッキング pageview
  static gaPageView (path) {
    if (window.location.hostname === 'localhost') { return }
    ReactGA.pageview(path.toLowerCase())
    ReactGA4.send({ hitType: 'pageview', page: path.toLowerCase() })
  }

  // GAトラッキング modalview
  static gaModalView (name) {
    if (window.location.hostname === 'localhost') { return }
    ReactGA.modalview(name.toLowerCase())
    ReactGA4.send({ hitType: 'pageview', page: `/modal/${name.toLowerCase()}` })
  }

  // GAトラッキング event
  static gaEvent (category, action, label = '', value) {
    if (window.location.hostname === 'localhost') { return }
    const options = {
      category: category,
      action: action,
      label: label,
      value: value,
    }
    ReactGA.event(options)
    ReactGA4.event(options)
  }

  // ----- プロフィール編集・表示系 -----

  // DEV-1305 テキスト入力時のstate更新制御対応
  static getInputValue (key) {
    const el = this.findInputElementByName(key)
    if (el) { return el.value }
  }

  // DEV-1305 テキスト入力時のstate更新制御対応
  static setInputValue (key, value) {
    const el = this.findInputElementByName(key)
    if (el) { el.value = value }
  }

  static findInputElementByName (name) {
    const elems = Array.from(document.getElementsByName(name))
    const namePattern = /^(input|textarea)$/i
    return elems.find(el => namePattern.test(el.nodeName))
  }

  // プロフィール編集: 選択肢
  createUserProfileSelect (name, params = {}) {
    const { classes, master, user } = this.props
    const { main_working_company_name } = this.state
    const profileMaster = master.profile
    const detail = profileMaster[name].detail
    const items = detail.common ? detail.common : detail[user.sex]

    // 登録時のみ項目タイトルを変更
    const title = name === 'annual_income' ? '自身の年収' : name === 'height' ? '自身の身長' : profileMaster[name].title

    let helperText = ''
    switch (name) {
      case 'same_organization_matching': {
        const company_name = main_working_company_name || user?.main_working_company_name || ''
        if (company_name.indexOf('ドコモ') > 0) {
          helperText = 'グループ会社とは、ドコモグループではなくNTTグループ（NTT東日本、NTTデータ、NTTコミュニケーションズなど）を指します。'
        }
        break
      }
      case 'marital_status':
        helperText = '結婚歴3回以上の場合は入会できません'
        break
      case 'marital_status_of_partner':
        helperText = '相手に求める結婚歴が3回以上の場合は入会できません'
        break
      default:
        helperText = params.noHelper ? '' : this.createUserProfileHelperText(name, params)
        break
    }

    if (params.noHelper) {
      helperText = ''
    }

    return (
      <FormControl className={classes.formControl} key={name} fullWidth>
        <InputLabel className={classes.inputLabel} error={this.state[`${name}_error`]} disabled={params.disabled}>
          {params.noTitle ? '' : title}
        </InputLabel>
        <Select
          disabled={params.disabled}
          error={this.state[`${name}_error`]}
          value={this.state[name]}
          onChange={this.handleChange}
          name={name}
          IconComponent={() => <KeyboardArrowDown />}
          data-testid={`select-${name}`}
        >
          <MenuItem value={''}><em>未選択</em></MenuItem>
          {items.map((val, i) => {
            return (
              <MenuItem key={i} value={val.value}>
                {(window.innerWidth <= 320 && val.label.length >= 15) ? kanaZenkakuToHankaku(val.label) : val.label}
              </MenuItem>
            )
          })}
        </Select>
        {helperText && <FormHelperText error={this.state[`${name}_error`]}>{helperText}</FormHelperText>}
        {(params.noTitle || params.noPublicSwitch) ? null : this.createPublicProfileSwitch(name) }
      </FormControl>
    )
  }

  // プロフィール編集（組織）: 選択肢
  createUserOrganizationSelect (name, params = {}) {
    if (!this.state.companies) { return }

    const { classes } = this.props
    const profileMaster = this.props.master.profile
    let items = []
    if (name === 'main_working_company_name' || name === 'loan_working_company_name') {
      items = this.state.companies
    } else if (name === 'main_working_division_name' && this.state.main_working_company_name) {
      let recDivisions = this.state.companies.filter(data => {
        return data.name.match(this.state.main_working_company_name)
      })
      items = recDivisions[0].divisions
    } else if (name === 'main_working_department_name' && this.state.main_working_company_name && this.state.main_working_division_name) {
      let recDivisions = this.state.companies.filter(data => {
        return data.name.match(this.state.main_working_company_name)
      })
      let recDepartments = recDivisions[0].divisions.filter(data => {
        return data.name.match(this.state.main_working_division_name)
      })
      items = recDepartments[0].departments
    } else if (name === 'loan_working_division_name' && this.state.loan_working_company_name) {
      let recDivisions = this.state.companies.filter(data => {
        return data.name.match(this.state.loan_working_company_name)
      })
      items = recDivisions[0].divisions
    } else if (name === 'loan_working_department_name' && this.state.loan_working_company_name && this.state.loan_working_division_name) {
      let recDivisions = this.state.companies.filter(data => {
        return data.name.match(this.state.loan_working_company_name)
      })
      let recDepartments = recDivisions[0].divisions.filter(data => {
        return data.name.match(this.state.loan_working_division_name)
      })
      items = recDepartments[0].departments
    }

    const helperText = this.createUserProfileHelperText(name, params)
    return (
      <FormControl
        className={classes.formControl}
        key={name}
      >
        <InputLabel error={this.state[`${name}_error`]} disabled={params.disabled}>
          {params.noTitle ? '' : profileMaster[name].title}
        </InputLabel>
        <Select
          disabled={params.disabled}
          error={this.state[`${name}_error`]}
          value={this.state[name]}
          onChange={this.handleChange}
          name={name}
          data-testid={`select-${name}`}
        >
          <MenuItem value=""><em>未選択</em></MenuItem>
          {
            items.map((val, i) => {
              return <MenuItem key={i} value={val.name}>{val.name}</MenuItem>
            })
          }
        </Select>
        {
          helperText &&
          <FormHelperText>{helperText}</FormHelperText>
        }
      </FormControl>
    )
  }

  // プロフィール編集: 1行入力
  createUserProfileTextField (name, params = {}) {
    const { classes, master } = this.props
    const { validateErrorText } = this.state
    const profileMaster = master.profile
    const helperText = name === 'nick_name' ? validateErrorText : this.createUserProfileHelperText(name, params)
    return (
      <div key={name} className={params.className ||classes.textField}>
        <TextField
          className={classes.fullTextField}
          label={params.noTitle ? '' : profileMaster[name].title}
          margin="normal"
          type={params.type || 'text'}
          InputProps={params.inputProps || {}}
          InputLabelProps={params.InputLabelProps || {}}
          helperText={helperText}
          key={name}
          name={name}
          error={this.state[`${name}_error`]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={params.disabled}
          inputProps={{ 'data-testid': `input-${name}` }}
        />
        { params.noTitle || params.noPublicSwitch ? null : this.createPublicProfileSwitch(name) }
      </div>
    )
  }

  // プロフィール編集: 複数行入力
  createUserProfileTextFieldMultiline (name, params = {}) {
    const { classes, master } = this.props
    const profileMaster = master.profile
    return (
      <div className={params.className ||classes.textField}>
        <TextField
          className={classes.fullTextField}
          label={profileMaster[name].title}
          margin="normal"
          type={params.type || 'text'}
          InputProps={params.inputProps || {}}
          InputLabelProps={params.InputLabelProps || {}}
          helperText={this.createUserProfileHelperText(name, params)}
          key={name}
          name={name}
          error={this.state[`${name}_error`]}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          disabled={params.disabled}
          multiline
          maxRows={10}
        />
        { this.createPublicProfileSwitch(name) }
      </div>
    )
  }

  // プロフィール編集: 公開設定
  createPublicProfileSwitch (name) {
    if (isNullOrUndefined(this.state.publics)) {
      return null
    }

    const { classes } = this.props
    const switchLabel = { true: '公開', false: '非公開' }

    if (!isProfilePublicFieldName(name) && !isProfilePrivateFieldName(name)
      && isNullOrUndefined(this.state.publics[name])
    ) {
      let publics = this.state.publics
      publics[name] = true
      this.setStateIfMounted({ publics: publics })
    }

    return (
      <div className={classes.publicContainer} >
        {isProfilePublicFieldName(name, this.state.publics[name]) ? (
          <Typography className={classes.itemStatus}>公開</Typography>
        ) : isProfilePrivateFieldName(name) ? (
          <Typography className={classes.itemStatus}>非公開</Typography>
        ) : (
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  disableRipple
                  checked={this.state.publics[name]}
                  onChange={this.handlePublicChange.bind(this)}
                  value={name}
                />
              }
              labelPlacement="start"
              label={switchLabel[this.state.publics[name]]}
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </FormGroup>
        )}
      </div>
    )
  }

  // テキスト複数行変換
  textConvertMultiline (text) {
    return BaseComponent.textConvertMultiline(text)
  }

  static textConvertMultiline (text) {
    if (!text) { return '' }
    const row = text.split(/\r\n|\r|\n/).length
    if (row === 1) { return text }
    return (
      text.split(/\r\n|\r|\n/).map(
        (line, key) => (
          <span key={key}>
            {key !==0 && <br />}
            {line}
          </span>
        )
      )
    )
  }

  // プロフィール編集の各項目の下に表示する補足
  createUserProfileHelperText (name, params) {
    const profileMaster = this.props.master.profile
    const description = profileMaster[name].description

    let helperTexts = []
    if (params.optional) {
      helperTexts.push('任意')
    }
    if (description) {
      helperTexts.push(description)
    }

    return helperTexts.join('、')
  }

  // プロフィール表示の項目順
  getUserProfileDisplayKeys () {
    return BaseComponent.getUserProfileDisplayKeys()
  }

  static getUserProfileDisplayKeys () {
    let keys = [
      'holiday',
      'how_to_spend_the_holidays',
      'future_work_way',
      'future_work_way_of_partner',
      'marriage_intention',
      'ideal_housework_and_childcare',
      'acceptable_housework_and_childcare',
      'marital_status',
      'marital_status_of_partner',
      'current_children',
      'children',
      'sibling',
      'height',
      'annual_income',
      'birthplace',
      'u_turn_intention',
      'blood_type',
      'liquor',
      'smoking_status',
      'heterosexual_smoking',
      'date_timing',
      'to_message',
      'job_worthwhile',
      'navi1',
      'navi2',
      'navi3',
    ]
    return keys
  }

  // プロフィール表示の各項目
  getUserProfileItemLabel (user, key) {
    return BaseComponent.getUserProfileItemLabel(this.props, user, key)
  }

  static getUserProfileItemLabel (props, user, key) {
    const master = props.master
    if (!master?.profile) { return null }
    if (isNullOrUndefined(user[key])) { return null }
    const detail = master.profile[key].detail
    if (isNullOrUndefined(detail) || Object.keys(detail).length === 0) {
      return user[key]
    }

    let items = null
    if (!isNullOrUndefined(detail.common)) {
      items = detail.common
    } else if (!isNullOrUndefined(detail.female) && user.sex === 'female') {
      items = detail.female
    } else if (!isNullOrUndefined(detail.male) && user.sex === 'male') {
      items = detail.male
    } else {
      return null
    }
    if (!isNullOrUndefined(items)) {
      let label = null
      items.some((value) => {
        if (value.value === user[key]) {
          label = value.label
        }
        return null
      })
      return label
    }
    return null
  }

  setStateIfMounted (params) {
    if (this._isMounted) {
      this.setState(params)
    }
  }

  async createPrefItems () {
    this.props.setLoading(true)
    try {
      const prefs = await Api.getPrefs()
      const items = prefs.map(pref => {
        return {
          value: pref.id,
          label: pref.display_name,
          display_order: pref.code,
        }
      })
      const sortedItems = this.sortDisplayOrder(items)
      this.setState({ pref_items: sortedItems })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async createStationItems (pref) {
    this.props.setLoading(true)
    const items = []
    try {
      if (isEmpty(pref)) { return items }
      const stations = await Api.getStations(pref)
      stations.forEach(station => {
        items.push(
          {
            value: station.name,
            label: station.title,
            display_order: station.display_order,
          }
        )
      })
      const sortedItems = this.sortDisplayOrder(items)
      this.setState({ station_items: sortedItems })
    } catch (error){
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  createCategoryItems () {
    let items = []
    let categories = this.props.master.shop_category
    for (let category in categories) {
      items.push(
        {
          value: category,
          label: categories[category]['title'],
          display_order: categories[category]['detail'].display_order,
        }
      )
    }
    items = this.sortDisplayOrder(items)
    this.setState({ category_items: items })
  }

  createOpenTimeItems () {
    let items = [
      {
        value: 'open_lunch',
        label: '昼',
        display_order: 1,
      },
      {
        value: 'open_dinner',
        label: '夜',
        display_order: 2,
      },
    ]
    items = this.sortDisplayOrder(items)
    this.setState({ open_time_items: items })
  }

  sortDisplayOrder (items) {
    items.sort(function (a, b) {
      if(a.display_order > b.display_order) return 1
      if(a.display_order < b.display_order) return -1
      return 0
    })
    return items
  }

  getItemLabel (value, items) {
    let label = '未選択'
    items.forEach(item => {
      if (item.value === value) {
        label = item.label
        return
      }
    })
    return label
  }

  createSearchSelect (name, title, items, disabled = false, noEmpty = false) {
    const { classes } = this.props

    return (
      <FormControl
        className={classes.formControl}
        key={name}
      >
        <InputLabel error={this.state[`${name}_error`]} disabled={disabled}>
          {title}
        </InputLabel>
        <Select
          disabled={disabled}
          value={this.state[name]}
          onChange={this.handleChange}
          name={name}
          error={this.state[`${name}_error`]}
          IconComponent={() => (
            <KeyboardArrowDown />
          )}
        >
          {
            !noEmpty &&
            <MenuItem value=""><em>未選択</em></MenuItem>
          }
          {
            items.map((val, i) => {
              return <MenuItem key={i} value={val.value}>{val.label}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    )
  }

  createPartnerSelect () {
    if (!this.state.partners) { return null }
    const { classes } = this.props
    return (
      <FormControl
        className={classes.formControl}
        key='partnerId'
      >
        <InputLabel className={classes.inputLabel} error={this.state.partnerIdError}>
          パートナー
        </InputLabel>
        <Select
          error={this.state.partnerIdError}
          value={this.state.partnerId}
          onChange={this.handleChange}
          name='partnerId'
          IconComponent={() => (
            <KeyboardArrowDown />
          )}
        >
          <MenuItem value={''}>{<em>未選択</em>}</MenuItem>
          <MenuItem value={'aill.ai'}>{<em>Aill直接営業</em>}</MenuItem>
          {
            this.state.partners.map((val, i) => {
              return <MenuItem key={i} value={val.partner_id}>{val.partner_name}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    )
  }

  // ----- ダイアログ文字列系 -----

  getHousewifeConfirmMessage () {
    let message = []
    message.push(<span key="msg1">{'専業主婦/専業主夫を選択された場合、ご紹介できる異性の人数が少なくなる可能性があります。'}</span>)
    message.push(<span key="msg2"><br /><br /></span>)
    message.push(<span key="msg3">{'＊利用規約「'}</span>)
    message.push(
      <span key="msg4"
        style={{ color: '#fff', textDecoration: 'underline' }}
        onClick={this.openTermsDialog}
      >
        {'第5条（利用資格）'}
      </span>
    )
    message.push(<span key="msg5">{'」参照'}</span>)
    return message
  }

  getHousewifeTermsMessage () {
    let message = []
    message.push(<span key="terms_msg1">{'1. 参画企業の従業員のうち本サービスの利用を希望する者（以下、「利用希望者」といいます）は、本規約を遵守することに同意し、弊社の定める方法により必要情報を弊社に提供することで、本サービスの会員登録を申込むことができます。'}<br /></span>)
    message.push(<span key="terms_msg2">{'2. 前項の申込みは、利用希望者自身が行わなければならず、代理人による申込みは、認められません。 また、利用希望者は、利用の申込みにあたり、真実、正確かつ最新の情報を弊社に提供しなければなりません。'}<br /></span>)
    message.push(<span key="terms_msg3">{'3. 弊社は、弊社の基準に従って、利用希望者の利用の可否を判断し、弊社が利用を認める場合、パスワード及びユーザーIDを当該利用希望者へ通知します。'}<br /></span>)
    message.push(<span key="terms_msg4">{'4. 前項に定める弊社による通知が行われた時点で、利用希望者の会員登録は完了し、当該利用希望者と弊社との間に本規約を内容とする本サービス利用契約（以下「本契約」といいます）が締結されます。'}<br /></span>)
    message.push(<span key="terms_msg5">{'5. 弊社は、利用希望者が、以下の各号のいずれかに該当する場合は、会員登録の拒否を行うことが出来るものとします。'}<br /></span>)
    message.push(<span key="terms_msg6">{'(1) 結婚又は交際を真剣に希望していないと弊社が判断する場合'}<br /></span>)
    message.push(<span key="terms_msg7">{'(2) 未成年又は学生である場合'}<br /></span>)
    message.push(<span key="terms_msg8">{'(3) 弊社が求める公的証明書の画像を提出（本サービスサイト内にて提出）しない場合'}<br /></span>)
    message.push(<span key="terms_msg9">{'(4) 参画企業の従業員でない場合'}<br /></span>)
    message.push(<span key="terms_msg10">{'(5) 第２１条の表明保証に違反していると弊社が判断した場合'}<br /></span>)
    message.push(<span key="terms_msg11">{'(6) 過去に会員登録を抹消されたり、利用停止処分を受けたりした場合'}<br /></span>)
    message.push(<span key="terms_msg12">{'(7) 弊社の提供するサービスと同一又は類似のサービスを提供することを業とする法人又は個人もしくはそれらの従業者である場合'}<br /></span>)
    message.push(<span key="terms_msg13">{'(8) 無限連鎖講及びマルチ商法など、本サービス内にてビジネスを行うことを目的としていると弊社が判断する場合'}<br /></span>)
    message.push(<span key="terms_msg14">{'(9) その他弊社が本サービスの利用を適当でないと判断した場合'}<br /></span>)
    message.push(<span key="terms_msg15">{'6. 弊社は、会員登録後に、会員が前項の各号に定める事項に該当することを発見した場合、当該会員に何らの通知なく、本サービスの提供を中止するものとします。'}<br /></span>)
    return message
  }

  getSmokingConfirmMessage () {
    let message = []
    message.push(<span key="msg1">{'喫煙 ヘビー（1箱以上/日）の場合、ご紹介できる異性の人数が少なくなる可能性もあります。'}<br /></span>)
    message.push(<span key="msg2">{'予め、ご了承をお願いいたします。'}</span>)
    return message
  }

  getFavSuggestMessage () {
    return BaseComponent.getFavSuggestMessage()
  }

  static getFavSuggestMessage () {
    let favSuggestMessage = []
    favSuggestMessage.push(<span key="fav_msg1">{'気になるあの人の、あなたに対する好感度をAIが可視化している機能です。'}<br /><br /></span>)
    favSuggestMessage.push(<span key="fav_msg2">{'効率的なアプローチをアシストします。'}<br /><br /></span>)
    favSuggestMessage.push(<span key="fav_msg3">{'好感度はコミュニケーションを重ねるほど随時変動します。'}</span>)
    return favSuggestMessage
  }

  getTermsMessage () {
    return BaseComponent.getTermsMessage()
  }

  static getTermsMessage () {
    return (
      <>
        この利用規約(以下「本規約」といいます)は、株式会社Aill(以下「弊社」といいます)が運営･提供するサービスの利用に関する条件を定めるものです。<br />
        <br />
        第1条（定義）<br />
        本規約において使用される用語は、以下のとおり定義されます。<br />
        (1)「参画企業」とは、弊社と本サービスの利用に関する契約を締結している企業を指します。<br />
        (2)「会員」とは、参画企業の従業員のうち、本規約に従って会員登録をした人を指します。<br />
        (3)「有料会員」とは、会員のうち、本サービスの有料サービスの登録を行った人を指します。<br />
        (4)「本サービスサイト」とは、弊社が運営するWebサイト（https://aill.ai/）を指します。<br />
        (5)「本アプリ」とは、弊社が運営・提供するスマートフォン向けアプリケーション「Aill goen（エール ゴエン）」を指します。<br />
        (6)「本サービス」とは、本サービスサイト又は本アプリにより弊社が提供するマッチングサービス「Aill goen（エール ゴエン）」を指します。<br />
        (7)「コンテンツ」とは、本サービスにおいて会員が他の会員に送付又は他の会員の閲覧可能な状態へおくことを目的に本アプリ上に入力した、プロフィール情報、メッセージなどの文章、画像、動画等の一切の情報を指します。<br />
        (8)「パートナー企業」とは、本サービスの提供のために弊社と事業提携等の契約を締結する企業を指します。<br />
        <br />
        第2条（規約の適用）<br />
        1.本規約は、会員と弊社の間の本サービスの利用に関して生ずる全ての関係に適用されます。会員は、本規約の定めに従って本サービスを利用しなければなりません。<br />
        2.本規約とは別に弊社が別途定める諸規定及び会員に対する通知（以下、あわせて「諸規定等」といいます）は、本規約の一部を構成するものとします。なお、本規約の規定と諸規定等の内容が矛盾・抵触する場合には、諸規定等が、本規約に優先して適用されるものとします。<br />
        <br />
        第3条（規約の変更）<br />
        1.弊社は、会員の事前の承諾を得ることなく本規約を変更することができるものとします。<br />
        2.前項の変更が行われた場合、本サービスの利用条件は、変更後の本規約によるものとします。変更後の本規約は、弊社が別途定める場合を除き、本アプリ又は本サービスサイト上に表示する方法により、会員へ通知するものとします。<br />
        3.本規約の変更後に利用者が本サービスを利用した場合又は弊社の定める期間内に退会の手続をとらなかった場合には、弊社は、利用者が、本規約の変更に同意したものとみなします。<br />
        <br />
        第4条（本サービスの内容）<br />
        1.本サービスは、参画企業の従業員（日本在住の有職である独身男女（未成年、学生は除く））を対象とした、将来を見据えたパートナー探しをAIによってアシストするものです。 本サービスは、一部のサービス及び機能を、無料でご利用いただけます。有料サービスをお使いいただくと、より充実したAIのアシストを受けることができます。なお、本サービスは結婚相手を見つけることを保証するものではありません。<br />
        2.本サービスでは、本アプリ上のトーク機能を通じて、会員同士でメッセージのやり取りを行うことができます。<br />
        3.弊社は、会員に対し、自己の結婚相手又は交際相手を探す目的の範囲内で、本サービスを利用することを許諾します。<br />
        <br />
        第5条（利用資格）<br />
        1.参画企業の従業員のうち本サービスの利用を希望する者（以下、「利用希望者」といいます）は、本規約を遵守することに同意し、弊社の定める方法により必要情報を弊社に提供することで、本サービスの会員登録を申込むことができます。<br />
        2.前項の申込みは、利用希望者自身が行わなければならず、代理人による申込みは、認められません。 また、利用希望者は、利用の申込みにあたり、真実、正確かつ最新の情報を弊社に提供しなければなりません。<br />
        3.弊社は、弊社の基準に従って、利用希望者の利用の可否を判断し、弊社が利用を認める場合、パスワード及びユーザーIDを当該利用希望者へ通知します。 <br />
        4.前項に定める弊社による通知が行われた時点で、利用希望者の会員登録は完了し、当該利用希望者と弊社との間に本規約を内容とする本サービス利用契約（以下「本契約」といいます）が締結されます。<br />
        5.弊社は、利用希望者が、以下の各号のいずれかに該当する場合は、会員登録の拒否を行うことが出来るものとします。<br />
        (1)結婚又は交際を真剣に希望していないと弊社が判断する場合<br />
        (2)未成年又は学生である場合<br />
        (3)弊社が求める公的証明書の画像を提出（本サービスサイト内にて提出）しない場合<br />
        (4)参画企業の従業員でない場合<br />
        (5)第２１条の表明保証に違反していると弊社が判断した場合<br />
        (6)過去に会員登録を抹消されたり、利用停止処分を受けたりした場合<br />
        (7)弊社の提供するサービスと同一又は類似のサービスを提供することを業とする法人又は個人もしくはそれらの従業者である場合<br />
        (8)無限連鎖講及びマルチ商法など、本サービス内にてビジネスを行うことを目的としていると弊社が判断する場合<br />
        (9)その他弊社が本サービスの利用を適当でないと判断した場合<br />
        6.弊社は、会員登録後に、会員が前項の各号に定める事項に該当することを発見した場合、当該会員に何らの通知なく、本サービスの提供を中止するものとします。<br />
        <br />
        第6条（利用料金について）<br />
        1.有料会員は、本サービスサイト上に掲示する利用料金を、弊社が定める支払方法により支払うものとします。<br />
        2.利用料金は、有料プランの申し込みが完了した日にご利用料金が発生します。有料プランを無料で使えるキャンペーンが適用される場合は有料プランを無料で使えるキャンペーン期間終了時点とします。<br />
        3.月額プランの月額利用料は、次回更新日の前日分まで発生するものとします。<br />
        4.有料プランを無料で使えるキャンペーン期間が終了する日の翌日の午前{Const.subscriptionUpdateHour - 1}時までに、有料プランを解約しない限り、有料プランを無料で使えるキャンペーン期間が終了する旨の予告なく、有料プランの月額利用料の支払いを自動的に開始します。なお、有料プランを無料で使えるキャンペーン期間中に有料プランを解約する場合は、月額利用料は発生しません。<br />
        5.有料サービスは利用者が自ら自動更新を停止しない限り、お申し込みのプランの支払いが自動的に更新されます。<br />
        6.弊社は、前項に基づき有料会員が支払った利用料金について、理由の如何を問わず返還を行わないものとします。申し込まれた有料プランを利用期間の途中で利用休止またはご解約（解約は退会フォームからお願いいたします）を行った場合も、返金や未利用期間の日割り計算による精算は行っておりません。<br />
        <br />
        第7条（利用環境について）<br />
        弊社は、会員が本サービスを利用するための環境（スマートフォンやタブレット、パソコン等の端末機器、ソフトウェア及び通信回線等のすべてを含む。）に関して一切の責任を持たないとともに、接続環境整備のための助言、サポート行為を行う責任を負わないものとします。<br />
        <br />
        第8条（会員の責任）<br />
        1.会員は、自己の責任に基づき本サービスを利用するものとします。<br />
        2.会員は、会員による本サービスの利用に関連して、第三者との間で紛争が起こった場合、当該紛争を自己の責任と費用により解決するものとします。<br />
        3.会員は、前項に基づく紛争により弊社に損害を与えた場合は、弊社が当該会員に対して損害賠償を請求する権利を有することを認めます。<br />
        <br />
        第9条（データの使用）<br />
        1.会員は、本サービスを利用するにあたり、以下を承諾するものとします。<br />
        (1)弊社が、プライバシーポリシー又は諸規定等に従って、会員の本サービスの利用に関する情報を収集し、定められた目的のために使用すること<br />
        (2)弊社が、本サービス上での会員間のメッセージのやり取りについて、閲覧し、当該メッセージのやり取りを本サービスの利用に関する情報として、取得すること<br />
        (3)本サービスの健全な運営のために、他のユーザーからの申出により、弊社がコンテンツ又はメッセージの全部又は一部を閲覧又は削除すること<br />
        2.弊社は、本サービスの向上又は会員への適切な広告の配信のため、Googleアナリティクス等の解析ツールを使用して本アプリまたは本サービスサイト上の行動履歴に関する情報等を収集します。会員は、当該解析ツールについて、当該解析ツールを提供する第三者の提示する利用規約、利用条件等が適用されることにあらかじめ留意するものとします。なお、Googleアナリティクス等のGoogleが提供するサービスに適用されるGoogleの利用規約については、下記のリンク先をご確認ください。<br />
        https://policies.google.com/terms?hl=ja<br />
        <br />
        第10条（禁止行為）<br />
        1.会員は、本サービスの利用にあたって、以下の行為を行ってはなりません。<br />
        (1)自己の結婚相手や真剣な交際相手を探す目的以外の目的で、本サービスを利用する行為<br />
        (2)自己以外の自然人・法人・団体・組織等の第三者（以下、「第三者」といいます。）に自己のユーザーID、パスワード及び認証コードを譲渡して、本サービスを利用させる行為<br />
        (3)第三者に自己のユーザーID、パスワード及び認証コードを閲覧可能な状態にしておく行為<br />
        (4)第三者の個人情報を公開する行為<br />
        (5)既婚者であることを隠し、本サービスを利用する行為<br />
        (6)第15条第1項に違反するコンテンツを掲載する行為<br />
        (7)虚偽の情報を弊社に提供し、又は虚偽の情報が含まれたコンテンツを掲載する行為<br />
        (8)本サービスで知り得た他の会員に対して、積極的に本サービス内のトーク機能以外の連絡先を問い合わせること<br />
        (9)第三者の名誉や社会的信用を毀損し、又は不快感や精神的な損害を与える行為<br />
        (10)選挙運動、又はこれらに類似する行為及び公職選挙法に抵触する行為<br />
        (11)第三者の所有する知的財産権等の権利を侵害する行為又は侵害するおそれのある行為<br />
        (12)本サービスの運営を妨げる行為<br />
        (13)弊社が、本サービスの運営を妨げるおそれがあると判断する量のデータ転送、サーバに負担をかける行為（不正な連続アクセスなど）<br />
        (14)商用目的の宣伝・広告行為（アダルト関連サービスへの誘導を目的として特定又は不特定多数の会員にメッセージ機能などの方法で送信したりする行為を含む）<br />
        (15)有害なコンピュータウィルス、コード、ファイル、プログラム等を開示する行為、もしくは開示されている場所について示唆する行為<br />
        (16)無限連鎖講及びマルチ商法、又はそれに類するもの、その恐れのあるものと弊社が判断する内容を掲載する行為<br />
        (17)本アプリ又は本サービスサイトをリバースエンジニアリング、逆コンパイル、逆アセンブル等により解析する行為<br />
        (18)本アプリ又は本サービスサイトを不正に改変する行為<br />
        (19)その他弊社が不適切であると判断する行為<br />
        2.会員が前項各号に違反した場合、弊社は、当該会員に対して、違約金として30万円を請求できるものとします。<br />
        <br />
        第11条（会員のコンテンツの保存について）<br />
        1.会員は、コンテンツについて、自己の責任において保存するものとします。弊社は、当該コンテンツのバックアップは行わず、保存について保証しないものとします。<br />
        2.弊社は、サーバーの稼動停止等によるコンテンツの削除について、一切の責任を負わないものとします。<br />
        <br />
        第12条（知的財産権等）<br />
        1.本サービスに関する知的財産権等その他一切の権利は、弊社又は弊社にライセンスを許諾している者に帰属します。<br />
        2.会員のコンテンツに関する著作権は、会員又は当該著作権を有する者に留保されます。会員は、弊社に対し、本サービスの提供、改善、開発、宣伝、利用促進、出版又はマーケティング等を行うために必要な範囲で、当該著作権を利用することを、無償、期間無制限かつ非独占的に許諾します。なお、会員は、弊社及び弊社の指定する者に対し、著作者人格権を行使しないものとします。<br />
        <br />
        第13条（本サービスの変更及び非保証）<br />
        1.弊社は、弊社が必要と判断する場合、 あらかじめ利用者に通知することなく、いつでも、本サービスの全部又は一部の内容を変更することができるものとします。弊社は、当該変更により会員に生じた損害について一切の責任を負いません。<br />
        2.弊社は、本サービスに事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、 権利侵害などを含みますが、これらに限りません）がないことを保証するものではありません。<br />
        3.弊社は、以下のいずれかに該当する事由により、本サービスの全部又は一部を一時的に中断し又は恒久的に中止する場合があります。この場合、原則として事前にホームページ上にて告知しますが、緊急の場合には告知なしに行うことがあります。弊社は、当該中断又は中止により会員に生じた損害について、一切の責任を負いません。<br />
        (1)本サービス用設備の保守又は工事<br />
        (2)本サービス用設備に障害が発生した場合<br />
        (3)登録電気通信事業者又はその他の電気通信事業者の提供する電気通信役務に起因して電気通信サービスの利用が不能になった場合<br />
        (4)その他やむを得ない事由により、弊社がサービスの中断又は中止が必要と判断した場合<br />
        4.弊社は、会員に対して、１ヶ月前までに通知を行うことにより本アプリ又は本サービスの一部又は全部を終了できるものとします。なお、同通知は、本アプリ又は本サービスサイト上への掲載によってなされるものとします。<br />
        5.弊社は、前項に基づく本サービスの終了により会員に生じた損害、損失、もしくはその他の費用について、一切の責任を負いません。<br />
        <br />
        第14条（契約期間）<br />
        1.利用者が本サービスの会員である限り、本契約は有効です。<br />
        2.会員は本サービスの退会申請フォームから契約終了の通知を弊社にお送りいただくことで、いつでもいかなる理由でも、本契約を終了させることができます。<br />
        3.事由の如何を問わず、有料会員が会員資格を失った場合、当該有料サービスが適用される利用期間が終了していなくても、弊社は、返金や未利用期間の日割り計算による精算を、一切行わないものとします。<br />
        <br />
        第15条（コンテンツ）<br />
        1.会員は、弊社に対し、次の各号に該当する内容を含むコンテンツを掲載しないものとします。<br />
        (1)第三者の権利を侵害する又は侵害するおそれのある内容<br />
        (2)公序良俗に反する内容<br />
        (3)他の会員へ不快感を与えるような内容<br />
        (4)虚偽の事実が含まれる内容<br />
        (5)犯罪行為又は違法行為を助長させる内容<br />
        (6)他人の著作権によって保護された作品を違法又は不正にコピーすることを助長する内容<br />
        (7)営利的又は違法な目的のために、他の利用者からパスワードや個人情報を求める内容<br />
        (8)公開されていないページや、パスワードがないとアクセスできないページを含んだ内容<br />
        (9)営利的活動又は販売を目的とする内容<br />
        (10)法令又は本規約に違反する内容<br />
        2.会員は、弊社が本サービスの適正な運用のために、コンテンツの内容を確認することがあることに同意します。弊社は、コンテンツが前項各号に定める事項に違反するものである又は不適切な内容であると判断した場合、当該コンテンツを削除することができます。<br />
        <br />
        第16条（解除）<br />
        1.弊社は、会員が次の各号に定める事項に該当した場合、あらかじめ通知することなく、当該会員に対し、コンテンツの削除、本サービスの全部又は一部の利用の停止、会員資格の取消又は本契約の解除という措置の全部又は一部を講じることができるものとします。<br />
        (1)本規約に定められている事項に違反した場合、もしくはそのおそれがあると弊社が判断した場合<br />
        (2)有料会員の場合、弊社にお支払いいただく利用料金について支払の遅滞が生じた場合<br />
        (3)会員が破産もしくは民事再生の手続の申立てを受け、又は会員自らがそれらの申立てを行うなど、利用者の信用不安が発生したと弊社が判断した場合<br />
        (4)その他、会員との信頼関係が失われた場合など、弊社と会員との契約関係の維持が困難であると弊社が判断した場合<br />
        2.弊社は、会員に対し、前項の措置について、理由を説明する義務を負わないものとし、当該措置により会員が被った損害について一切の責任を負いません。<br />
        3.弊社は、会員が有料会員でない場合、当該会員が6ヵ月以上本サービスを利用しなかった場合、当該会員との本契約を、何らの通知なく解除することができます｡<br />
        <br />
        第17条（通信の秘密及び情報の開示）<br />
        1.弊社は、電気通信事業法第4条に基づき、会員の通信の秘密を守るものとします。ただし、(1)法令に基づき当該秘密の開示を要求された場合、(2)第2項に定める請求があった場合、(3)弊社又は第三者の生命、身体又は財産の保護のために必要があると弊社が判断した場合、(4)その他弊社が合理的な必要があると判断した場合には、必要な範囲で利用者の通信の内容の確認、開示その他の処分を行うことができるものとし、利用者はこれに同意するものとします。<br />
        2.弊社は、プロバイダー責任制限法（正称：特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律）第4条に該当する請求があった場合、当該請求の範囲内で情報を開示する場合があります。弊社は、かかる開示により利用者が被った損害について一切の責任を負わないものとします。<br />
        <br />
        第18条（プライバシーポリシーの順守）<br />
        弊社は、本サービスを提供するにあたり、会員から取得した情報を、本サービスサイト上に掲示するプライバシーポリシーに従い、適切に保護し、取り扱うものとします。<br />
        <br />
        第19条（児童を誘引する行為の規制）<br />
        1.本サービスでは、インターネット異性紹介事業を利用して児童を誘引する行為の規制を行っております。この目的は、「インターネット異性紹介事業の利用に起因する児童買春その他の犯罪から児童を保護し、もって児童の健全な育成に資すること」です。 当該目的を実現するため、本サービスではサービス利用に当たって男女共に身分証明書など予め定められた方法による年齢確認を行っております。<br />
        2.弊社は、上記目的の達成のため、自己紹介文の記入欄において、自己紹介文の投稿監視を行っております。弊社は、自己紹介文の内容が不適切と判断した場合、何らの通知なく、自己紹介文の掲載内容の削除等を行う場合があり、会員はこれに同意するものとします。<br />
        <br />
        第20条（免責事項）<br />
        1.弊社は、会員が本サービスの利用によって被った損害（会員間のトラブル等、他の会員により被った損害を含む）について、その一切の責任を負わないものとします。ただし、弊社の故意若しくは重大な過失によって発生した場合又は会員が消費者契約法上の消費者に該当する場合はこの限りでなく、この場合、弊社は会員が弊社に対して支払った利用料金の総額を限度額として、損害を賠償する責任を負います。<br />
        2.弊社は、ユーザーID、パスワード及び認証コードの一致をもって、会員本人による本サービスの使用と判断するものであり、当該ユーザーID、パスワード及び認証コードが第三者に盗用されたことにより会員が被る損害について、一切の責任を負わないものとします。<br />
        3.本サービス利用の際に発生した、電話会社又は各種通信業者より請求される接続に関する費用は、会員が自己責任において管理するものとし、弊社は、いかなる保証も行わないものとします。<br />
        4.本サービスは、日本国内においてのみ使用されることを想定しており、弊社は、日本国外において本サービスの利用ができることを保証しないものとします。また、弊社は、日本国外における本サービスの利用について、一切のサポートを提供する義務を負わないものとします。<br />
        5.本アプリから紹介された相手の退会等で「いいね！」が送信できない場合、弊社は追加の紹介は行わないものとします。ただし、紹介画面表示を確認した後24時間以内、または初回紹介ブースト中に限り、紹介者が退会した場合は追加の紹介を行うこととします。<br />
        <br />
        第21条（反社会的勢力の排除）<br />
        1.本条において「反社会的勢力」とは、次の各号の一に該当するものをいう。<br />
        (1)暴力団及びその関係団体<br />
        (2)暴力団及びその関係団体の構成員<br />
        (3)総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団、その他これらに属する団体又は個人<br />
        (4)暴力、威力と詐欺的手法を駆使して経済的利益を追求する団体又は個人<br />
        (5)その他前各号所定の団体又は個人に準ずる者<br />
        2.会員は、次の各号に定める内容について、表明し保証する。<br />
        (1)自らが反社会的勢力に該当しないこと、かつ将来にわたっても該当しないこと<br />
        (2)自らが反社会的勢力と社会的に避難されるべき関係を有しないこと、かつ将来にわたっても関係を有しないこと<br />
        (3)自ら又は第三者を利用して、暴力を用いる不当な要求行為、脅迫的な言動、風説の流布・偽計又は威力を用いて相手方の信用を毀損し又は業務を妨害する行為、その他これらに準ずる行為を行わないこと<br />
        3.弊社は、会員が前項各号に違反し、又は前項の規定に基づく表明及び確約に関して虚偽の申告をしたことが判明した場合、会員に対してなんらの通知、催告も要せず、直ちに本契約の全部又は一部を解除することができるものとする。<br />
        4.弊社は、前項の規定による契約解除により会員に損害が生じても、これを一切賠償しないものとする。<br />
        5.弊社は、会員が本条の規定に違反したことにより損害を被った場合、第３項の規定による契約解除にかかわらず、当該損害について損害の賠償を会員に請求することができるものとする。<br />
        <br />
        第22条（権利義務の譲渡禁止）<br />
        会員は、弊社の書面による事前の承諾なく、本契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。<br />
        <br />
        第23条（損害賠償の請求）<br />
        利用者が本規約に違反し、又は不正もしくは違法な行為によって弊社に損害を与えた場合、弊社は当該利用者に対して損害賠償（弁護士費用を含む）を請求することができるものとします。<br />
        <br />
        第24条（準拠法）<br />
        本規約に関する準拠法は、日本法とします。<br />
        <br />
        第25条（管轄裁判所）<br />
        本規約に関連する紛争について、その訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
        <br />
        附則<br />
        令和1年10月1日制定<br />
        令和6年7月29日改訂<br />
      </>
    )
  }

  // プライバシーステートメント
  getPraivacyMessage () {
    return BaseComponent.getPraivacyMessage()
  }

  static getPraivacyMessage () {
    let privacyMessage = []
    privacyMessage.push(<span key="privacy_msg1">{'・申し込まれた個人情報やトーク内容は所属会社に共有されることはありません。'}<br /></span>)
    privacyMessage.push(<span key="privacy_msg2"><br /></span>)
    privacyMessage.push(<span key="privacy_msg3">{'・AIによる本サービスの品質向上をはかるため、また、本サービスの不正な利用を防ぐため、当社は、お客様のメッセージ内容（その他メッセージに関する情報）について、取得・利用・保存を行います。'}<br /></span>)
    privacyMessage.push(<span key="privacy_msg4"><br /></span>)
    privacyMessage.push(<span key="privacy_msg5">{'・本サービスにて得られた情報は匿名化した上で下記の利用をさせていただきます。'}<br /></span>)
    privacyMessage.push(<span key="privacy_msg6">{'１）当システムの評価とサービス・AI改善に活用いたします。'}<br /></span>)
    privacyMessage.push(<span key="privacy_msg7">{'２）入会数や成果の数値情報を参加企業を増やすために活用させていただくことがございます。'}<br /></span>)
    return privacyMessage
  }
}

BaseComponent.propTypes = {
  classes: PropTypes.object,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  setImportantErrorMessage: PropTypes.func,
  setAuthId: PropTypes.func,
  setMailAddress: PropTypes.func,
  setPassword: PropTypes.func,
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  setIsLogin: PropTypes.func,
  setAdmin: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  setMaster: PropTypes.func,
  matchings: PropTypes.array,
  setMatchings: PropTypes.func,
  friends: PropTypes.array,
  friend: PropTypes.object,
  setFriend: PropTypes.func,
  chatrooms: PropTypes.array,
  setChatRooms: PropTypes.func,
  master: PropTypes.object,
  setPhoneNumber: PropTypes.func,
  setDisableSmsVerification: PropTypes.func,
}
