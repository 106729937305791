import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Config from 'commons/config'

const useStyles = makeStyles((theme) => ({
  button: {
    width: sp => sp ? 260 : 308,
    height: sp => sp ? 44 : 52,
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    background: '#423BC7',
    '&:hover': {
      color: '#ffffff',
      background: '#423BC7',
    },
    borderRadius: 50,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3.75),
    textTransform: 'none',
  },
}))

export default function AillClubButton (props) {
  const { onClose } = props
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  const label = Config.deployMode === 'members' ?  'メンバーズサイトに戻る' : 'Aill Clubモードへ'

  return (
    <Button className={classes.button} variant="contained" onClick={onClose}>
      {label}
    </Button>
  )
}

AillClubButton.propTypes = {
  onClose: PropTypes.func,
}
