import React from 'react'
import PropTypes from 'prop-types'
import Const from 'commons/constant'
import PlanTermExpired from 'components/plan/PlanTermExpired'

/*
 * 加入しているご利用プランの期限チェック
 * スタンダード(standard): 月次自動更新で失敗した場合は期限が切れた状態になる
 */
export default class PlanTermCheck extends React.Component {
  state = {
    subscriptionExpired: false,
  }

  render () {
    const { children } = this.props
    return (
      <React.Fragment>
        {
          this.state.subscriptionExpired ? (
            <PlanTermExpired subscription={this.props.subscription} />
          ) : children
        }
      </React.Fragment>
    )
  }

  componentDidMount () {
    const { subscription } = this.props
    if (!subscription) { return }
    if (!subscription.expired_at) { return }
    if (subscription.content === Const.planTypes.FREE) { return }
    const now = new Date()
    const expDate = new Date(subscription.expired_at)
    if (expDate < now) {
      this.setState({ subscriptionExpired: true })
      return
    }
  }
}

PlanTermCheck.propTypes = {
  children: PropTypes.any.isRequired,
  user: PropTypes.object,
  subscription: PropTypes.object,
}
