import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AillClubButton from 'components/aill-club/AillClubButton'
import Benefits01 from 'images/aill-club/benefits01_sp.png'
import Benefits02 from 'images/aill-club/benefits02_sp.png'
import Benefits03 from 'images/aill-club/benefits03_sp.png'
import Benefits04 from 'images/aill-club/benefits04_sp.png'
import BenefitsTitle from 'images/aill-club/benefits_title_sp.png'

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#E1EBF4',
    paddingTop: theme.spacing(17.5),
    paddingBottom: theme.spacing(2.5),
  },
  benefits: {
    maxWidth: sp => sp ? 630 : 738,
    margin: 'auto',
  },
  img: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  benefitsTitle: {
    marginBottom: theme.spacing(2),
  },
}))

export default function BenefitsContainer (props) {
  const { onClose } = props
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  return (
    <div className={classes.root}>
      <img className={`${classes.img} ${classes.benefitsTitle}`} src={BenefitsTitle} width={spMatches ? 291.88 : 355.69} />
      <Grid className={classes.benefits} container>
        <img className={classes.img} src={Benefits01} width={spMatches ? 314 : 368.5} />
        <img className={classes.img} src={Benefits02} width={spMatches ? 314 : 368.5} />
        <img className={classes.img} src={Benefits03} width={spMatches ? 314 : 368.5} />
        <img className={classes.img} src={Benefits04} width={spMatches ? 314 : 368.5} />
      </Grid>
      {!spMatches && (
        <AillClubButton onClose={onClose} />
      )}
    </div>
  )
}

BenefitsContainer.propTypes = {
  onClose: PropTypes.func,
}
