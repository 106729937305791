import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// https://swiperjs.com/
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import { makeStyles } from '@material-ui/core/styles'
import Api from 'commons/api'
import {
  calculateHourDiff,
  // addDate
} from 'commons/utility'
import FirstApproveBanner from 'components/first-approve/FirstApproveBanner'
import FirstBoostBanner from 'components/first-boost/FirstBoostBanner'
import FreeTermExtensionChallengeBanner from 'components/free-term-extension-challenge/FreeTermExtensionChallengeBanner'
import MeetingBoostBanner from 'components/meeting-boost/MeetingBoostBanner'
// import MovieEventBanner from 'components/movie/MovieEventBanner'
import BaseComponent from 'components/parts/BaseComponent'
import ComebackBanner from 'components/parts/ComebackBanner'
import SpecialOfferBanner from 'components/special-offer/SpecialOfferBanner'
// import ValentineEventBanner from 'components/valentine-event/ValentineEventBanner'
// import WinterEventBanner from 'components/winter-event/WinterEventBanner'

SwiperCore.use([Pagination, Navigation, Autoplay])

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'visible',
    '& .swiper-pagination': {
      position: 'relative',
      bottom: 0,
    },
    '& .swiper-pagination-bullet': {
      background: '#ffffff',
      border: '1px solid blue',
      opacity: 1,
    },
    '& .swiper-pagination-bullet-active': {
      background: '#3739BD',
    },
    '& .swiper-button-next': {
      color: '#ffffff',
      top: '40%',
      right: 0,
      '&:after': {
        fontSize: 18,
        fontWeight: 'bold',
      },
    },
    '& .swiper-button-prev': {
      color: '#ffffff',
      top: '40%',
      left: 0,
      '&:after': {
        fontSize: 18,
        fontWeight: 'bold',
      },
    },
  },
}))

export default function SwipeableBanner (props) {
  const { subscription, specialOffers } = props
  const classes = useStyles()
  const [swiper, setSwiper] = useState(null)
  const slideNum = useRef(0)
  // 初回ブースト有効判定
  const firstBoostEnabled = BaseComponent.isFirstMatchingBoostEnabled(props)
  // 無料期間延長チャレンジ有効判定
  const freeExtensionChallengeEnabled = BaseComponent.isFreeExtensionChallengeEnabled(props)
  // ミッション：出会を増やそうイベント
  const [meetingBoostMission, setMeetingBoostMission] = useState(null)
  // 出会を増やそうイベントバナー表示判定：期間中
  const [enableMeetingBoostBanner, setEnableMeetingBoostBanner] = useState(false)
  // ミッション：無料期間延長チャレンジ
  const [freeTermExtensionMissions, setFreeTermExtensionMissions] = useState([])
  // 無料期間延長チャレンジバナー表示判定：未達成 or 24時間以内に達成した場合有効
  const [enableFTEMissionsBanner, setEnableFTEMissionsBanner] = useState(false)
  // ミッション：出会を増やそう(承認)イベント
  const [firstApproveMission, setFirstApproveMission] = useState(null)
  // 出会を増やそう(承認)イベントバナー表示判定：期間中
  const [enableFirstApproveBanner, setEnableFirstApproveBanner] = useState(false)
  // 期間内のシアターイベント
  // const [enableMovieEvents, setEnableMovieEvents] = useState([])
  // ミッション：ウィンターイベント2022
  // const [winterEvent2022Missions, setWinterEvent2022Missions] = useState([])
  // ウィンターイベント2022バナー表示判定：Missionがある and mission.end_at より前
  // const [winterEvent2022Banner, setWinterEvent2022Banner] = useState(false)
  // ミッション：バレンタインイベント2023
  // const [valentineEvent2023Missions, setValentineEvent2023Missions] = useState([])
  // バレンタインイベント2023バナー表示判定：Missionがある and mission.end_at より前
  // const [valentineEvent2023Banner, setValentineEvent2023Banner] = useState(false)
  // カムバックキャンペーン有効判定
  const comebackEnabled = (subscription?.campaign_types || []).includes('comeback')
  // 特別オファー
  const specialOfferEnabled = specialOffers && specialOffers.length > 0

  // イベント取得
  // useEffect(() => {
  //   loadMovieEvents()
  //   loadWinterEvent()
  //   loadValentineEvent()
  // }, [])

  // 初回ブースト表示
  useEffect(() => {
    if (!firstBoostEnabled) { return }
    slideNum.current += 1
  }, [firstBoostEnabled])

  // 出会を増やそう(承認)イベント表示
  useEffect(() => {
    loadFirstApproveMission()
  }, [])

  const loadFirstApproveMission = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getMissions('first_approve')
      setFirstApproveMission(res[0])
      // 終了前ミッション抽出
      const missions = res.filter(mission => {
        const now = new Date()
        if (now <= new Date(mission.start_at)) { return false }
        if (new Date(mission.end_at) <= now) { return false }
        return true
      })
      const enabled = 0 < missions.length
      setEnableFirstApproveBanner(enabled)
      if (enabled) { slideNum.current += 1 }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }
  // 出会を増やそうイベント取得・表示
  useEffect(() => {
    loadMeetingBoostMission()
  }, [])

  // 無料期間延長チャレンジ取得・表示
  useEffect(() => {
    if (!freeExtensionChallengeEnabled) { return }
    // 初期値nullのまま=未完了, 読み込み済みで無しの場合undefinedになる
    if (meetingBoostMission === null) { return }
    if (meetingBoostMission) { return }
    loadFTMMissions()
  }, [freeExtensionChallengeEnabled, meetingBoostMission])

  // カムバックキャンペーン表示
  useEffect(() => {
    if (!comebackEnabled) { return }
    slideNum.current += 1
  }, [comebackEnabled])

  // 特別オファー表示
  useEffect(() => {
    if (!specialOfferEnabled) { return }
    slideNum.current += specialOffers.length
  }, [specialOfferEnabled])

  const loadMeetingBoostMission = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getMissions('meeting_boost')
      setMeetingBoostMission(res[0])
      // 終了前ミッション抽出
      const missions = res.filter(mission => {
        const now = new Date()
        if (now <= new Date(mission.start_at)) { return false }
        if (new Date(mission.end_at) <= now) { return false }
        return true
      })
      const enabled = 0 < missions.length
      setEnableMeetingBoostBanner(enabled)
      if (enabled) { slideNum.current += 1 }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const loadFTMMissions = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getMissions('free_term_extension')
      setFreeTermExtensionMissions(res)
      // 終了前 and (未達成 or 24時間以内に達成した)ミッション抽出
      const now = new Date()
      const missions = res.filter(mission => {
        if (new Date(mission.end_at) <= now) { return false }
        return !mission.achieved || calculateHourDiff(new Date(mission.achieved_at), now) <= 24
      })
      const enabled = !firstBoostEnabled && (0 < missions.length)
      setEnableFTEMissionsBanner(enabled)
      if (enabled) { slideNum.current += 1 }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // シアターイベント取得
  // const loadMovieEvents = async () => {
  //   props.setLoading(true)
  //   try {
  //     const res = await Api.getMovieEvents('trailer')
  //     // 期間内のイベント抽出
  //     const today = new Date()
  //     const enableEvents = res.filter(event => (
  //       new Date(event.start_at) < today && today < new Date(event.end_at)
  //     ))
  //     setEnableMovieEvents(enableEvents)
  //     if (enableEvents.length > 0) { slideNum.current += 1 }
  //   } catch (error) {
  //     BaseComponent.handleApiError(props, error)
  //   } finally {
  //     props.setLoading(false)
  //   }
  // }

  // ウィンターイベント2022取得
  // const loadWinterEvent = async () => {
  //   props.setLoading(true)
  //   try {
  //     const res = await Api.getMissions('winter_event_2022')
  //     setWinterEvent2022Missions(res)
  //     //自分の Mission が存在する and 現在時刻が (end_at +24時間) より前
  //     const enabled = res.filter(mission => new Date() <= addDate(mission.end_at, 1)).length > 0
  //     setWinterEvent2022Banner(enabled)
  //     if (enabled) { slideNum.current += 1 }
  //   } catch (error) {
  //     BaseComponent.handleApiError(props, error)
  //   } finally {
  //     props.setLoading(false)
  //   }
  // }

  // バレンタインイベント2023取得
  // const loadValentineEvent = async () => {
  //   props.setLoading(true)
  //   try {
  //     const res = await Api.getMissions('valentine_event_2023')
  //     setValentineEvent2023Missions(res)
  //     // 自分の Mission が存在する and 現在時刻が (end_at +24時間) より前
  //     const enabled = res.filter(mission => new Date() <= addDate(mission.end_at, 1)).length > 0
  //     setValentineEvent2023Banner(enabled)
  //     if (enabled) { slideNum.current += 1 }
  //   } catch (error) {
  //     BaseComponent.handleApiError(props, error)
  //   } finally {
  //     props.setLoading(false)
  //   }
  // }

  useEffect(() => {
    if (!swiper) { return }
    const autoplay = swiper.autoplay
    slideNum.current > 1 ? autoplay.start() : autoplay.stop()
  }, [slideNum.current, swiper])

  return (
    <>
      {slideNum.current > 0 && (
        <Swiper
          className={classes.root}
          slidesPerView={1}
          loop={slideNum.current > 1}
          navigation={slideNum.current > 1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          onSwiper={setSwiper}
        >
          {firstBoostEnabled && (
            <SwiperSlide>
              <FirstBoostBanner {...props} />
            </SwiperSlide>
          )}
          {enableMeetingBoostBanner && (
            <SwiperSlide>
              <MeetingBoostBanner {...props} mission={meetingBoostMission} />
            </SwiperSlide>
          )}
          {enableFTEMissionsBanner && (
            <SwiperSlide>
              <FreeTermExtensionChallengeBanner missions={freeTermExtensionMissions} {...props} />
            </SwiperSlide>
          )}
          {enableFirstApproveBanner && (
            <SwiperSlide>
              <FirstApproveBanner {...props} mission={firstApproveMission} />
            </SwiperSlide>
          )}
          {/* {enableMovieEvents.map(movieEvent => (
            <SwiperSlide key={movieEvent.id}>
              <MovieEventBanner movieEvent={movieEvent} {...props} />
            </SwiperSlide>
          ))} */}
          {/* {winterEvent2022Banner && (
            <SwiperSlide>
              <WinterEventBanner missions={winterEvent2022Missions} {...props} />
            </SwiperSlide>
          )}
          {valentineEvent2023Banner && (
            <SwiperSlide>
              <ValentineEventBanner missions={valentineEvent2023Missions} {...props} />
            </SwiperSlide>
          )} */}
          {comebackEnabled && (
            <SwiperSlide>
              <ComebackBanner {...props} />
            </SwiperSlide>
          )}
          {specialOfferEnabled && (
            <>
              {specialOffers.map(offer => (
                <SwiperSlide key={offer.id}>
                  <SpecialOfferBanner {...props} offer={offer} />
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      )}
    </>
  )
}

SwipeableBanner.propTypes = {
  subscription: PropTypes.object,
  specialOffers: PropTypes.array,
  setLoading: PropTypes.func,
}
