import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import About from 'images/aill-club/about.png'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
  },
  content: {
    maxWidth: 400,
    paddingTop: `${theme.spacing(5)}px !important`,
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  text: {
    fontSize: 16,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textAlign: 'left',
  },
  about: {
    marginTop: theme.spacing(-5),
  },
  form: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3),
    '& span': {
      fontSize: 15,
      lineHeight: 1,
      display: 'inline-block',
      padding: 0,
      margin: 0,
    },
  },
  actions: {
    paddingTop: theme.spacing(3.25),
  },
}))

export default function StopWithdrawalDialog (props) {
  const { open, onConfirm, onNext } = props
  const classes = useStyles()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!open) { return }
    setChecked(false)
  }, [open])

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} style={{ zIndex: 9998 }}>
        <DialogContent>
          <Typography className={classes.text}>
            退会するとお付き合い中限定のAill Clubモードがお二人とも使用できなくなります。<br />
            パートナーとご相談の上、<br />
            退会に進まれるようお願いいたします。
          </Typography>
          <img className={classes.about} src={About} width={286} onClick={onConfirm} />
          <FormControlLabel
            className={classes.form}
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                style={{ color: '#423BC7' }}
              />
            }
            label="パートナーと合意済み"
          />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button variant="contained" color="secondary" onClick={onNext} disabled={!checked}>
            進む
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

StopWithdrawalDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onNext: PropTypes.func,
}
