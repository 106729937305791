import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Const from 'commons/constant'
import Currency from 'commons/currency'
import { addDate, formatPrevJapaneseDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  currentPlan: {
    fontSize: 14,
    width: '100%',
    color: '#707070',
    fontWeight: 'bold',
    border: '1px solid #707070',
    padding: theme.spacing(4),
  },
  item: {
    minWidth: 175,
    lineHeight: 1,
  },
  mt: {
    marginTop: theme.spacing(6),
  },
  mt1: {
    marginBottom: theme.spacing(1),
  },
  myPlanValue: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    marginTop: theme.spacing(0.5),
    whiteSpace: 'pre-wrap',
  },
  expReason: {
    fontSize: 16,
    fontWeight: 600,
    color: '#F8608B',
  },
  divider: {
    width: '100%',
    height: 1,
    background: '#0000001F',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export default function CurrentPlan (props) {
  const { plan, subscription, organization, billings, friends, next, monthlyFees } = props
  const classes = useStyles()
  const [nextBilling, setNextBilling] = useState(null)
  const spMatches = !useMediaQuery('(min-width:1080px)')
  // トーク中
  const friendCount = (friends || []).filter(friend => {
    if (!friend) { return false }
    if (friend.type === 'daily') { return false }
    if (friend.type === 'friend_receiving') { return false }
    if (friend.type === 'friend_applying') { return false }
    if (friend.type === 'friend_pending') { return false }
    if (friend.type === 'friend_pending_canceled') { return false }
    if (friend.type === 'ai') { return false }
    return true
  }).length
  // 保留中
  const pendingCount = (friends || []).filter(friend => {
    if (!friend) { return false }
    if (friend.type === 'friend_pending') { return true }
    return false
  }).length

  useEffect(() => {
    if (subscription && subscription.next_plan_id) {
      (async () => {
        try {
          const nextPaymentMonths = subscription.next_payment_months || 1
          const res = await Api.getMyNextBilling(
            subscription.next_plan_id, nextPaymentMonths
          )
          setNextBilling(res)
        } catch (error) {
          BaseComponent.handleApiError(props, error)
        }
      })()
    }
  }, [subscription])

  if (!(plan && subscription && organization && billings)) {
    return (
      <Typography className={classes.currentPlan}>
        読み込み中…
      </Typography>
    )
  }

  const currentPlanId = subscription.content
  const campaignTitles = subscription.campaign_titles
  const expTime = subscription?.expired_at ? new Date(subscription.expired_at) : null
  const now = new Date()

  const formatCurrentAmount = () => {
    if (currentPlanId === Const.planTypes.FREE) { return '0円' }
    if (currentPlanId === Const.planTypes.SPECIAL_FREE) { return '0円' }
    if (currentPlanId === Const.planTypes.STANDARD_FREE) { return '0円' }
    if (!expTime) { return '0円' }
    if (!subscription || !monthlyFees || monthlyFees.length === 0) { return '' }
    const fee = monthlyFees.find(f => f.payment_months === subscription.payment_months)
    if (!fee) { return '' }
    return `${Currency.format(fee.price)}円 (税込) ${expTime < now ? '（有効期限切れ）' : ''}`
  }

  const formatNextBillingDate = () => {
    if (subscription.next_plan_id === Const.planTypes.FREE) { return '-' }
    if (!subscription?.next_billing_date) { return '-' }
    if (new Date(subscription.next_billing_date) < now) { return '-' }
    return dateFormat(subscription.next_billing_date, 'yyyy年m月d日')
  }

  const formatExpDate = () => {
    switch (currentPlanId) {
      case Const.planTypes.STANDARD_INITIAL:
        return dateFormat(addDate(expTime, -1), 'yyyy年m月d日')
      case Const.planTypes.STANDARD:
      case Const.planTypes.STANDARD_FREE:
      case Const.planTypes.PREMIUM:
        return `${dateFormat(expTime, 'yyyy年m月d日')} 午前${Const.subscriptionUpdateHour}時`
      default:
        return ''
    }
  }

  const expReason = (() => {
    if (!expTime) { return null }
    if (now < expTime) { return null }
    switch (subscription.expired_reason) {
      case 'payment_not_set':
        return 'お支払い方法が設定されていません。'
      case 'billing_failed':
        return 'ご利用料金の請求時に問題が発生しました。お支払い方法をご確認ください。'
      case 'can_not_change_to_trial':
        return '複数の異性とトーク中のため、フリープランへ移行できませんでした。'
      case 'end_of_monitor_term':
        return ''
      default:
        return null
    }
  })()

  const formatNextBillingAmount = () => {
    if (!nextBilling) { return '-' }
    return `${Currency.format(nextBilling.amount)}円`
  }

  const formatNextBillingNotes = () => {
    if (subscription.next_plan_id == Const.planTypes.STANDARD_FREE) {
      return `＊${dateFormat(expTime, 'yyyy年m月d日')}からモニター期間に移行します。`
    }
    if (!nextBilling) { return }
    if (!nextBilling.campaign_titles) { return }
    if (nextBilling.campaign_titles.length === 0) { return }
    if (nextBilling.plan_id === Const.planTypes.FREE) { return }
    return `${nextBilling.campaign_titles.join(',')}適用予定`
  }

  const createColumn = (title, value, note = null) => {
    return (
      <Grid className={`${classes.item} ${spMatches && classes.mt1}`} item>
        {title}
        <Typography color='primary' className={classes.myPlanValue}>
          {value}{note && (<>&nbsp;&nbsp;＊{note}</>)}
        </Typography>
      </Grid>
    )
  }

  const createText = () => {
    if (next) { return '' }
    if (currentPlanId === Const.planTypes.FREE) { return '' }
    if (currentPlanId === Const.planTypes.SPECIAL_FREE) { return '' }
    if (!organization) { return '' }
    if (!subscription) { return '' }
    if (organization.full_paid_enabled) {
      let text = `＊${organization.canonical_name}の従業員様は`
      if (currentPlanId === Const.planTypes.STANDARD_INITIAL) {
        text += '、無料トライアル期間終了後も無料でご利用いただけます。'
      } else if (currentPlanId === Const.planTypes.STANDARD_FREE && subscription.monitor) {
        text += '、モニター期間終了後も無料でご利用いただけます。'
      } else {
        const endDate = formatPrevJapaneseDate(organization?.full_paid_end_date)
        text += `${endDate ? `${endDate}まで` : ''}無料でご利用いただけます。`
      }
      return text
    } else {
      if (!expTime) { return '' }
      const freeDays = subscription.free_days
      if (currentPlanId === Const.planTypes.STANDARD_INITIAL) {
        // 「yyyy年m月d日までご入会後に無料でご利用いただける期間。うち◯日間はキャンペーン適用。」
        const freeDaysText = 0 < freeDays ? `うち${freeDays}日間はキャンペーン適用。` : ''
        return `＊${formatExpDate()}まで${plan.note}。${freeDaysText}`
      } else {
        if (currentPlanId === Const.planTypes.STANDARD_FREE) {
          return `＊${formatExpDate()}までモニター期間。`
        }
        // 「yyyy年m月d日 H時まで〇〇適用中。うち無料期間◯日。」
        const campaignText = 0 < campaignTitles?.length ? campaignTitles.join(',') + '適用中。' : ''
        const freeDaysText = 0 < freeDays ? `うち無料期間${freeDays}日。` : ''
        return `＊${formatExpDate()}まで${campaignText}${freeDaysText}`
      }
    }
  }

  const formatPriceLabel = () => {
    if (1 < subscription.payment_months) {
      return `月額（${subscription.payment_months}ヶ月分）`
    } else {
      return '月額'
    }
  }

  const formatNextPriceLabel = () => {
    if (1 < subscription.next_payment_months) {
      return `ご請求予定金額（${subscription.next_payment_months}ヶ月分）`
    } else {
      return 'ご請求予定金額'
    }
  }

  return (
    <Grid className={classes.currentPlan}>
      <Grid container justifyContent="flex-start" alignItems="center">
        {createColumn('ご利用中のプラン', plan.plan_name)}
        {createColumn(formatPriceLabel(), formatCurrentAmount())}
      </Grid>
      <div>
        {createColumn(' ', createText())}
        {expReason && <Typography className={classes.expReason}>＊{expReason}</Typography>}
      </div>
      {(!expReason && (subscription?.next_plan_id || '').match(/standard/) && currentPlanId !== Const.planTypes.STANDARD_FREE) && (
        <>
          <div className={classes.divider} />
          <Grid container justifyContent="flex-start" alignItems="center">
            {createColumn('次回のご請求日', formatNextBillingDate())}
            {createColumn(formatNextPriceLabel(), formatNextBillingAmount())}
          </Grid>
          <div>
            {createColumn('', formatNextBillingNotes())}
          </div>
        </>
      )}
      {!next && (
        <>
          <div className={classes.divider} />
          <Grid container justifyContent="flex-start" alignItems="center">
            {createColumn('アプリ利用状況', `トーク中: ${friendCount}人    保留中: ${pendingCount}人`)}
          </Grid>
        </>
      )}
    </Grid>
  )
}

CurrentPlan.propTypes = {
  plan: PropTypes.object,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  billings: PropTypes.array,
  friends: PropTypes.array,
  next: PropTypes.bool,
  monthlyFees: PropTypes.array,
}
