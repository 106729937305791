import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MatchingFilterDialog from './MatchingFilterDialog'
import ItemContainer from './MatchingFilterItemContainer'
import ItemLabel from './MatchingFilterItemLabel'
import TextValue from './MatchingFilterTextValue'
import MatchingFilterWheelPicker from './MatchingFilterWheelPicker'

const values = [140, 150, 160, 170, 180, 190, 200]
const items = [
  { id: 'none', value: 'なし' },
  ...values.map(v => ({ id: v.toString(), value: `${v}cm` })),
]

export default function MatchingFilterHeight (props) {
  const { matchingConfig, onSave } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [disabledSave, setDisabledSave] = useState(false)
  const [minHeight, setMinHeight] = useState(null)
  const [maxHeight, setMaxHeight] = useState(null)

  const resetHeights = () => {
    setMinHeight(matchingConfig?.min_height || null)
    setMaxHeight(matchingConfig?.max_height || null)
  }

  useEffect(resetHeights, [matchingConfig])

  useEffect(() => {
    setDisabledSave((maxHeight || 999) <= (minHeight || 0))
  }, [minHeight, maxHeight])

  const createValueText = () => {
    if (!minHeight && !maxHeight) { return 'なし' }
    const minText = minHeight ? `${minHeight}cm` : ''
    const maxText = maxHeight ? `${maxHeight}cm` : ''
    return `${minText} 〜 ${maxText}`
  }

  const onChangeMin = (item) => {
    setMinHeight(itemToValue(item))
  }

  const onChangeMax = (item) => {
    setMaxHeight(itemToValue(item))
  }

  const itemToValue = (item) => {
    return (item.id === 'none') ? null : parseInt(item.id)
  }

  const handleSave = async () => {
    if (onSave) {
      await onSave(minHeight, maxHeight)
    }
    setOpenDialog(false)
  }

  const handleCancel = () => {
    resetHeights()
    setOpenDialog(false)
  }

  return (<>
    <ItemContainer>
      <ItemLabel>
        相手の身長
      </ItemLabel>
      <TextValue
        value={createValueText()}
        onClick={() => setOpenDialog(true)}
        data-testid='MatchingFilter-height'
      />
    </ItemContainer>
    <MatchingFilterDialog
      open={openDialog}
      title="相手の身長"
      disabledSave={disabledSave}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <MatchingFilterWheelPicker
        data={items}
        selectedID={minHeight ? minHeight.toString() : 'none'}
        onChange={onChangeMin}
      />
      <span style={{ fontSize: 16, fontWeight: 700, color: '#423BC7' }}>〜</span>
      <MatchingFilterWheelPicker
        data={items}
        selectedID={maxHeight ? maxHeight.toString() : 'none'}
        onChange={onChangeMax}
      />
    </MatchingFilterDialog>
  </>)
}

MatchingFilterHeight.propTypes = {
  matchingConfig: PropTypes.object,
  onSave: PropTypes.func,
}