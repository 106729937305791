import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KomaFace from 'images/aill-club/komainu_face_sp.png'

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(3),
    background: sp => sp ? '#E1EBF4' : '#616AFA',
  },
  attentionBox: {
    width: 314,
    borderRadius: 20,
    margin: 'auto',
    boxShadow: '0px 3px 6px #00000029',
    '& div:nth-of-type(1)': {
      width: '100%',
      height: 210,
      background: '#616AFA',
      borderRadius: '20px 20px 0 0',
      paddingTop: theme.spacing(2.5),
      backgroundImage: `url(${KomaFace})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    '& div:nth-of-type(2)': {
      width: '100%',
      height: 136,
      background: '#ffffff',
      borderRadius: '0 0 20px 20px',
      paddingTop: theme.spacing(2),
      '& ol': {
        maxWidth: 270,
        fontSize: 12,
        fontWeight: 400,
        color: '#060179',
        lineHeight: '18px',
        paddingLeft: theme.spacing(1.25),
        margin: 'auto',
        '& li': {
          marginBottom: theme.spacing(1.5),
        },
      },
    },
  },
  text: {
    width: 'fit-content',
    fontSize: 20,
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: 1,
    letterSpacing: '2.0px',
    textAlign: 'center',
    margin: 'auto',
  },
  note: {
    fontSize: 12,
    fontWeight: 400,
    color: sp => sp ? '#060179' : '#ffffff',
  },
  container: {
    width: 715,
    height: 277,
    margin: 'auto',
    position: 'relative',
  },
  leftbox: {
    width: 314,
    height: 211,
    position: 'absolute',
    left: 0,
    bottom: 0,
    backgroundImage: `url(${KomaFace})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  rightbox: {
    width: 284,
    height: 133,
    position: 'absolute',
    right: 0,
    bottom: 65,
  },
  marker: {
    background: 'linear-gradient(transparent 55%, #423BC7 0%)',
    padding: '0 4px',
  },
}))

export default function AttentionBox () {
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  if (spMatches) {
    return (
      <div className={classes.root}>
        <div className={classes.attentionBox}>
          <div>
            <Typography className={classes.text} style={{ marginBottom: 12 }}>注意点</Typography>
            <Typography className={`${classes.text} ${classes.marker}`} style={{ lineHeight: 1, marginBottom: 12 }}>
              お付き合いしている二人が
            </Typography>
            <Typography className={`${classes.text} ${classes.marker}`} style={{ lineHeight: 1, marginBottom: 12 }}>
              退会していないことが条件
            </Typography>
            <Typography className={classes.text} style={{ fontSize: 12, lineHeight: '18px' }}>
              間違って退会しないように<br />
              気をつけて！
            </Typography>
          </div>
          <div>
            <ol type="1">
              <li className={classes.note}>
                お付き合い状態の2人のうち、どちらか一方が退会、もしくは、お付き合い状態を解除するとAill Clubは利用できなくなります。
              </li>
              <li className={classes.note}>
                Aill Clubの利用が停止となった時点で特典は対象外となり、トーク履歴も削除されます。
              </li>
            </ol>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftbox}>
          <Typography className={classes.text} style={{ marginBottom: 12 }}>注意点</Typography>
          <Typography className={`${classes.text} ${classes.marker}`} style={{ lineHeight: 1, marginBottom: 12 }}>
            お付き合いしている二人が
          </Typography>
          <Typography className={`${classes.text} ${classes.marker}`} style={{ lineHeight: 1, marginBottom: 12 }}>
            退会していないことが条件
          </Typography>
          <Typography className={classes.text} style={{ fontSize: 12, lineHeight: '18px' }}>
            間違って退会しないように<br />
            気をつけて！
          </Typography>
        </div>
        <div className={classes.rightbox}>
          <ol type="1">
            <li className={classes.note} style={{ marginBottom: 14 }}>
              お付き合い状態の2人のうち、どちらか一方が退会、もしくは、お付き合い状態を解除するとAill Clubは利用できなくなります。
            </li>
            <li className={classes.note}>
              Aill Clubの利用が停止となった時点で特典は対象外となり、トーク履歴も削除されます。
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

AttentionBox.propTypes = {}
