import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Config from 'commons/config'
import { calculateAge } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import MatchingFilterDialog from './MatchingFilterDialog'
import ItemContainer from './MatchingFilterItemContainer'
import ItemLabel from './MatchingFilterItemLabel'
import TextValue from './MatchingFilterTextValue'
import valueStyle from './MatchingFilterValueStyle'
import MatchingFilterWheelPicker from './MatchingFilterWheelPicker'

const ageUpperValues = [
  { id: '15', value: '+15歳' },
  { id: '14', value: '+14歳' },
  { id: '13', value: '+13歳' },
  { id: '12', value: '+12歳' },
  { id: '11', value: '+11歳' },
  { id: '10', value: '+10歳' },
  { id: '9', value: '+9歳' },
  { id: '8', value: '+8歳' },
  { id: '7', value: '+7歳' },
  { id: '6', value: '+6歳' },
  { id: '5', value: '+5歳' },
  { id: '4', value: '+4歳' },
  { id: '3', value: '+3歳' },
  { id: '2', value: '+2歳' },
  { id: '1', value: '+1歳' },
  { id: '0', value: '同年齢' },
  { id: '99', value: 'なし' },
]

const ageLowerValues = [
  { id: '99', value: 'なし' },
  { id: '0', value: '同年齢' },
  { id: '1', value: '-1歳' },
  { id: '2', value: '-2歳' },
  { id: '3', value: '-3歳' },
  { id: '4', value: '-4歳' },
  { id: '5', value: '-5歳' },
  { id: '6', value: '-6歳' },
  { id: '7', value: '-7歳' },
  { id: '8', value: '-8歳' },
  { id: '9', value: '-9歳' },
  { id: '10', value: '-10歳' },
  { id: '11', value: '-11歳' },
  { id: '12', value: '-12歳' },
  { id: '13', value: '-13歳' },
  { id: '14', value: '-14歳' },
  { id: '15', value: '-15歳' },
]

const useStyles = makeStyles(theme => ({
  // app: MatchingFilter
  value: valueStyle(theme),
  valueContainer: {
    width: 'fit-content',
  },
  arrowdown: {
    fontSize: 16,
    color: '#423BC7',
  },
  // entry: LoveSheet
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    width: '100%',
    position: 'relative',
  },
  inputLabel: {
    fontSize: 14,
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
  },
}))

export default function MatchingFilterAge (props) {
  const { user } = props
  const classes = useStyles()
  const [ageFrom, setAgeFrom] = useState(0)
  const [ageTo, setAgeTo] = useState(0)
  const [openSelectAgeDialog, setOpenSelectAgeDialog] = useState(false)

  useEffect(() => {
    if (!user) { return }
    const min = user.matching_min_age_diff == null ? 99 : user.matching_min_age_diff
    const max = user.matching_max_age_diff == null ? 99 : user.matching_max_age_diff
    setAgeFrom(min)
    setAgeTo(max)
  }, [user])

  // 年齢選択ポップアップが閉じるたびに発火
  useEffect(() => {
    if (openSelectAgeDialog) { return }
    const min = user.matching_min_age_diff == null ? 99 : user.matching_min_age_diff
    const max = user.matching_max_age_diff == null ? 99 : user.matching_max_age_diff
    setAgeFrom(min)
    setAgeTo(max)
  }, [openSelectAgeDialog])

  const createAgeLabel = () => {
    if (ageFrom == 99 && ageTo == 99) {
      return '設定なし'
    } else if (ageFrom == 99) {
      return `〜 +${ageTo}歳`
    } else if (ageTo == 99) {
      return `-${ageFrom}歳 〜`
    } else {
      return `-${ageFrom}歳 〜 +${ageTo}歳`
    }
  }

  const saveAge = async () => {
    props.setLoading(true)
    try {
      const params = {
        matching_min_age_diff: ageFrom == 99 ? null : ageFrom,
        matching_max_age_diff: ageTo == 99 ? null : ageTo,
      }
      await BaseComponent.updateUser(props, params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
    setOpenSelectAgeDialog(false)
  }

  const createDialog = () => (
    <MatchingFilterDialog
      open={openSelectAgeDialog}
      title="相手の年齢"
      onSave={saveAge}
      onCancel={() => setOpenSelectAgeDialog(false)}
    >
      <MatchingFilterWheelPicker
        data={ageLowerValues.filter(v => Number(v.id) === 99 || (18 <= calculateAge(user.birthday) - Number(v.id)))}
        selectedID={String(ageFrom) || '0'}
        onChange={(item) => setAgeFrom(item.id)}
      />
      <span style={{ fontSize: 16, fontWeight: 700, color: '#423BC7' }}>〜</span>
      <MatchingFilterWheelPicker
        data={ageUpperValues}
        selectedID={String(ageTo) || '0'}
        onChange={(item) => setAgeTo(item.id)}
        data-testid="age-to-list"
      />
    </MatchingFilterDialog>
  )

  // 恋愛情報入力画面ではstyleが違う
  if (Config.deployMode === 'entry') {
    return (
      <>
        <FormControl className={classes.formControl} key={'matching-filter-age'} fullWidth onClick={() => setOpenSelectAgeDialog(true)}>
          <TextField
            label={'希望する相手の年齢'}
            value={createAgeLabel()}
            InputLabelProps={{ classes: { root: classes.inputLabel } }}
          />
          <KeyboardArrowDown style={{ position: 'absolute', right: 0, bottom: 2 }} />
          {/* app: プロフィール詳細画面では表示しないので非公開としておく */}
          <div><Typography variant="body1" className={classes.itemStatus}>非公開</Typography></div>
        </FormControl>
        {createDialog()}
      </>
    )
  }

  return (
    <>
      <ItemContainer>
        <ItemLabel>
          相手の年齢
        </ItemLabel>
        <TextValue
          value={createAgeLabel()}
          onClick={() => setOpenSelectAgeDialog(true)}
          data-testid='MatchingFilter-age'
        />
      </ItemContainer>
      {createDialog()}
    </>
  )
}

MatchingFilterAge.propTypes = {
  user: PropTypes.object,
  setLoading: PropTypes.func,
}
