import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AillClubButton from 'components/aill-club/AillClubButton'
import BenefitsContainer from 'components/aill-club/BenefitsContainer'
import BottomContainer from 'components/aill-club/BottomContainer'
import TopContainer from 'components/aill-club/TopContainer'
import WhatContainer from 'components/aill-club/WhatContainer'

const useStyles = makeStyles(() => ({
  content: {
    padding: '0 !important',
  },
  fixed: {
    position: 'fixed',
    transform: 'translate(-50%, -0%)',
    left: '50%',
    bottom: 44,
    zIndex: 99,
  },
}))

export default function AboutAillClub (props) {
  const { open, onClose } = props
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  return (
    <Dialog open={open} fullScreen>
      <DialogContent className={classes.content}>
        {spMatches && (
          <div className={classes.fixed}>
            <AillClubButton onClose={onClose} />
          </div>
        )}
        <TopContainer onClose={onClose} />
        <BenefitsContainer onClose={onClose} />
        <WhatContainer />
        <BottomContainer onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}

AboutAillClub.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
