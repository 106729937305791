import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AillClubButton from 'components/aill-club/AillClubButton'
import KomaMain from 'images/aill-club/komainu_main_sp.png'
import TopImg from 'images/aill-club/top_photo_sp.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: sp => sp ? 616 : 698,
    position: 'relative',
    backgroundColor: '#E9EEF4',
    backgroundImage: `url(${TopImg})`,
    backgroundSize: sp => sp ? '1000px' : '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '60%',
    backgroundPositionY: '100%',
  },
  title: {
    width: sp => sp ? 'unset' : 340,
    fontSize: sp => sp ? 30 : 42,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: sp => sp ? '40px' : '56px',
    textAlign: 'center',
    paddingTop: sp => sp ? theme.spacing(5.5) : 0,
    marginBottom: theme.spacing(3.5),
    position: sp => sp ? 'unset' : 'absolute',
    top: 120,
    left: '13%',
  },
  subtitle: {
    width: sp => sp ? 'unset' : 340,
    fontSize: sp => sp ? 15 : 21,
    fontWeight: 600,
    color: '#060179',
    lineHeight: sp => sp ? '22px' : '31px',
    letterSpacing: sp => sp ? '0.3px' : '0.42px',
    textAlign: 'center',
    position: sp => sp ? 'unset' : 'absolute',
    top: 261,
    left: '13%',
  },
  topBox: {
    width: sp => sp ? 286 : 340,
    height: sp => sp ? 144 : 171,
    backgroundColor: '#ffffff',
    backgroundImage: `url(${KomaMain})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: sp => sp ? '50%' : 0,
    backgroundPositionY: '100%',
    borderRadius: 21,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3.5),
    position: 'absolute',
    transform: sp => sp ? 'translate(-50%, -0%)' : 'unset',
    left: sp => sp ? '50%' : '13%',
    bottom: sp => sp ? -56 : 108,
    textAlign: 'center',
    boxShadow: '0px 3px 6px #00000029',
  },
  topText: {
    fontSize: sp => sp ? 14 : 16,
    fontWeight: 600,
    color: '#060179',
    lineHeight: '22px',
    letterSpacing: '2.0px',
  },
  topSubText: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: '2.0px',
  },
  free: {
    width: 63,
    height: 63,
    background: '#F55083',
    borderRadius: '50%',
    position: 'absolute',
    top: -14,
    right: -24,
    '& p': {
      fontSize: 10,
      fontWeight: 600,
      color: '#ffffff',
      lineHeight: 1,
      '& span': {
        fontSize: 30,
      },
    },
  },
  absolute: {
    position: sp => sp ? 'unset' : 'absolute',
    bottom: 23,
    left: 'calc(13% + 20px)',
  },
}))

export default function TopContainer (props) {
  const { onClose } = props
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        ずっとこれからも<br />
        二人で。
      </Typography>
      <Typography className={classes.subtitle}>
        Aill Clubは<br />
        Aill goenでお付き合いした<br />
        お二人を応援し続けます。
      </Typography>
      <Grid
        className={classes.topBox}
        container
        direction="column"
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.topText}>
          今後は、二人だけで楽しむ<br />
          Aill Clubモードに<br />
          なります。
        </Typography>
        <Typography className={classes.topSubText}>
          引き続きヨロシク！
        </Typography>
        <Grid className={classes.free} container direction="column" justifyContent="center">
          <Typography>利用料</Typography>
          <Typography><span>0</span>円</Typography>
        </Grid>
      </Grid>
      {!spMatches && (
        <div className={classes.absolute}>
          <AillClubButton onClose={onClose} />
        </div>
      )}
    </div>
  )
}

TopContainer.propTypes = {
  onClose: PropTypes.func,
}
