import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import { canUseCafeteriaPoint } from 'commons/utility'
import PlanInfo from 'components/members/PlanInfo'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import PlanConfirmDialog from 'components/plan/PlanConfirmDialog'

const useStyles = makeStyles(() => ({
  infoText: {
    fontSize: 14,
    color: '#1A1667',
    textAlign: 'center'
  },
  link: {
    fontWeight: 600,
    color: '#1A1667',
    textDecoration: 'underline',
    textDecorationColor: '#1A1667',
    '& :visited': {
      color: '#1A1667'
    }
  },
}))

export default function SelectPlan (props) {
  const {
    subscription, organization, myPref, monthlyFees, nextMonthlyFees,
    payment, matchingState, scroll, refs, ...others
  } = props
  const classes = useStyles()
  const [nextBilling, setNextBilling] = useState(null)
  const [openPlanConfirmDialog, setOpenPlanConfirmDialog] = useState(false)
  const [openPaymentNotFound, setOpenPaymentNotFound] = useState(false)
  const [openFewRemainingMatching, setOpenFewRemainingMatching] = useState(false)

  if (!subscription) {
    return (
      <Typography>読み込み中…</Typography>
    )
  }

  // 特別フリープラン対象から外れる数日前に next_plan_id がフリープランになる。
  // ユーザーが自分で特別フリープランに戻せないようにしておく。
  const nextIsNotFree = (subscription.next_plan_id !== Const.planTypes.FREE)
  const spFreeTarget = (myPref?.special_free_plan_target && nextIsNotFree)

  const onClickSelectPlan = async (planId, months) => {
    props.setLoading(true)
    try {
      const nextBilling = await Api.getMyNextBilling(planId, months)
      if (0 < nextBilling.amount && !payment) {
        setOpenPaymentNotFound(true)
        return
      }
      const count = matchingState.good_remaining_target_count
      if (0 < nextBilling.amount && count < nextBilling.payment_months * 15) {
        setOpenFewRemainingMatching(true)
        return
      }
      setNextBilling(nextBilling)
      setOpenPlanConfirmDialog(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onConfirmOK = async (keepFriendUserId) => {
    setOpenPlanConfirmDialog(false)
    props.setLoading(true)
    try {
      const params = {
        content: nextBilling.plan_id,
        payment_months: nextBilling.payment_months,
        keep_friend_user_id: keepFriendUserId,
      }
      await Api.updateMySubscription(params)
      window.location.reload()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  if (!monthlyFees) {
    return <Typography>読み込み中…</Typography>
  }

  return (
    <>
      {canUseCafeteriaPoint(props.user) ? (
        <>
          <Typography className={classes.infoText}>2つのプランをご用意しています。</Typography>
          <Typography component="div" className={classes.infoText}>カフェテリアポイントを利用しての支払いも可能です。</Typography>
          <Typography className={classes.infoText}>
            カフェテリアポイントについては
            <a className={classes.link} onClick={() => scroll('cafeteriaRef')}>こちら</a>
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.infoText}>スタンダードプランとフリープランの</Typography>
          <Typography className={classes.infoText}>2つのプランをご用意しています。</Typography>
        </>
      )}
      <PlanInfo
        planType={Const.planTypes.STANDARD}
        subscription={subscription}
        organization={organization}
        monthlyFees={monthlyFees}
        nextMonthlyFees={nextMonthlyFees}
        matchingState={matchingState}
        onClickSelectPlan={onClickSelectPlan}
        refs={refs}
      />
      <PlanInfo
        planType={spFreeTarget ? Const.planTypes.SPECIAL_FREE : Const.planTypes.FREE}
        subscription={subscription}
        organization={organization}
        monthlyFees={monthlyFees}
        onClickSelectPlan={onClickSelectPlan}
        refs={refs}
      />
      <PlanConfirmDialog
        open={openPlanConfirmDialog}
        myPref={myPref}
        subscription={subscription}
        organization={organization}
        nextBilling={nextBilling}
        matchingState={matchingState}
        onConfirmOK={onConfirmOK}
        onClose={() => setOpenPlanConfirmDialog(false)}
        {...others}
      />
      <ConfirmDialog
        open={openPaymentNotFound}
        onClose={() => setOpenPaymentNotFound(false)}
        close="yes"
        title="エラー"
        message="お支払い方法を登録してください。"
      />
      <ConfirmDialog
        open={openFewRemainingMatching}
        onClose={() => setOpenFewRemainingMatching(false)}
        close="yes"
        title={'現在このプランは\nご利用いただけません'}
        message={'お住まい及びご年齢により、ご紹介できる異性の人数が少なくなっております。\n大変申し訳ございませんが、ご了承いただきますようお願いいたします。'}
        messageLeft
      />
    </>
  )
}

SelectPlan.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  myPref: PropTypes.object,
  monthlyFees: PropTypes.array,
  nextMonthlyFees: PropTypes.array,
  payment: PropTypes.object,
  matchingState: PropTypes.object,
  scroll: PropTypes.func,
  refs: PropTypes.object,
  setLoading: PropTypes.func,
}
