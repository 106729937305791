import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TuneIcon from '@material-ui/icons/Tune'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import FriendCell from 'components/parts/FriendCell.jsx'
import FriendHomeLoading from 'components/parts/FriendHomeLoading'
import SwipeableBanner from 'components/parts/SwipeableBanner'
import PlanTermCheck from 'components/plan/PlanTermCheck'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
  messageContainer: {
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  messageHighlight: {
    ...theme.styles.highlightColor,
    fontWeight: 600,
  },
  emptyComment: {
    opacity: 0.4,
  },
  table: {
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.styles.footer.height,
  },
  slide: {
    height: `calc(${window.innerHeight}px - ${theme.styles.header.height}px - ${theme.styles.footer.height}px)`,
    textAlign: 'center',
  },
  filterButton: {
    color: '#616AFA',
    background: '#EFF0FF',
    fontSize: 12,
    fontWeight: 'bold',
    width: 236,
    borderRadius: 20,
    marginTop: theme.spacing(1.75),
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: theme.spacing(0.75),
    position: 'relative',
  },
  tuneIcon: {
    fontSize: 18,
    position: 'absolute',
    right: 16,
  },
  footerSpace: {
    height: theme.styles.footer.height,
  },
}))

function photoRatingForSort (val) {
  switch (val) {
    case 'good': return 3
    case 'ok': return 2
    case 'bad': return 1
    default: return 0
  }
}

export default function FriendHome (props) {
  const { friends, matchings, matchingState, subscription } = props
  const classes = useStyles()
  const [dailyFriends, setDailyFriends] = useState([])
  const [moreFriendCount, setMoreFriendCount] = useState(0)
  const [fetchFriendData, setFetchFriendData] = useState(false)
  const timeoutId = useRef(null)
  const [displayProfileItems, setDisplayProfileItems] = useState([])
  const [readIds, setReadIds] = useState(null)

  useEffect(() => {
    loadMatchings()
    getUserConfig()
    getReadIds()
    BaseComponent.loadNotifications(props)
  }, [])

  // 次回紹介更新日時に自動リロード
  useEffect(() => {
    const delay = calcReloadDelay()
    if (0 < delay && !timeoutId.current) {
      timeoutId.current = window.setTimeout(() => {
        window.clearTimeout(timeoutId.current)
        window.location.reload()
      }, delay)
    }
    return () => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }, [matchingState])

  // 自動リロードするまでの時間（ミリ秒）
  // 自動リロードできない場合は -1
  const calcReloadDelay = () => {
    if (!matchingState) { return -1 }
    if (!matchingState.next_matching_date) { return -1 }

    const nextMatchingDate = new Date(matchingState.next_matching_date)
    return nextMatchingDate.getTime() - Date.now()
  }

  const getUserConfig = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getUserConfig()
      setDisplayProfileItems(res?.display_profile_items || [])
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const getReadIds = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getReadIds('Daily')
      setReadIds(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  useEffect(() => {
    if (!subscription) { return }
    updateMoreFriendCount()
  }, [subscription])

  // fetchFriends(): 紹介画面を更新
  useEffect(() => {
    (async () => {
      props.setLoading(true)
      try {
        const entries = await BaseComponent.loadAppliedEntries(props)
        const newFriends = entries?.received.length > 0 ? await BaseComponent.loadFriends(props) : friends
        if (newFriends && matchings?.length > 0) {
          const matchingUserIds = matchings.map(m => m.target_user_id)
          const included = newFriends.some(friend => matchingUserIds.includes(friend.id))
          const friendUserIds = newFriends.map(friend => friend.id)
          const matchIncluded = matchingUserIds.every(userId => friendUserIds.includes(userId))
          fetchFriends((included && matchIncluded) ? newFriends : await BaseComponent.loadFriends(props))
        } else {
          fetchFriends(await BaseComponent.loadFriends(props))
        }
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
  }, [matchings])

  const loadMatchings = async () => {
    props.setLoading(true)
    try {
      await BaseComponent.loadMatchings(props)
      await BaseComponent.loadUser(props)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const updateMoreFriendCount = () => {
    setMoreFriendCount(BaseComponent.getRestFriendCount(props))
  }

  // 相性スコアが最も高いFriendを返す
  const findMaxScoreFriend = (friends) => {
    if (!friends) { return null }
    if (friends.length === 0) { return null }
    const sorted = friends.slice().sort((a, b) => a.matching - b.matching)
    return sorted[sorted.length - 1]
  }

  const fetchFriends = (friends) => {
    const now = new Date()
    const daily = friends.filter(friend => {
      if (friend.type === 'friend') { return false }
      if (friend.type === 'friend_pending') { return false }
      if (friend.type === 'friend_pending_canceled') { return false }
      if (friend.type === 'lover') { return false }
      if (friend.type === 'lover_applying') { return false }
      if (friend.type === 'lover_receiving') { return false }
      if (friend.type === 'closed') { return false }
      if (!friend.daily_expired_at) { return false }
      return now < new Date(friend.daily_expired_at)
    })

    // 紹介一覧並べ替え（紹介日→プロフィール写真評価→相性）
    // DEV-927 同日で複数人紹介の場合、相性スコア最高を先頭
    const maxScoreFriend = findMaxScoreFriend(daily)
    daily.sort((a, b) => {
      if (a.daily_expired_at < b.daily_expired_at) { return 1 }
      if (a.daily_expired_at > b.daily_expired_at) { return -1 }

      if (a.boost_title && !b.boost_title) { return 1 }
      if (!a.boost_title && b.boost_title) { return -1 }

      if (maxScoreFriend) {
        if (a.id === maxScoreFriend.id) { return -1 }
        if (b.id === maxScoreFriend.id) { return 1 }
      }

      const a_photoRating = photoRatingForSort(a.photo_rating)
      const b_photoRating = photoRatingForSort(b.photo_rating)
      if (a_photoRating < b_photoRating) { return 1 }
      if (a_photoRating > b_photoRating) { return -1 }

      if (a.matching < b.matching) { return 1 }
      if (a.matching > b.matching) { return -1 }
      return 0
    })

    setDailyFriends([...daily])
    setFetchFriendData(true)
  }

  return (
    <div className={classes.root}>
      <FriendHomeLoading loading={fetchFriendData} />
      <AppHeader
        showLogo
        hideBack
        hideHome
        showMypage
        showTutorialIcon
        showFeedback
        {...props}
      />
      <SwipeableBanner {...props} />
      <PlanTermCheck {...props}>
        <div className={classes.slide}>
          <div className={classes.messageContainer}>
            <Typography className={classes.messageHighlight}>
              残り{moreFriendCount}人と友達可能
            </Typography>
            <Grid
              className={classes.filterButton}
              container
              justifyContent="center"
              alignItems="center"
              onClick={() => props.setScreen('MatchingFilter')}
            >
              紹介条件の変更
              <TuneIcon className={classes.tuneIcon} />
            </Grid>
          </div>
          {dailyFriends.length > 0 ? (
            <Table className={classes.table}>
              <tbody>
                {dailyFriends.map((friend, index) => (
                  <FriendCell
                    {...props}
                    key={index}
                    index={index}
                    friend={friend}
                    friendsNum={Math.min(dailyFriends.length, 5)}
                    displayProfileItems={displayProfileItems}
                    readIds={readIds}
                  />
                ))}
                <TableRow className={classes.footerSpace} />
              </tbody>
            </Table>
          ) : (
            <Grid container alignItems="center" justifyContent="space-around">
              <Typography className={classes.emptyComment}>
                Coming Soon
              </Typography>
            </Grid>
          )}
        </div>
      </PlanTermCheck>
    </div>
  )
}

FriendHome.propTypes = {
  friends: PropTypes.array.isRequired,
  matchings: PropTypes.array,
  matchingState: PropTypes.object,
  subscription: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
