import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Config from 'commons/config'
import { formatPref } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ItemContainer from './MatchingFilterItemContainer'
import ItemLabel from './MatchingFilterItemLabel'
import valueStyle from './MatchingFilterValueStyle'

const useStyles = makeStyles(theme => ({
  // app: MatchingFilter
  selectbox: {
    '&:before': {
      content: 'none',
    },
    '&:after': {
      content: 'none',
    },
  },
  select: valueStyle(theme),
  arrowdown: {
    fontSize: 16,
    color: '#423BC7',
  },
  // entry: LoveSheet
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  inputLabel: {
    fontSize: 14,
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
  },
  // dialog
  dialogRoot: {
    zIndex: '1 !important',
  },
  content: {
    paddingTop: `${theme.spacing(9)}px !important`,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  prefsContainer: {
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  panel: {
    borderStyle: 'none',
  },
  actions: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  saveButton: {
    width: 230,
    height: 44,
    fontWeight: 600,
  },
  backButton: {
    width: 230,
    height: 44,
    fontSize: 12,
    fontWeight: 600,
    flexBasis: 1,
    marginTop: theme.spacing(1),
  },
}))

export default function MatchingFilterAddress (props) {
  const { user } = props
  const classes = useStyles()
  const [selectAddress, setSelectAddress] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [regions, setRegions] = useState({
    hokkaido: false,
    tohoku: false,
    kanto: false,
    chubu: false,
    kinki: false,
    chugoku: false,
    shikoku: false,
    kyusyu_okinawa: false,
  })
  const [prefs, setPrefs] = useState([
    { name: 'hokkaido', label: '北海道', checked: true, region: 'hokkaido' },
    { name: 'fukushima', label: '福島', checked: true, region: 'tohoku' },
    { name: 'miyagi', label: '宮城', checked: true, region: 'tohoku' },
    { name: 'yamagata', label: '山形', checked: true, region: 'tohoku' },
    { name: 'akita', label: '秋田', checked: true, region: 'tohoku' },
    { name: 'iwate', label: '岩手', checked: true, region: 'tohoku' },
    { name: 'aomori', label: '青森', checked: true, region: 'tohoku' },
    { name: 'tokyo', label: '東京', checked: true, region: 'kanto' },
    { name: 'kanagawa', label: '神奈川', checked: true, region: 'kanto' },
    { name: 'saitama', label: '埼玉', checked: true, region: 'kanto' },
    { name: 'chiba', label: '千葉', checked: true, region: 'kanto' },
    { name: 'ibaraki', label: '茨城', checked: true, region: 'kanto' },
    { name: 'gunma', label: '群馬', checked: true, region: 'kanto' },
    { name: 'tochigi', label: '栃木', checked: true, region: 'kanto' },
    { name: 'fukui', label: '福井', checked: true, region: 'chubu' },
    { name: 'ishikawa', label: '石川', checked: true, region: 'chubu' },
    { name: 'toyama', label: '富山', checked: true, region: 'chubu' },
    { name: 'nigata', label: '新潟', checked: true, region: 'chubu' },
    { name: 'nagano', label: '長野', checked: true, region: 'chubu' },
    { name: 'yamanashi', label: '山梨', checked: true, region: 'chubu' },
    { name: 'shizuoka', label: '静岡', checked: true, region: 'chubu' },
    { name: 'aichi', label: '愛知', checked: true, region: 'chubu' },
    { name: 'gifu', label: '岐阜', checked: true, region: 'chubu' },
    { name: 'mie', label: '三重', checked: true, region: 'chubu' },
    { name: 'shiga', label: '滋賀', checked: true, region: 'kinki' },
    { name: 'osaka', label: '大阪', checked: true, region: 'kinki' },
    { name: 'kyoto', label: '京都', checked: true, region: 'kinki' },
    { name: 'nara', label: '奈良', checked: true, region: 'kinki' },
    { name: 'wakayama', label: '和歌山', checked: true, region: 'kinki' },
    { name: 'hyogo', label: '兵庫', checked: true, region: 'kinki' },
    { name: 'tottori', label: '鳥取', checked: true, region: 'chugoku' },
    { name: 'shimane', label: '島根', checked: true, region: 'chugoku' },
    { name: 'okayama', label: '岡山', checked: true, region: 'chugoku' },
    { name: 'hiroshima', label: '広島', checked: true, region: 'chugoku' },
    { name: 'yamaguchi', label: '山口', checked: true, region: 'chugoku' },
    { name: 'kagawa', label: '香川', checked: true, region: 'shikoku' },
    { name: 'tokushima', label: '徳島', checked: true, region: 'shikoku' },
    { name: 'kochi', label: '高知', checked: true, region: 'shikoku' },
    { name: 'ehime', label: '愛媛', checked: true, region: 'shikoku' },
    { name: 'fukuoka', label: '福岡', checked: true, region: 'kyusyu_okinawa' },
    { name: 'saga', label: '佐賀', checked: true, region: 'kyusyu_okinawa' },
    { name: 'nagasaki', label: '長崎', checked: true, region: 'kyusyu_okinawa' },
    { name: 'kumamoto', label: '熊本', checked: true, region: 'kyusyu_okinawa' },
    { name: 'oita', label: '大分', checked: true, region: 'kyusyu_okinawa' },
    { name: 'miyazaki', label: '宮崎', checked: true, region: 'kyusyu_okinawa' },
    { name: 'kagoshima', label: '鹿児島', checked: true, region: 'kyusyu_okinawa' },
    { name: 'okinawa', label: '沖縄', checked: true, region: 'kyusyu_okinawa' },
  ])

  useEffect(() => {
    if (!user) { return }
    initSelectAddress()
  }, [user])

  useEffect(() => {
    setRegions({
      hokkaido: prefs.some(pref => (pref.region === 'hokkaido' && pref.checked)),
      tohoku: prefs.some(pref => (pref.region === 'tohoku' && pref.checked)),
      kanto: prefs.some(pref => (pref.region === 'kanto' && pref.checked)),
      chubu: prefs.some(pref => (pref.region === 'chubu' && pref.checked)),
      kinki: prefs.some(pref => (pref.region === 'kinki' && pref.checked)),
      chugoku: prefs.some(pref => (pref.region === 'chugoku' && pref.checked)),
      shikoku: prefs.some(pref => (pref.region === 'shikoku' && pref.checked)),
      kyusyu_okinawa: prefs.some(pref => (pref.region === 'kyusyu_okinawa' && pref.checked)),
    })
  }, [prefs])

  const initSelectAddress = () => {
    if (user.matching_prefs) {
      if (user.matching_prefs.length === 0) {
        selectAllAddresses()
        setSelectAddress(false)
        return
      }
      setPrefs(prevState => prevState.map(pref => ({
        name: pref.name,
        label: pref.label,
        region: pref.region,
        checked: user.matching_prefs.includes(pref.name),
      })))
      setSelectAddress(true)
    } else {
      selectAllAddresses()
      setSelectAddress(false)
    }
  }

  const onChangeAddressSelect = async (event) => {
    const value = event.target.value
    if (!value) {
      props.setLoading(true)
      try {
        const params = { matching_regions: [], matching_prefs: [] }
        await BaseComponent.updateUser(props, params)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
      initSelectAddress()
    }
    setSelectAddress(value)
    setOpenDialog(value)
  }

  const createAddressLabel = () => {
    const prefs = user.matching_prefs
    if (!prefs || prefs.length === 0) { return '設定なし' }

    if (prefs.length === 1) {
      return formatPref(prefs[0])
    } else {
      return `${formatPref(prefs[0])}, 他${prefs.length - 1}県`
    }
  }

  const selectAllAddresses = () => {
    setPrefs(prevState => prevState.map(pref => ({
      name: pref.name,
      label: pref.label,
      region: pref.region,
      checked: true
    })))
  }

  const createSelectRegions = (region, title) => (
    <Accordion className={classes.panel} style={{ margin: 0 }}>
      <AccordionSummary expandIcon={<ArrowDropDownCircleIcon className={classes.icon} />}>
        <FormControlLabel
          control={
            <Checkbox
              checked={regions[region]}
              onChange={(e) => handleChangeAddress(region, null, e)}
              onClick={event => event.stopPropagation()}
              value='region'
            />
          }
          label={title}
          onClick={event => event.stopPropagation()}
        />
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup row className={classes.prefsContainer}>
          {prefs.map(pref => {
            if (pref.region !== region) { return }
            return (
              <FormControlLabel
                key={pref.name}
                label={pref.label}
                control={
                  <Checkbox
                    checked={pref.checked}
                    onChange={(e) => handleChangeAddress(region, pref.name, e)}
                    value='pref'
                  />
                }
              />
            )
          })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )

  const handleChangeAddress = (region, name, event) => {
    const value = event.target.value
    const checked = event.target.checked
    if (value === 'region') {
      setPrefs(prevState => prevState.map(pref => (pref.region !== region ? pref : {
        name: pref.name,
        label: pref.label,
        region: pref.region,
        checked: checked
      })))
    } else if (value === 'pref') {
      setPrefs(prevState => prevState.map(pref => (pref.name !== name ? pref : {
        name: pref.name,
        label: pref.label,
        region: pref.region,
        checked: checked
      })))
    }
  }

  const onClickUpdateSelectAddress = async () => {
    const new_regions = Object.keys(regions).filter(region => regions[region])
    const prefNames = []
    prefs.map(pref => {
      if (!pref.checked) { return }
      prefNames.push(pref.name)
    })
    props.setLoading(true)
    try {
      const params = { matching_regions: new_regions, matching_prefs: prefNames }
      await BaseComponent.updateUser(props, params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
    setOpenDialog(false)
  }

  const createFullScreenDialog = () => (
    <Dialog open={openDialog} classes={{ root: classes.dialogRoot }} fullScreen>
      <DialogContent className={classes.content}>
        <Typography className={classes.subTitle}>住まいを追加・変更</Typography>
        {createSelectRegions('hokkaido', '北海道')}
        {createSelectRegions('tohoku', '東北')}
        {createSelectRegions('kanto', '関東')}
        {createSelectRegions('chubu', '中部')}
        {createSelectRegions('kinki', '近畿')}
        {createSelectRegions('chugoku', '中国')}
        {createSelectRegions('shikoku', '四国')}
        {createSelectRegions('kyusyu_okinawa', '九州・沖縄')}
      </DialogContent>
      <DialogActions className={classes.actions} disableSpacing>
        <Button
          className={classes.saveButton}
          variant="contained"
          color="secondary"
          onClick={onClickUpdateSelectAddress}
        >
          保存
        </Button>
        <Button
          className={classes.backButton}
          variant="text"
          color="secondary"
          onClick={() => {
            initSelectAddress()
            setOpenDialog(false)
          }}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  )

  // 恋愛情報入力画面ではstyleが違う
  if (Config.deployMode === 'entry') {
    return (
      <>
        <FormControl className={classes.formControl} key={'matching-filter-address'} fullWidth>
          <InputLabel className={classes.inputLabel}>
            希望する相手の住まい
          </InputLabel>
          <Select
            value={selectAddress}
            onChange={onChangeAddressSelect}
            IconComponent={() => <KeyboardArrowDown />}
          >
            <MenuItem key={0} value={false}>設定なし</MenuItem>
            <MenuItem key={1} value={true}>
              {selectAddress ? createAddressLabel() : '設定する'}
            </MenuItem>
          </Select>
          {/* app: プロフィール詳細画面では表示しないので非公開としておく */}
          <div><Typography variant="body1" className={classes.itemStatus}>非公開</Typography></div>
        </FormControl>
        {createFullScreenDialog()}
      </>
    )
  }

  return (
    <>
      <ItemContainer>
        <ItemLabel>
          相手の住まい
        </ItemLabel>
        <Select
          className={classes.selectbox}
          classes={{ select: classes.select }}
          value={selectAddress}
          onChange={onChangeAddressSelect}
          IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
          data-testid="MatchingFilter-address"
        >
          <MenuItem key={0} value={false}>設定なし</MenuItem>
          <MenuItem key={1} value={true}>
            {selectAddress ? createAddressLabel() : '設定する'}
          </MenuItem>
        </Select>
      </ItemContainer>
      {createFullScreenDialog()}
    </>
  )
}

MatchingFilterAddress.propTypes = {
  user: PropTypes.object,
  setLoading: PropTypes.func,
}
