import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Const from 'commons/constant'
import { addHours } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import NotActiveIcon from 'images/invitation-campaign/not_active_icon.png'
import UsedIcon from 'images/invitation-campaign/used_icon.png'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  subtext: {
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    marginBottom: theme.spacing(1),
  },
  note: {
    fontSize: 13,
    fontWeight: 300,
    color: '#707070',
  },
  container: {
    marginTop: sp => sp ? theme.spacing(1) : 0,
    position: 'relative',
  },
  switch: {
    width: 109,
    height: 37,
    borderRadius: 45,
    padding: 0,
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%, -50%)',
  },
  icon: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '50%',
    left: 6,
    transform: 'translate(0%, -50%)',
  },
  switchBase: {
    padding: theme.spacing(0.5),
    '&$checked': {
      transform: 'translateX(72px)',
      '& + $track': {
        backgroundColor: '#616AFA',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 29,
    height: 29,
  },
  track: {
    borderRadius: 45,
    border: '1px solid #C6C6C6',
    backgroundColor: '#C6C6C6',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  disabled: {
    display: 'none',
    '& + $track': {
      backgroundColor: '#9D9D9E !important',
      opacity: '1 !important',
      border: 'none',
    },
  },
  divider: {
    height: 1,
    background: '#0000001F',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}))

export default function CouponInfo (props) {
  const { data, subscription, index, loadMyTickets } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  if (!data || !subscription) {
    return (
      <>
        <Typography className={classes.text}>クーポンは登録されていません</Typography>
        <Typography className={classes.subtext}>
          「クーポン登録」欄にてクーポンコードを登録すると、<br />
          ここに表示されます
        </Typography>
      </>
    )
  }

  // 使用済みフラグ
  const used = data.used
  // 期限切れフラグ
  const expired = false
  // 選択中フラグ
  const selected = data.id === subscription.next_ticket_id
  // 値引きクーポン
  const discountCoupon = data.incentive_type === 'plan_discount'

  const getLabelText = () => {
    if (discountCoupon) {
      return used ? '適用済み' : expired ? '期限切れ' : selected ? '選択中' : '選択する'
    } else {
      return used ? '適用済み' : '未適用'
    }
  }

  const createLabel = () => {
    const label = getLabelText()
    const style = selected ? { left: 16 } : { right: 16 }
    return (
      <Typography className={classes.label} style={style}>{label}</Typography>
    )
  }

  const createIcon = () => {
    const icon = used ? UsedIcon : expired ? NotActiveIcon : null
    if (icon) { return <img className={classes.icon} src={icon} /> }
  }

  // 自動更新の時間帯判定
  const validateCurrentTime = () => {
    const target = [Const.planTypes.STANDARD, Const.planTypes.STANDARD_INITIAL]
    if (!target.includes(subscription.content)) { return true }
    const startTime = new Date(`${subscription.next_billing_date}T08:00:00+09:00`)
    const endTime = addHours(startTime, 1)
    const now = new Date()
    if (startTime < now && now < endTime) { return false }
    return true
  }

  const handleChangeTicket = async () => {
    if (!validateCurrentTime()) { return }
    props.setLoading(true)
    try {
      const nextId = selected ? null : data.id
      await Api.updateMySubscription({ next_ticket_id: nextId })
      await BaseComponent.loadSubscription(props)
      loadMyTickets()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  if (!data) { return <></> }

  return (
    <>
      {index !== 0 && <div className={classes.divider} />}
      <Grid container justifyContent="space-between" alignItems="center">
        <div>
          <Typography className={classes.text}>{data.title}</Typography>
          {data.campaign_code && (
            <Typography className={classes.text}>
              クーポンコード&nbsp;&nbsp;{data.campaign_code.toUpperCase()}
            </Typography>
          )}
          {data.description && (
            <Typography className={classes.subtext}>
              {data.description}
            </Typography>
          )}
          <Typography className={classes.note}>
            {data.used_at ? (
              <>{dateFormat(data.used_at, 'yyyy年m月d日HH:MM')} に適用済</>
            ) : (
              <>
                { discountCoupon ? (
                  <>
                    ＊利用するボタンタップで次回の月額ご利用料金決済時に適用<br />
                    ＊併用不可
                  </>
                ) : (
                  <>＊適用まで最大60分程度かかります。</>
                )}
              </>
            )}
          </Typography>
        </div>
        <div className={classes.container}>
          <Switch
            classes={{
              root: classes.switch,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
              disabled: classes.disabled,
            }}
            value={!selected}
            checked={selected}
            onChange={handleChangeTicket}
            disabled={!discountCoupon || used || expired}
            disableRipple
            inputProps={{ 'data-testid': `my-coupon-${index}` }}
          />
          {createLabel()}
          {createIcon()}
        </div>
      </Grid>
    </>
  )
}

CouponInfo.propTypes = {
  data: PropTypes.object,
  subscription: PropTypes.object,
  index: PropTypes.number,
  loadMyTickets: PropTypes.func,
  setLoading: PropTypes.func,
}
