import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Api from 'commons/api'
import Storage from 'commons/storage'
import FemaleTemplate from 'components/self-introduction/female'
import MaleTemplate from 'components/self-introduction/male'
import InputCategory from 'components/template-dialog/InputCategory'
import Preview from 'components/template-dialog/Preview'
import SelectCategory from 'components/template-dialog/SelectCategory'
import SelectWordType from 'components/template-dialog/SelectWordType'
import SelfIntroductionThemeProvider from 'components/template-dialog/TemplateDialogThemeProvider'

class SelfIntroductionItem {
  static restore (props) {
    const item = new SelfIntroductionItem()
    item.category = props.category || ''
    item.title = props.title || ''
    item.values = props.values || []
    return item
  }

  constructor (category = '', title = '') {
    this.category = category
    this.title = title
    this.values = []
  }

  isEmpty () {
    return !this.title
  }

  valueAt (index) {
    if (!this.values) { return '' }
    if (index < 0) { return '' }
    if (this.values.length <= index) { return '' }
    return this.values[index] || ''
  }
}

export default function SelfIntroductionTemplateDialog (props) {
  const { open, onClose, onChange, user } = props
  const [dialogPageNum, setDialogPageNum] = useState(-1)
  const [template, setTemplate] = useState(null)
  const [wordType, setWordType] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const isChubuTokai = user.area_id === 'chubu'

  useEffect(() => {
    if (!user) { return }
    if (isChubuTokai) {
      setSelectedItems([
        new SelfIntroductionItem('仕事'),
        new SelfIntroductionItem(),
        new SelfIntroductionItem(),
      ])
    } else {
      setSelectedItems([
        new SelfIntroductionItem(),
        new SelfIntroductionItem(),
        new SelfIntroductionItem(),
      ])
    }
  }, [user])

  const inputItems = selectedItems.filter(item => !item.isEmpty())

  const restoreStates = () => {
    const states = Storage.selfIntroductionTemplateDialogStates.value
    if (states) {
      setWordType(states.wordType || '')
      if (!states.selectedItems) { return }
      const restoreObj = states.selectedItems.map(obj => SelfIntroductionItem.restore(obj))
      // 中部東海かつstorageに仕事以外の値がある場合
      // category: 仕事, title: '' でrestoreする
      if (isChubuTokai && restoreObj[0]['category'] !== '仕事') {
        restoreObj[0]['category'] = '仕事'
        restoreObj[0]['title'] = ''
      }
      setSelectedItems(restoreObj)
    }
  }

  const saveStates = () => {
    Storage.selfIntroductionTemplateDialogStates.value = {
      wordType,
      selectedItems,
    }
  }

  useEffect(() => {
    if (open) {
      const temp = user.sex === 'male' ? MaleTemplate : FemaleTemplate
      setTemplate(temp)
      // 性別を切り替えることで存在しない項目を参照してしまうのでlocalStorageの値をclear
      const states = Storage.selfIntroductionTemplateDialogStates.value
      if (!states?.selectedItems) { return }
      const result = states.selectedItems.every(obj => (!!temp.sentence[obj.title] || !obj.title))
      if (result) {
        restoreStates()
      } else {
        Storage.selfIntroductionTemplateDialogStates.clear()
      }
    }
    if (!open && wordType) { saveStates() }
  }, [open])

  const next = () => {
    if (dialogPageNum <= inputItems.length) {
      setDialogPageNum(dialogPageNum + 1)
    }
  }

  const prev = () => {
    setDialogPageNum(dialogPageNum - 1)
  }

  const close = () => {
    onClose()
    setDialogPageNum(-1)
  }

  const onChangeTitle = (index, category, title) => {
    const newItem = new SelfIntroductionItem(category, title)
    const copyItems = selectedItems.concat()
    copyItems.splice(index, 1, newItem)
    setSelectedItems(copyItems)
  }

  const onChangeValues = (index, values) => {
    selectedItems[index].values = values
    setSelectedItems(selectedItems.concat())
  }

  const onTextGenerated = text => {
    saveUserProfiles()
    onChange && onChange(text)
    close()
  }

  const saveUserProfiles = () => {
    inputItems.forEach(item => {
      item.values.filter(v => !!v).forEach(value => {
        Api.createUserProfile(item.category, item.title, value)
      })
    })
  }

  return (
    <SelfIntroductionThemeProvider>
      <Dialog open={(open && template)} disableEnforceFocus>
        {dialogPageNum === -1 &&
          <SelectWordType
            wordType={wordType}
            onChange={newValue => setWordType(newValue)}
            onNext={next}
            onClose={close}
          />
        }
        {dialogPageNum === 0 &&
          <SelectCategory
            type="self-introduction"
            selectedItems={selectedItems}
            onChangeTitle={onChangeTitle}
            onNext={next}
            onPrev={prev}
            onClose={close}
            template={template}
            isChubuTokai={isChubuTokai}
          />
        }
        {0 < dialogPageNum && dialogPageNum <= inputItems.length &&
          <InputCategory
            type="self-introduction"
            index={dialogPageNum - 1}
            selectedItems={inputItems}
            wordType={wordType}
            onChange={onChangeValues}
            onNext={next}
            onPrev={prev}
            onClose={close}
            template={template}
          />
        }
        {inputItems.length < dialogPageNum &&
          <Preview
            type="self-introduction"
            wordType={wordType}
            selectedItems={inputItems}
            onNext={onTextGenerated}
            onPrev={prev}
            onClose={close}
            template={template}
          />
        }
      </Dialog>
    </SelfIntroductionThemeProvider>
  )
}
SelfIntroductionTemplateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  user: PropTypes.object,
}
