import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import Currency from 'commons/currency'
import { addDate, calcDateDiff, canUseCafeteriaPoint, copyToClipboard } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import CopyIcon from 'images/invitation-campaign/copy_icon.png'

const useStyles = makeStyles(theme => ({
  cafeteriaText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#F55083'
  },
  entryRoot: {
    fontSize: 16,
    fontWeight: 600,
    color: '#707070',
    width: '95%',
    maxWidth: 664,
    border: 'solid 2px #423BC7',
    borderRadius: 5,
    position: 'relative',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    '&:before': {
      content: `"${dateFormat(new Date(), 'yyyy年m月キャンペーン')}"`,
      fontSize: 18,
      fontWeight: 600,
      color: '#423BC7',
      textAlign: 'center',
      width: 250,
      background: '#ffffff',
      display: 'inline-block',
      position: 'absolute',
      top: -15,
      left: '50%',
      transform: 'translateX(-50%)',
    }
  },
  root: {
    width: '100%',
    minHeight: 152,
    border: '1px solid #707070',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
  },
  stripeBorder: {
    width: 215,
    height: 45,
    fontSize: 18,
    fontWeight: 600,
    color: '#1A1667',
    letterSpacing: 1,
    position: 'relative',
    padding: 5,
    zIndex: 0,
    '&:before': {
      position: 'absolute',
      top: 4,
      bottom: 4,
      left: 5,
      right: 5,
      background: '#ffffff',
      content: '""',
      zIndex: -1,
      border: 'solid 1px #B0B4FC',
    },
    '&:after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'repeating-linear-gradient(-60deg, #B0B4FC, #B0B4FC 3px, white 3px, white 6px)',
      border: 'solid 1px #B0B4FC',
      content: '""',
      zIndex: -2,
    },
  },
  campaignList: {
    fontWeight: 600,
    color: '#1A1667',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  campaignTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
  },
  campaignText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    whiteSpace: 'pre-wrap',
    '& strong': {
      color: '#1A1667',
    },
  },
  notice: {
    fontSize: 13,
    fontWeight: 300,
  },
  note: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    listStyle: '"＊"'
  },
  myCode: {
    width: '100%',
    maxWidth: 481,
    height: 82,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.12,
    color: '#1A1667',
    background: '#ffffff',
    border: '1px solid #1A1667',
    borderRadius: 10,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

// デフォルト無料日数
const defaultFreeDays = 7
const sellingPrice = 6600

export default function MembersCampaignBox (props) {
  const { organization, freeTerm, campMaster, subscription, scroll } = props
  const classes = useStyles()
  const [monthlyPrice, setMonthlyPrice] = useState(null)
  const [incentivePrice, setIncentivePrice] = useState(null)
  const campaignUrl = (campMaster?.campaign_code || '').toUpperCase()
  const incentiveLimit = campMaster?.display_org_incentive_limit

  useEffect(() => {
    (async () => {
      setMonthlyPrice(await BaseComponent.getMonthlyPrice(props))
      if (!campMaster) { return }
      setIncentivePrice(Currency.withTax(await BaseComponent.getIncentivePrice(props, campMaster)))
    })()
  }, [campMaster])

  // 入会後の無料トライアル期間
  const formatFreeTerm = () => {
    if (freeTerm?.free_days) {
      return (
        <>
          通常{defaultFreeDays}日間のところ、
          <strong>{freeTerm.free_days}日間無料</strong>
        </>
      )
    }
    if (freeTerm?.free_end_at) {
      const endDate = addDate(new Date(freeTerm.free_end_at), -1)
      const tillDays = calcDateDiff(endDate, new Date())
      return (
        <>
          通常{defaultFreeDays}日間のところ、
          <strong>{tillDays}日間無料</strong>
        </>
      )
    }
    return <>{defaultFreeDays}日間無料</>
  }

  const shareInvitationLink = async () => {
    try {
      await Api.addActionLog('click', 'share_button')
      try {
        await copyToClipboard(campaignUrl)
        BaseComponent.showRequestSuccessMessage(props, 'コピーしました')
      } catch (error) {
        BaseComponent.showErrorMessage(props, error)
      }
    } catch (error) {
      // noop
    }
  }

  if (!organization) { return null }

  // 特定企業対象のキャンペーンが有効な場合
  if (campMaster?.target_organization_id && monthlyPrice && incentivePrice) {
    // 値引き後価格
    const discountedPrice = Math.max((monthlyPrice - incentivePrice), 0)
    return (
      <>
        <Typography className={classes.campaignTitle}>
          {organization.canonical_name}様&nbsp;専用<br />
          {incentiveLimit > 0 && `先着${incentiveLimit}名様`}
          {discountedPrice === 0 ? '無料' : `${Currency.format(incentivePrice)}円OFF`}クーポン
        </Typography>
        <Box className={classes.root}>
          <Grid className={classes.stripeBorder} container justifyContent="center" alignItems="center">
            {Currency.format(incentivePrice)}円OFFクーポン
          </Grid>
          <Typography className={classes.campaignText} style={{ fontSize: 18, marginTop: 8 }}>
            {incentiveLimit > 0 && `先着${incentiveLimit}名様限定！`}スタンダードプランを
            {(discountedPrice === 0) ? '1ヶ月無料' : `${Currency.format(incentivePrice)}円OFF`}
            *でご利用いただけます！
          </Typography>
          <Typography className={classes.notice} component="div">
            <ul className={classes.note}>
              <li>1クーポン{Currency.format(incentivePrice)}円分としてお使いいただけます。</li>
              <li>
                通常月額{Currency.format(monthlyPrice)}円 - クーポン割引{Currency.format(incentivePrice)}円 = {discountedPrice}円
                {(discountedPrice === 0) && <>（無料）</>}
              </li>
              <li>価格は全て税込です。</li>
            </ul>
          </Typography>
          <Typography className={classes.campaignText} style={{ fontSize: 14, marginTop: 24 }}>
            使い方
          </Typography>
          <Typography className={classes.notice} component="div">
            <ul className={classes.note}>
              <li>
                クーポン利用には本クーポンコードをコピーし「
                <u onClick={(() => scroll('couponRef'))}>クーポン登録</u>
                」、「
                <u onClick={(() => scroll('couponRef'))}>登録済みクーポンの利用選択</u>
                」を行ってください。
              </li>
              <li>登録済みクーポンにつきましては「<u onClick={(() => scroll('couponRef'))}>登録済みクーポンの利用選択</u>」欄をご確認ください。</li>
            </ul>
          </Typography>
          {incentiveLimit > 0 && (
            <>
              <Typography className={classes.campaignText} style={{ fontSize: 14, marginTop: 24 }}>
                2枚以上ご利用の場合
              </Typography>
              <Typography className={classes.notice} component="div">
                <ul className={classes.note}>
                  <li>残数がある限り何回でもクーポンをご利用いただけます。</li>
                  <li>登録済みクーポンが適用済みになった後に、2枚目以降のクーポンをご登録可能です。</li>
                </ul>
              </Typography>
            </>
          )}
          <Typography className={classes.campaignText} style={{ fontSize: 14, marginTop: 24 }}>
            注意事項
          </Typography>
          <Typography className={classes.notice} component="div">
            <ul className={classes.note}>
              {incentiveLimit > 0 && <li>先着{incentiveLimit}名様限定クーポンです。</li>}
              {incentiveLimit > 0 && <li>クーポン登録人数が{incentiveLimit}名に達した場合、クーポン登録はできません。</li>}
              <li>決済時に本クーポンが「選択中」になっていなかった場合、クーポンは適用されません。</li>
              <li>期限切れクーポンはご利用いただけません。</li>
              <li>チケット適用期間終了後は選択されているご利用プランが自動更新となります。</li>
              <li>本クーポンは予告なく終了する場合がございます。</li>
            </ul>
          </Typography>
          <Typography className={classes.campaignText} style={{ marginTop: 24 }}>
            クーポンコード
          </Typography>
          <Grid className={classes.myCode} container wrap="nowrap" justifyContent="space-between" alignItems="center">
            {campaignUrl}
            <img src={CopyIcon} width={50} height={50} onClick={shareInvitationLink} />
          </Grid>
        </Box>
      </>
    )
  }

  // メンバーズサイト（無料トライアル期間中）
  if (subscription?.content !== Const.planTypes.STANDARD_INITIAL) { return null }
  return (
    <>
      {canUseCafeteriaPoint(organization) && <Typography className={classes.cafeteriaText}>カフェテリアポイント利用可能</Typography>}
      <Box className={classes.root} sx={{ px: { xs: 4, sm: 8 } }}>
        <ol className={classes.campaignList}>
          <li>
            <Typography className={classes.campaignText}>
              {formatFreeTerm()}で「スタンダードプラン」をご体験いただけます！
            </Typography>
          </li>
          <li>
            <Typography className={classes.campaignText}>
              スタンダードプランのご利用料金が<strong>月額{Currency.format(monthlyPrice)}円</strong><br />
              <span style={{ fontSize: 14, fontWeight: 300 }}>
                （販売価格{Currency.format(sellingPrice)}円、{organization?.burden_label}{Currency.format(sellingPrice - monthlyPrice)}円）
              </span>
            </Typography>
          </li>
        </ol>
        <Typography className={classes.notice} component="div">
          <ul className={classes.note}>
            <li>無料トライアル期間終了日の翌日午前{Const.subscriptionUpdateHour - 1}時までにフリープランに変更いただいた場合はご利用料金が発生しません。</li>
            <li>ご利用料金は無料トライアル期間終了日の翌日午前{Const.subscriptionUpdateHour}時のお支払いとなります。無料トライアル期間終了後は選択されているご利用プランの自動更新となります。</li>
            <li>ご利用プランは入会後にメンバーズサイトより変更いただけます。</li>
            <li>価格は全て税込み価格です。</li>
            <li>お支払い方法は各社クレジットカードになります。</li>
          </ul>
        </Typography>
      </Box>
    </>
  )
}

MembersCampaignBox.propTypes = {
  organization: PropTypes.object,
  freeTerm: PropTypes.object,
  campMaster: PropTypes.object,
  subscription: PropTypes.object,
  scroll: PropTypes.func,
}
