import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { calculateMonthDiff } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import CongratulationsImage from 'images/img_congratulations.png'
import BackgroundImage from 'images/img_speech_balloon.png'

const FontColor = '#414141'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    color: FontColor,
  },
  content: {
    maxWidth: 400,
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  body: {
    textAlign: 'center',
  },
  messageCenter: {
    textAlign: 'center',
    fontSize: 12,
    color: FontColor,
    fontWeight: 'bold',
  },
  question: {
    textAlign: 'left',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 12,
    color: FontColor,
    fontWeight: 'bold',
  },
  count: {
    fontSize: 10,
    fontWeight: 'bold',
    ...theme.styles.highlightColor,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  slider: {
    width: 208,
  },
  markActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  marked: {
    marginBottom: 0,
  },
  sliderRoot: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  valueLabel: {
    top: -12,
    left: -2,
    backgroundColor: 'transparent',
    '& span': {
      width: 20,
      height: 20,
      transform: 'none',
      borderRadius: 4,
      backgroundColor: 'transparent',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'contain',
      '& span': {
        fontSize: 10,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        transform: 'none',
        lineHeight: '16px',
      }
    },
  },
  track: {
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
    height: 5,
    opacity: 0,
  },
  rail: {
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
    height: 5,
    opacity: 1,
  },
  thumb: {
    width: 16,
    height: 16,
    border: '1px solid #616AFA',
    backgroundColor: theme.palette.primary.main,
  },
  messageLeft: {
    textAlign: 'left',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 11,
    color: '#707070',
  },
  thanksTitle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.4px'
  },
}))

export default function HappinessSurveyDialog (props) {
  const { open, user, onClose, timingType } = props
  const classes = useStyles()
  const [surveyCount, setSurveyCount] = useState(1)
  const [memberPeriod, setMemberPeriod] = useState(0)
  const [answer1, setAnswer1] = useState(5)
  const [answer2, setAnswer2] = useState(5)
  const [answer3, setAnswer3] = useState(5)
  const [answer4, setAnswer4] = useState(5)
  const [sended, setSended] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        // 初回の場合処理無し
        if (!user.next_happiness_date) { return }
        // 2回目以降の場合最新のSurvey取得
        const res = await Api.getLatestSurvey('happiness')
        setSurveyCount(res.survey_count + 1)
        setMemberPeriod(getMemberPeriod())
        setAnswer1(res.answer_1)
        setAnswer2(res.answer_2)
        setAnswer3(res.answer_3)
        setAnswer4(res.answer_4)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [])

  const getMemberPeriod = () => {
    if (!user.first_login_date) { return 0 }
    const today = new Date()
    const firstLoginDay = new Date(user.first_login_date)
    return calculateMonthDiff(firstLoginDay, today)
  }

  const createThanksDialogContent = () => (
    <>
      <DialogTitle className={classes.thanksTitle} disableTypography>
        アンケートへのご協力<br />
        ありがとうございました！
        {timingType === 'graduate' && (
          <>
            <br />
            <br />
            Aill goenでお付き合い後は<br />
            Aill Clubモードで<br />
            お二人を応援し続けます。
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <img src={CongratulationsImage} width={'100%'} />
      </DialogContent>
      <DialogActions disableSpacing>
        <Button variant="contained" color="secondary" onClick={async () => {
          onClose()
          // User#next_happiness_dateが更新されるので再取得
          await BaseComponent.forceLoadUser(props)
        }}>
          閉じる
        </Button>
      </DialogActions>
    </>
  )

  const sendSurvey = async () => {
    props.setLoading(true)
    const params = {
      user_id: user.id,
      survey_type: 'happiness',
      timing_type: timingType,
      survey_count: surveyCount,
      member_period: memberPeriod,
      answer_1: Number(answer1),
      answer_2: Number(answer2),
      answer_3: Number(answer3),
      answer_4: Number(answer4),
    }
    try {
      await Api.createSurvey(params)
      if (Config.deployMode === 'app') {
        setSended(true)
      } else {
        // User#next_happiness_dateが更新されるので再取得
        await BaseComponent.forceLoadUser(props)
        onClose()
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const createQuestion = (label) => (
    <Typography className={classes.question}>
      {label}
    </Typography>
  )

  const createSlider = (targetState, changeStateFunc) => (
    <Grid container alignItems="center" justifyContent="space-around">
      <Typography className={classes.count}>
        0
      </Typography>
      <Slider
        className={classes.slider}
        classes={{
          markActive: classes.markActive,
          marked: classes.marked,
          root: classes.sliderRoot,
          valueLabel: classes.valueLabel,
          track: classes.track,
          rail: classes.rail,
          thumb: classes.thumb,
        }}
        color="secondary"
        value={Number(targetState)}
        getAriaValueText={(value) => value}
        onChange={(event, value) => changeStateFunc(value)}
        valueLabelDisplay="on"
        aria-labelledby="discrete-slider-always"
        step={1}
        max={10}
      />
      <Typography className={classes.count}>
        10
      </Typography>
    </Grid>
  )

  const createSurveyDialogContent = () => (
    <>
      <DialogTitle className={classes.title} disableTypography>
        アンケートご協力のお願い
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.body}>
          <Typography className={classes.messageCenter}>
            直感で下記の質問に回答ください。<br />
            （計４問）
          </Typography>
          {createQuestion('現在のあなたの幸福度はどれくらいですか？')}
          {createSlider(answer1, setAnswer1)}
          {createQuestion('現在、あなたは私生活の中でワクワクする体験は多いですか？')}
          {createSlider(answer2, setAnswer2)}
          {createQuestion('現在、あなたは仕事に対して意欲はどれくらいですか？')}
          {createSlider(answer3, setAnswer3)}
          {createQuestion('あなたの会社で働くことを親しい友人や知人に、どの程度おすすめしたいと思いますか？')}
          {createSlider(answer4, setAnswer4)}
          <Typography className={classes.messageLeft}>
            ＊本アンケートは、匿名化した上で効果分析のためのデータとして活用させていただきます。<br />
            分析結果は、本格サービスに向けて公表する場合がございます。
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={sendSurvey}>
          送信
        </Button>
      </DialogActions>
    </>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} style={{ zIndex: 9998 }} data-testid="happiness-survey-dialog">
        {sended ? createThanksDialogContent() : createSurveyDialogContent()}
      </Dialog>
    </DialogThemeProvider>
  )
}

HappinessSurveyDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
  timingType: PropTypes.string,
  setLoading: PropTypes.func,
}
