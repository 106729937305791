import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AttentionBox from 'components/aill-club/AttentionBox'
import Checkbox from 'images/aill-club/checkbox.png'
import Free from 'images/aill-club/free_sp.png'
import PhoneSP from 'images/aill-club/phone_talk_sp.png'
import PhonePC from 'images/aill-club/phone_talk_sp.png'
import WhatText from 'images/aill-club/what_is_aill_club_sp.png'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: sp => sp ? theme.spacing(3) : 0,
    background: sp => sp ? '#E1EBF4' : '#E9EEF4',
  },
  whatTitle: {
    marginBottom: theme.spacing(3.75),
  },
  whatBox: {
    width: 314,
    margin: 'auto',
    borderRadius: 20,
    marginBottom: theme.spacing(3),
    boxShadow: '0px 3px 6px #00000029',
    '& div:nth-of-type(1)': {
      width: '100%',
      height: 287,
      background: '#F5F5F5',
      borderRadius: '20px 20px 0 0',
      paddingTop: theme.spacing(2.5),
      backgroundImage: `url(${PhoneSP})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '& p': {
        fontSize: 20,
        fontWeight: 600,
        color: '#423BC7',
        lineHeight: '26px',
        letterSpacing: '2.0px',
        textAlign: 'center',
      },
    },
    '& div:nth-of-type(2)': {
      width: '100%',
      height: 303,
      background: '#ffffff',
      borderRadius: '0 0 20px 20px',
      paddingTop: theme.spacing(4.5),
    },
  },
  check: {
    maxWidth: 200,
    fontSize: 14,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    margin: 'auto',
    marginBottom: theme.spacing(1),
    display: 'flex',
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& span': {
      width: 15.83,
      height: 15,
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  },
  img: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  container: {
    width: 795,
    height: 430,
    margin: 'auto',
    position: 'relative',
  },
  box: {
    width: 314,
    height: 365,
    background: '#ffffff',
    borderRadius: 20,
    paddingTop: theme.spacing(3.75),
    paddingRight: theme.spacing(5.5),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5.5),
    position: 'absolute',
    top: 40,
    right: 0,
  },
  text: {
    fontSize: 20,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '26px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
}))

export default function WhatContainer () {
  const spMatches = !useMediaQuery('(min-width:1024px)')
  const classes = useStyles(spMatches)

  if (spMatches) {
    return (
      <div className={classes.root}>
        <img className={`${classes.img} ${classes.whatTitle}`} src={WhatText} width={184.06} />
        <div className={classes.whatBox}>
          <div>
            <Typography>
              アプリはそのまま、<br />
              中身がシンプルに変身！
            </Typography>
          </div>
          <div>
            <Typography className={classes.check}>
              <img src={Checkbox} width={15.83} height={15} />
              マッチング機能なし
            </Typography>
            <Typography className={classes.check}>
              <img src={Checkbox} width={15.83} height={15} />
              二人だけのトーク画面
            </Typography>
            <Typography className={classes.check}>
              <img src={Checkbox} width={15.83} height={15} />
              お得なお知らせの配信
            </Typography>
            <Typography className={classes.check} style={{ marginTop: 20 }}>
              <img src={Checkbox} width={15.83} height={15} />
              Aill Clubのご利用料金は<br />
            </Typography>
            <Typography className={classes.check} style={{ marginBottom: 10 }}>
              <span />
              お付き合い中ずっと
            </Typography>
            <img className={classes.img} src={Free} width={166.14} height={110} />
          </div>
        </div>
        <AttentionBox />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img className={classes.img} src={WhatText} width={184} style={{ position: 'absolute',top: 64, left: 130 }} />
        <img className={classes.img} src={PhonePC} width={442} style={{ position: 'absolute', bottom: 0 }} />
        <div className={classes.box}>
          <Typography className={classes.text}>
            アプリはそのまま、<br />
            中身がシンプルに変身！
          </Typography>
          <Typography className={classes.check}>
            <img src={Checkbox} width={15.83} height={15} />
            マッチング機能なし
          </Typography>
          <Typography className={classes.check}>
            <img src={Checkbox} width={15.83} height={15} />
            二人だけのトーク画面
          </Typography>
          <Typography className={classes.check}>
            <img src={Checkbox} width={15.83} height={15} />
            お得なお知らせの配信
          </Typography>
          <Typography className={classes.check} style={{ marginTop: 20 }}>
            <img src={Checkbox} width={15.83} height={15} />
            Aill Clubのご利用料金は<br />
          </Typography>
          <Typography className={classes.check} style={{ marginBottom: 10 }}>
            <span />
            お付き合い中ずっと
          </Typography>
          <img className={classes.img} src={Free} width={166.14} height={110} />
        </div>
      </div>
      <AttentionBox />
    </div>
  )
}

WhatContainer.propTypes = {}
