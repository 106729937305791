import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addSeconds } from 'commons/utility'
import Good from 'images/meeting-boost/good.png'
import Komainu from 'images/meeting-boost/koma.png'
import PartyPopperLeft from 'images/meeting-boost/party_popper_left.png'
import PartyPopper2Left from 'images/meeting-boost/party_popper_left_2.png'
import PartyPopperRight from 'images/meeting-boost/party_popper_right.png'
import PartyPopper2Right from 'images/meeting-boost/party_popper_right_2.png'
import People from 'images/meeting-boost/people.png'
import PhoneFemale01 from 'images/meeting-boost/phone_female_01.png'
import PhoneFemale02 from 'images/meeting-boost/phone_female_02.png'
import PhoneMale01 from 'images/meeting-boost/phone_male_01.png'
import PhoneMale02 from 'images/meeting-boost/phone_male_02.png'
import RibbonPink from 'images/meeting-boost/ribbon.png'
import Top from 'images/meeting-boost/top.png'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 0,
    paddingTop: theme.spacing(3),
    background: '#0CBCFF',
  },
  content: {
    width: 328,
    textAlign: 'center',
    padding: '0 !important',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  top: {
    height: 472,
    background: '#0CBCFF',
    backgroundImage: `url(${Top})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '& p': {
      fontSize: 13,
      fontWeight: 600,
      color: '#0CBCFF',
      lineHeight: 1,
      letterSpacing: '1.3px',
      position: 'absolute',
      top: 225,
      left: '50%',
      transform: 'translate(-50%, -0%)',
    },
  },
  bottom: {
  },
  box: {
    width: 268,
    minHeight: 218,
    border: '2px solid #0CBCFF',
    borderRadius: 8,
    margin: 'auto',
    paddingTop: theme.spacing(3),
    position: 'relative',
    '& p': {
      fontSize: 14,
      fontWeight: 600,
      color: '#423BC7',
      lineHeight: '21.7px',
      letterSpacing: '1.4px',
      marginTop: theme.spacing(1.25),
      '& span': {
        color: '#F55083',
      },
    },
    '& img:nth-of-type(1)': {
      position: 'absolute',
      top: 2,
      left: 2,
    },
    '& img:nth-of-type(2)': {
      position: 'absolute',
      top: 2,
      right: 2,
    },
    '& img:nth-of-type(4)': {
      position: 'absolute',
      top: 48,
      left: 167,
    },
  },
  arrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderRight: '22px solid transparent',
    borderLeft:' 22px solid transparent',
    borderTop: '22.61px solid #F55083',
    borderBottom: 0,
    margin: 'auto',
  },
  text: {
    fontSize: 18,
    fontWeight: 600,
    color: '#0CBCFF',
    lineHeight: 1,
    letterSpacing: '1.8px',
    marginTop: theme.spacing(3.75),
    marginBottom: theme.spacing(1.5),
    position: 'relative',
    '& img:nth-of-type(1)': {
      position: 'absolute',
      top: 12,
      left: -32,
      zIndex: 1,
    },
    '& img:nth-of-type(2)': {
      position: 'absolute',
      top: 12,
      right: -32,
      zIndex: 1,
    },
  },
  term: {
    width: 268,
    height: 40,
    background: '#ffffff',
    border: '2px solid #0CBCFF',
    borderRadius: 8,
    margin: 'auto',
    marginBottom: theme.spacing(5),
    position: 'relative',
    zIndex: 2,
    '& p': {
      fontSize: 13,
      fontWeight: 600,
      color: '#0CBCFF',
      lineHeight: 1,
      letterSpacing: '1.3px',
    },
  },
  serif: {
    marginBottom: theme.spacing(1.25),
    '& div': {
      width: 16,
      border: '1px solid #616AFA',
    },
    '& p': {
      fontSize: 12,
      fontWeight: 600,
      color: '#423BC7',
      lineHeight: 1,
      margin: theme.spacing(0.5),
    },
  },
  button: {
    width: 268,
    color: '#ffffff',
    marginTop: theme.spacing(-2.5),
    marginBottom: theme.spacing(4),
  },
}))

export default function MeetingBoostDialog (props) {
  const { open, onClose, user, mission, fromBanner } = props
  const classes = useStyles()

  if (!user) { return null }
  if (!mission) { return null }
  if (!mission?.end_at) { return null }

  const phoneImg01 = user.sex === 'male' ? PhoneMale01 : PhoneFemale01
  const phoneImg02 = user.sex === 'male' ? PhoneMale02 : PhoneFemale02
  const endAt = mission.end_at

  return (
    <DialogThemeProvider color="meeting-boost">
      <Dialog open={open}>
        <DialogContent className={classes.content}>
          <div className={classes.top}>
            <Typography>24時間限定!!</Typography>
          </div>
          <div className={classes.bottom}>
            <img src={RibbonPink} width={249.95} style={{ marginTop: 24.5, marginBottom: 8 }} />
            <div className={classes.box}>
              <img src={PartyPopperLeft} width={50.43} height={64.54} />
              <img src={PartyPopperRight} width={50.43} height={64.54} />
              <img src={phoneImg01} width={123.43} height={128} />
              <img src={Good} width={55} height={55} />
              <Typography>
                紹介画面の５人に<br />
                「いいね！」を送ろう
              </Typography>
            </div>
            <div className={classes.arrow} style={{ marginTop: 17, marginBottom: 18 }} />
            <div className={classes.box} style={{ height: 239 }}>
              <img src={PartyPopperLeft} width={50.43} height={64.54} />
              <img src={PartyPopperRight} width={50.43} height={64.54} />
              <img src={phoneImg02} width={123.43} height={128} />
              <img src={People} width={55} height={55} />
              <Typography>
                「いいね！」送信数に応じて<br />
                <span>{dateFormat(addSeconds(endAt, 1), 'yyyy/mm/dd HH:MM')}に</span><br />
                紹介者が追加されるよ
              </Typography>
            </div>
            <Typography className={classes.text}>
              イベント期間
              <img src={PartyPopper2Left} width={55} />
              <img src={PartyPopper2Right} width={55} />
            </Typography>
            <Grid className={classes.term} container justifyContent="center" alignItems="center">
              <Typography>{dateFormat(endAt, 'yyyy/mm/dd HH:MM')}まで</Typography>
            </Grid>
            <Grid className={classes.serif} container justifyContent="center" alignItems="center">
              <div style={{ transform: 'rotate(60deg)' }} />
              <Typography>少しでも気になったら<br />いいね！を送ろう</Typography>
              <div style={{ transform: 'rotate(120deg)' }} />
            </Grid>
            <img src={Komainu} width={160.57} />
            <Button className={classes.button} variant="contained" onClick={onClose}>
              {fromBanner ? '閉じる' : 'イベントに参加する'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

MeetingBoostDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  mission: PropTypes.object,
  fromBanner: PropTypes.bool,
}