import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#F55083',
    border: '3px solid #F55083',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: 'none',
    }
  },
  komanavi: {
    height: 35,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
}))

export default function FirstBoostNavi (props) {
  const { children, boostTitle } = props
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
        <Typography className={classes.text}>
          {boostTitle ? `${boostTitle}特典紹介者` : '出会いを増やそうイベント対象者'}
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

FirstBoostNavi.propTypes = {
  children: PropTypes.object,
  boostTitle: PropTypes.string,
}
