import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import valueStyle from './MatchingFilterValueStyle'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
  },
  value: valueStyle(theme),
  icon: {
    fontSize: 16,
    color: '#423BC7',
  }
}))

export default function MatchingFilterTextValue (props) {
  const { value, onClick, ...others } = props
  const classes = useStyles()
  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      onClick={onClick}
      className={classes.root}
      {...others}
    >
      <Typography variant="body1" className={classes.value}>
        {value}
      </Typography>
      <KeyboardArrowDown className={classes.icon} />
    </Grid>
  )
}

MatchingFilterTextValue.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}
