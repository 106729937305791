import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import Const from 'commons/constant'
import Storage from 'commons/storage'
import * as utility from 'commons/utility'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import InterestedThingsProfile from 'components/interested-things/InterestedThingsProfile'
import MatchingFilterAddress from 'components/matching-filter/MatchingFilterAddress'
import MatchingFilterAge from 'components/matching-filter/MatchingFilterAge'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import SelfIntroductionTemplateDialog from 'components/self-introduction/SelfIntroductionTemplateDialog'

const styles = theme => ({
  headerSpacer: {
    paddingTop: theme.styles.header.height,
  },
  rootcontainer: {
    maxWidth: 700,
    margin: 'auto',
  },
  rootcontainerEdit: {
    maxWidth: 700,
    margin: 'auto',
    ...theme.styles.secretRoomColor,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerEdit: {
    display: 'flex',
    flexWrap: 'wrap',
    ...theme.styles.secretRoomColor,
  },
  subTitle: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    ...theme.styles.messageColor,
  },
  subTitleEdit: {
    marginTop: `calc(${theme.styles.header.height}px + ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    ...theme.styles.messageColor,
  },
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  fullTextField: {
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(2),
  },
  back: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
  trigger: {
    color: '#00f',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
  },
  helperText: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100%',
    ...theme.styles.messageColor,
    fontSize: 12,
  },
  publicContainer: {
    position: 'relative',
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
  },
  root: {
    width: theme.spacing(5),
    height: theme.spacing(2.5),
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translateX(${theme.spacing(2.5)}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.secondary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
  },
  track: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  switchLabel: theme.styles.profileItemStatus,
  switchRoot: {
    margin: 0,
    position: 'absolute',
    right: 0,
  },
  textField: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
  },
  buttonContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2.75),
    marginRight: theme.spacing(2.75),
  },
  inputLabel: {
    fontSize: 14,
  },
  inputError: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: '#FF0000',
  },
})

export class LoveSheet extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.submitEntry = this.submitEntry.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openTermsDialog = this.openTermsDialog.bind(this)
    this.closeTermsDialog = this.closeTermsDialog.bind(this)
    this.closeSmokingConfirmDialog = this.closeSmokingConfirmDialog.bind(this)

    this._isMounted = false

    const storageProfile = Storage.editingUserProfile.value

    this.state = {
      // 必須入力項目
      future_work_way_of_partner: this.props.user.future_work_way_of_partner || storageProfile?.future_work_way_of_partner || '',
      future_work_way: this.props.user.future_work_way || storageProfile?.future_work_way || '',
      holiday: this.props.user.holiday || storageProfile?.holiday || '',
      how_to_spend_the_holidays: this.props.user.how_to_spend_the_holidays || '',
      marital_status: this.props.user.marital_status || storageProfile?.marital_status || '',
      marital_status_of_partner: this.props.user.marital_status_of_partner || storageProfile?.marital_status_of_partner || '',
      children: this.props.user.children || storageProfile?.children || '',
      smoking_status: this.props.user.smoking_status || storageProfile?.smoking_status || '',
      heterosexual_smoking: this.props.user.heterosexual_smoking || storageProfile?.heterosexual_smoking || '',
      annual_income: this.props.user.annual_income || storageProfile?.annual_income || '',
      height: this.props.user.height || storageProfile?.height || '',
      to_message: this.props.user.to_message || '',
      navi1: this.props.user.navi1 || storageProfile?.navi1 || '',
      navi2: this.props.user.navi2 || storageProfile?.navi2 || '',
      navi3: this.props.user.navi3 || storageProfile?.navi3 || '',
      current_children: this.props.user.current_children || storageProfile?.current_children || '',
      job_worthwhile: this.props.user.job_worthwhile || storageProfile?.job_worthwhile || '',
      sibling: this.props.user.sibling || storageProfile?.sibling || '',
      blood_type: this.props.user.blood_type || storageProfile?.blood_type || '',
      liquor: this.props.user.liquor || storageProfile?.liquor || '',
      date_timing: this.props.user.date_timing || storageProfile?.date_timing || '',
      birthplace: this.props.user.birthplace || storageProfile?.birthplace || '',
      u_turn_intention: this.props.user.u_turn_intention || storageProfile?.u_turn_intention || '',
      ideal_housework_and_childcare: this.props.user.ideal_housework_and_childcare || storageProfile?.ideal_housework_and_childcare || '',
      acceptable_housework_and_childcare: this.props.user.acceptable_housework_and_childcare || storageProfile?.acceptable_housework_and_childcare || '',
      interested_things: this.props.user.interested_things || storageProfile?.interested_things || [],

      // エラー判定用
      future_work_way_of_partner_error: false,
      future_work_way_error: false,
      holiday_error: false,
      how_to_spend_the_holidays_error: false,
      marital_status_error: false,
      marital_status_over3_error: false,
      marital_status_of_partner_error: false,
      marital_status_of_partner_over3_error: false,
      children_error: false,
      smoking_status_error: false,
      heterosexual_smoking_error: false,
      annual_income_error: false,
      height_error: false,
      navi1_error: false,
      navi2_error: false,
      navi3_error: false,
      current_children_error: false,

      sibling_error: false,
      blood_type_error: false,
      liquor_error: false,
      to_message_error: false,
      job_worthwhile_error: false,
      date_timing_error: false,
      birthplace_error: false,
      u_turn_intention_error: false,
      ideal_housework_and_childcare_error: false,
      acceptable_housework_and_childcare_error: false,

      isHousewife: false,
      isTerms: false,
      isSmokingHeavy: false,
      openSelfIntrudctionTemplate: false,
      publics: {},

      inputError: false,
    }
  }

  componentWillMount () {
    window.scrollTo(0,0)
    if (this.isEnteredLoveSheet(this.props.user)) {
      this.getProfilePublicSetting(this.props.user.id)
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.initProfileInputs()
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  initProfileInputs () {
    const { user } = this.props
    const storageProfile = Storage.editingUserProfile.value
    BaseComponent.setInputValue(
      'how_to_spend_the_holidays',
      user.how_to_spend_the_holidays || storageProfile?.how_to_spend_the_holidays || ''
    )
    BaseComponent.setInputValue(
      'to_message',
      user.to_message || storageProfile?.to_message || ''
    )
    if (utility.calcAge(user.birthday) < 20) {
      this.setState({
        liquor: 'not_drink',
        smoking_status: 'not_smoke',
      })
    }
  }

  useState (editKey) {
    return ![
      'how_to_spend_the_holidays',
      'to_message',
    ].includes(editKey)
  }

  handleChange (event) {
    const value = event.target.value
    const name = event.target.name

    const storageValue = Storage.editingUserProfile.value
    Storage.editingUserProfile.value = Object.assign(
      storageValue || this.props.user, { [name]: value }
    )

    if (!this.useState(name)) {
      BaseComponent.setInputValue(name, value)
      if (this.state[name + '_error']) { this.setState({ [name + '_error']: false }) }
      if (this.state[name + '_over3_error']) { this.setState({ [name + '_over3_error']: false }) }
    } else if (name === 'marital_status' && value === 'over_3_times') {
      this.setState({
        [name]: value,
        [name + '_error']: true,
        [name + '_over3_error']: true,
      })
    } else if (name === 'marital_status_of_partner' && value === 'over_3_times') {
      this.setState({
        [name]: value,
        [name + '_error']: true,
        [name + '_over3_error']: true,
      })
    } else if ((name === 'future_work_way_of_partner' || name === 'future_work_way') && value === 'housewife') {
      this.setState({
        isHousewife: true,
        [name]: value,
        [name + '_error']: false,
      })
    } else if (name === 'smoking_status' && value === 'heavy') {
      this.setState({
        isSmokingHeavy: true,
        [name]: value,
        [name + '_error']: false,
      })
    } else {
      this.setState({
        [name]: value,
        [name + '_error']: false,
        [name + '_over3_error']: false,
      })
    }
    if (this.state.inputError) {
      this.setStateIfMounted({ inputError: false })
    }
  }

  getIgnoredKeys () {
    let ignoredKeys = [
      'isHousewife', 'isTerms', 'isSmokingHeavy',
      'openSelfIntrudctionTemplate', 'publics', 'inputError',
    ]
    Array.prototype.push.apply(ignoredKeys, utility.getProfileIgnoredKeys())
    return ignoredKeys
  }

  async submitEntry (event) {
    event.preventDefault()

    let goPost = true
    const params = {}
    const ignoredKeys = this.getIgnoredKeys()

    const optionalKeys = [
      'job_worthwhile',
      'interested_things',
    ]

    Object.keys(this.state).forEach(key => {
      if (key.indexOf('_error') !== -1 || ignoredKeys.includes(key)) {
        return
      }

      // 任意項目
      if (optionalKeys.includes(key)) {
        if (this.state[key].length > 50) {
          this.setState({[key + '_error']: true})
          goPost = false
        } else {
          params[key] = this.state[key]
        }
        return
      }
      const val = this.useState(key) ? this.state[key].trim() : BaseComponent.getInputValue(key).trim()
      if (utility.isEmpty(val)) {
        this.setState({[key + '_error']: true})
        goPost = false
      } else {
        if (key === 'marital_status' && val === 'over_3_times') {
          this.setState({
            [key + '_error']: true,
            [key + '_over3_error']: true,
          })
          goPost = false
        } else if (key === 'marital_status_of_partner' && val === 'over_3_times') {
          this.setState({
            [key + '_error']: true,
            [key + '_over3_error']: true,
          })
          goPost = false
        } else if (key === 'to_message' && val.length > Const.maxToMessageLength) {
          this.setState({[key + '_error']: true})
          goPost = false
        } else {
          params[key] = val
        }
      }
      return null
    })

    // 恋愛対象「異性」を固定でセット
    params['love_target'] = 'heterosexual'

    if (goPost) {
      this.props.setLoading(true)
      try {
        const user = await this.updateUser(params)
        await Api.updateProfilePublicSetting(this.props.user.id, this.state.publics)
        if (this.locationIsMyLove()) {
          await this.loadMatchings()
          this.props.history.goBack()
        } else {
          Storage.editingUserProfile.clear()
          BaseComponent.toEntryNextStep(this.props, user)
        }
      } catch (error) {
        this.handleApiError(error)
      } finally {
        this.props.setLoading(false)
      }
    } else {
      this.setStateIfMounted({ inputError: true })
    }
  }

  goBack = () => {
    if (this.locationIsMyLove()) {
      this.props.history.goBack()
    } else {
      // 電話番号登録画面へ
      this.props.setScreen('PhoneNumber')
    }
  }

  closeConfirmDialog () {
    this.setState({ isHousewife: false })
  }

  openTermsDialog () {
    this.setState({isTerms: true})
  }

  closeTermsDialog () {
    this.setState({isTerms: false})
  }

  async getProfilePublicSetting (user_id) {
    this.props.setLoading(true)
    try {
      let setting = await Api.getProfilePublicSetting(user_id)
      this.setStateIfMounted({ publics: setting })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async handlePublicChange (event) {
    let key = event.target.value
    let publics = this.state.publics
    publics[key] = event.target.checked
    this.setStateIfMounted({ publics: publics })
  }

  isEnteredLoveSheet (user) {
    if (utility.isEmpty(user.holiday)) {
      return false
    }
    return true
  }

  closeSmokingConfirmDialog () {
    this.setState({ isSmokingHeavy: false })
  }

  // ログイン後の「恋愛情報編集」画面か
  locationIsMyLove () {
    return Config.deployMode === 'app'
  }

  createHeader (isMyLove) {
    const { classes, ...other } = this.props
    return (
      <div>
        <AppHeader title="恋愛情報" hideBack={true} {...other} />
        {!isMyLove && <EntrySteps step={1} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} /> }
        <Grid container direction='column' alignItems="center" justifyContent="center">
          <Grid item>
            <Typography className={isMyLove ? classes.subTitleEdit : classes.subTitle}>
              より良い相性の異性をご紹介するため入力をお願いします。
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  render () {
    if (!Storage.enabled) {
      return <DisableCookieScreen />
    }

    const { classes, user } = this.props
    const isMyLove = Config.deployMode === 'app'

    return (
      <React.Fragment>
        <ImportantConfirmDialog
          open={this.state.isHousewife}
          onClose={this.closeConfirmDialog}
          onOk={this.closeConfirmDialog}
          onCancel={this.closeConfirmDialog}
          close="yes"
          title="<<注意事項>>"
          message={this.getHousewifeConfirmMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.isTerms}
          onClose={this.closeTermsDialog}
          onOk={this.closeTermsDialog}
          onCancel={this.closeTermsDialog}
          close="yes"
          title="第5条（利用資格）"
          message={this.getHousewifeTermsMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.isSmokingHeavy}
          onClose={this.closeSmokingConfirmDialog}
          onOk={this.closeSmokingConfirmDialog}
          onCancel={this.closeSmokingConfirmDialog}
          close="yes"
          title="注意事項"
          message={this.getSmokingConfirmMessage()}
        />
        <SelfIntroductionTemplateDialog
          open={this.state.openSelfIntrudctionTemplate}
          onChange={text => BaseComponent.setInputValue('to_message', text)}
          onClose={() => this.setState({ openSelfIntrudctionTemplate: false })}
          user={user}
        />
        <div className={isMyLove ? classes.rootcontainerEdit : classes.rootcontainer}>
          {this.createHeader(isMyLove)}
          <form className={isMyLove ? classes.containerEdit : classes.container} noValidate autoComplete="off" onSubmit={this.submitEntry}>
            <Card className={classes.card}>
              {/* 休日 */}
              {this.createUserProfileSelect('holiday')}
              {/* 趣味 */}
              {this.createUserProfileTextField('how_to_spend_the_holidays', { InputLabelProps: { className: classes.inputLabel } })}
              {/* 希望する自分の将来の働き方 */}
              {this.createUserProfileSelect('future_work_way')}
              {/* 希望する相手の将来の働き方 */}
              {this.createUserProfileSelect('future_work_way_of_partner')}
              {/* 理想とする自分が担う家事育児の役割 */}
              {this.createUserProfileSelect('ideal_housework_and_childcare')}
              {/* 許容する自分が担う家事育児の役割 */}
              {this.createUserProfileSelect('acceptable_housework_and_childcare')}
              {/* 結婚歴 */}
              {this.createUserProfileSelect('marital_status', { noHelper: !this.state.marital_status_over3_error })}
              {/* 相手に求める結婚歴 */}
              {this.createUserProfileSelect('marital_status_of_partner', { noHelper: !this.state.marital_status_of_partner_over3_error })}
              {/* （現在）子供の有無 */}
              {this.createUserProfileSelect('current_children')}
              {/* （将来）子供の有無・人数 */}
              {this.createUserProfileSelect('children')}
              {/* 兄弟姉妹 */}
              {this.createUserProfileSelect('sibling')}
              {/* 身長 */}
              {this.createUserProfileSelect('height')}
              {/* 年収 */}
              {this.createUserProfileSelect('annual_income')}
              {/* 出身 */}
              {this.createUserProfileSelect('birthplace')}
              {/* 将来は地元に帰りたい */}
              {this.createUserProfileSelect('u_turn_intention')}
              {/* 血液型 */}
              {this.createUserProfileSelect('blood_type')}
              {/* お酒 */}
              {this.createUserProfileSelect('liquor', { disabled: utility.calcAge(user.birthday) < 20 })}
              {/* 喫煙状況 */}
              {this.createUserProfileSelect('smoking_status', { disabled: utility.calcAge(user.birthday) < 20 })}
              {/* 相手の喫煙に関して */}
              {this.createUserProfileSelect('heterosexual_smoking')}
              {/* 異性とのデートのタイミング */}
              {this.createUserProfileSelect('date_timing')}
              {/* 興味のあること */}
              <InterestedThingsProfile
                {...this.props}
                user={user}
                selectedItems={this.state.interested_things || []}
                setSelectedItems={(val) => {
                  this.setState({ interested_things: val })
                  const storageValue = Storage.editingUserProfile.value
                  Storage.editingUserProfile.value = Object.assign(
                    storageValue || this.props.user, { 'interested_things': val }
                  )
                }}
              />
              {/* 異性へのメッセージ */}
              {this.createUserProfileTextFieldMultiline('to_message', { InputLabelProps: { className: classes.inputLabel } })}
              <Button
                variant="contained"
                color="secondary"
                style={{ width: 256, height: 40 }}
                onClick={() => this.setState({ openSelfIntrudctionTemplate: true })}
              >
                テンプレートで作成する
              </Button>
            </Card>
            <Typography className={classes.helperText}>下記の項目はAIにインプットさせていただく情報です。<br />（異性に公開されません）</Typography>
            <Card className={classes.card}>
              {/* 希望する相手の年齢 */}
              <MatchingFilterAge {...this.props} />
              {/* 希望する相手の住まい */}
              <MatchingFilterAddress {...this.props} />
              {/* 理想とするメール頻度 */}
              {this.createUserProfileSelect('navi1')}
              {/* メールは一行の場合が多い？ */}
              {this.createUserProfileSelect('navi2')}
              {/* 相手から長文のメールが来た時の気持ちは？ */}
              {this.createUserProfileSelect('navi3')}
            </Card>
            <Grid container direction="column" alignItems="center" className={classes.buttonContainer}>
              {this.state.inputError && (
                <Grid item className={classes.button}>
                  <Typography className={classes.inputError}>
                    エラーの項目があります。<br/>赤字の箇所をご確認ください。
                  </Typography>
                </Grid>
              )}
              <Grid item className={classes.button}>
                <Button variant="contained" color="secondary" className={classes.entry} type="submit">
                  {isMyLove ? '保存する':'送信'}
                </Button>
              </Grid>
              <Grid item className={classes.button}>
                <Button variant="text" color="secondary" className={classes.back} onClick={this.goBack}>
                  {isMyLove ? 'キャンセル' : '戻る'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(LoveSheet)
