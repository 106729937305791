export default function (theme) {
  return {
    fontSize: 14,
    fontWeight: 300,
    color: '#414141',
    paddingRight: `${theme.spacing(1)}px !important`,
    '&:focus': {
      backgroundColor: '#ffffff',
    }
  }
}