import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import WarningIcon from '@material-ui/icons/Warning'
import Api from 'commons/api'
import Const from 'commons/constant'
import CouponConfirmDialog from 'components/members/CouponConfirmDialog'
import CouponInfo from 'components/members/CouponInfo'
import CouponSubmittedDialog from 'components/members/CouponSubmittedDialog'
import HowToDiscountCouponDialog from 'components/members/HowToDiscountCouponDialog'
import HowToFreeCouponDialog from 'components/members/HowToFreeCouponDialog'
import BaseComponent from 'components/parts/BaseComponent'
import QuestionIcon from 'images/invitation-campaign/question_icon.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 640,
  },
  about: {
    width: 'fit-content',
    margin: theme.spacing(0.5),
    cursor: 'pointer',
  },
  aboutText: {
    fontSize: 13,
    fontWeight: 600,
    color: '#616AFA',
    marginLeft: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 640,
    background: '#F4F7FE',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: sp => sp ? theme.spacing(3) : theme.spacing(4),
  },
  inputBox: {
    width: '100%',
    maxWidth: sp => sp ? 316 : 297,
    position: 'relative',
  },
  input: {
    height: 46,
    fontSize: 14,
    fontWeight: 300,
    color: '#9D9D9E',
    background: '#ffffff',
    borderRadius: 5,
    paddingLeft: theme.spacing(1.5),
  },
  errMsg: {
    fontSize: 12,
    fontWeight: 300,
    color: '#F55083',
    position: 'absolute',
    bottom: -24,
  },
  submit: {
    width: sp => sp ? 220 : 184,
    height: sp => sp ? 41 : 37,
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    background: '#616AFA',
    borderRadius: 45,
    marginTop: sp => sp ? theme.spacing(3) : 0,
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    '&:hover': {
      background: '#616AFA',
    }
  },
  couponList: {
    width: '100%',
    maxWidth: 504,
    background: '#ffffff',
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
  },
}))

export default function Coupon (props) {
  const { subscription } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)
  const [couponCode, setCouponCode] = useState('')
  const [couponCodeError, setCouponCodeError] = useState(false)
  const [couponInfo, setCouponInfo] = useState(null)
  const [myCoupons, setMyCoupons] = useState([])
  const [openHowToFreeCouponDialog, setOpenHowToFreeCouponDialog] = useState(false)
  const [openHowToDiscountCouponDialog, setOpenHowToDiscountCouponDialog] = useState(false)
  const [openCouponConfirmDialog, setOpenCouponConfirmDialog] = useState(false)
  const [openCouponSubmittedDialog, setOpenCouponSubmittedDialog] = useState(false)

  useEffect(() => {
    loadMyTickets()
  }, [])

  const loadMyTickets = async () => {
    try {
      const coupons = await Api.getMyTickets()
      const targetCoupons = coupons.filter(item => {
        if (!item.title) { return false }

        const type = item.incentive_type
        if (type === 'standard_plan') { return true }
        if (type === 'plan_discount') { return true }
        return false
      })
      setMyCoupons(targetCoupons)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const createHowTo= (text, onClick) => (
    <Grid
      className={classes.about}
      container
      justifyContent="center"
      alignContent="center"
      onClick={onClick}
    >
      <img src={QuestionIcon} width={22} height={22} />
      <Typography className={classes.aboutText}>
        <u>{text}</u>
      </Typography>
    </Grid>
  )

  const createErrorMessage = () => {
    if (!couponCodeError) { return }
    return (
      <Grid className={classes.errMsg} container alignItems="center">
        <WarningIcon style={{ width: 20, height: 20 }} />
        &nbsp;&nbsp;{couponCodeError}
      </Grid>
    )
  }

  const onChangeCoupon = (e) => {
    const value = e.target.value
    setCouponCode(value)
    setCouponCodeError(false)
  }

  const checkCoupon = async () => {
    if (!couponCode) { return }
    props.setLoading(true)
    try {
      const res = await Api.getCampaignMaster({ campaign_code: couponCode })
      setCouponInfo(res)
      setOpenCouponConfirmDialog(true)
    } catch (error) {
      error.response.json().then(body => {
        if (body.message) {
          setCouponCodeError(body.message)
        }
      })
    } finally {
      props.setLoading(false)
    }
  }

  const submitCoupon = async () => {
    if (!couponCode) { return }
    props.setLoading(true)
    try {
      setOpenCouponConfirmDialog(false)
      const res = await Api.submitTicket({ campaign_code: couponCode })
      if (res.used) {
        window.location.reload()
      } else {
        loadMyTickets()
        setOpenCouponSubmittedDialog(true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const canShowNotice = () => {
    if (!subscription) { return false }
    const planType = subscription.content
    return planType === Const.planTypes.STANDARD || planType === Const.planTypes.STANDARD_INITIAL
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        {createHowTo('無料クーポンの使い方', () => setOpenHowToFreeCouponDialog(true))}
        {createHowTo('割引クーポンの使い方', () => setOpenHowToDiscountCouponDialog(true))}
      </Grid>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.title}>クーポン登録</Typography>
        <Typography className={classes.text}>
          お持ちのクーポンコードをご登録ください。<br />
          クーポンコードは所属企業・団体から案内されている情報をご確認ください。
        </Typography>
        <Grid className={classes.inputContain} container justifyContent="center" alignItems="center">
          <div className={classes.inputBox}>
            <Input
              id="input-coupon-code"
              className={classes.input}
              placeholder="ここに入力してください"
              onChange={onChangeCoupon}
              disableUnderline
              fullWidth
              inputProps={{ maxLength: 8 }}
            />
            {createErrorMessage()}
          </div>
          <Button
            className={classes.submit}
            variant="contained"
            onClick={checkCoupon}
            disabled={!couponCode}
          >
            登録する
          </Button>
        </Grid>
      </Grid>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.title}>
          登録済みクーポンの<br />
          利用選択
        </Typography>
        <Typography className={classes.text}>
          「選択中」のクーポンが次回の月額ご利用料金決済時に使用されます。
          {canShowNotice() && (
            <>
              <br />
              {dateFormat(subscription.next_billing_date, 'm月d日')}{Const.subscriptionUpdateHour}時〜{Const.subscriptionUpdateHour + 1}時は変更できません。
            </>
          )}
        </Typography>
        {myCoupons.length > 0 ? (
          <div className={classes.couponList}>
            {myCoupons.map((data, index) => (
              <React.Fragment key={index}>
                <CouponInfo
                  data={data}
                  index={index}
                  loadMyTickets={loadMyTickets}
                  {...props}
                />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className={classes.couponList}>
            <CouponInfo />
          </div>
        )}
      </Grid>
      <HowToFreeCouponDialog
        open={openHowToFreeCouponDialog}
        onClose={() => setOpenHowToFreeCouponDialog(false)}
      />
      <HowToDiscountCouponDialog
        open={openHowToDiscountCouponDialog}
        onClose={() => setOpenHowToDiscountCouponDialog(false)}
      />
      <CouponConfirmDialog
        open={openCouponConfirmDialog}
        onConfirm={submitCoupon}
        onClose={() => setOpenCouponConfirmDialog(false)}
        data={couponInfo}
      />
      <CouponSubmittedDialog
        open={openCouponSubmittedDialog}
        onClose={() => setOpenCouponSubmittedDialog(false)}
        data={couponInfo}
      />
    </div>
  )
}

Coupon.propTypes = {
  subscription: PropTypes.object,
  setLoading: PropTypes.func,
}
