import React, { useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Config from 'commons/config'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { formatPrevJapaneseDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  content: {
    '& p': {
      textAlign: 'left',
    },
    '& ul': {
      listStyle: '"＊"',
      padding: theme.spacing(1.5),
      margin: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  komainu: {
    width: 170,
    height: 67,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
    objectFit: 'none',
    objectPosition: 'center top',
  },
}))

export default function PlanUpdateDialog (props) {
  const { open, onClose, subscription } = props
  const classes = useStyles()

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('planupdate')
  }, [open])

  if (!subscription) { return null }
  if (!subscription.next_billing_date) { return null }

  const planChange = () => {
    return (
      subscription.content !== subscription.next_plan_id
      || subscription.payment_months !== subscription.next_payment_months
    )
  }

  const currentPlanName = () => {
    switch (subscription.content) {
      case Const.planTypes.STANDARD_INITIAL:
        return '無料トライアル期間'
      case Const.planTypes.STANDARD:
        return `スタンダードプラン（${subscription.payment_months || 1}ヶ月分）`
      default:
        return ''
    }
  }

  const nextPlanName = () => {
    switch (subscription.next_plan_id) {
      case Const.planTypes.STANDARD_INITIAL:
        return '無料トライアル期間'
      case Const.planTypes.STANDARD:
        return `スタンダードプラン（${subscription.next_payment_months || subscription.payment_months || 1}ヶ月分）`
      case Const.planTypes.FREE:
        return 'フリープラン'
      case Const.planTypes.SPECIAL_FREE:
        return '特別フリープラン'
      default:
        return ''
    }
  }

  const handleClose = async () => {
    const id = `Info.PlanUpdate_${subscription.next_billing_date}`
    onClose(id)
  }

  const openMembersSite = () => {
    window.open(Config.membersSiteUrl)
    handleClose()
  }

  return (
    <>
      <DialogThemeProvider color="primary">
        <Dialog open={open}>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogTitle disableTypography>
            ご利用プラン更新の<br />お知らせ
          </DialogTitle>
          <DialogContent className={classes.content}>
            {planChange() ? (
              <Typography variant="body1">
                {currentPlanName()}の期限は{formatPrevJapaneseDate(subscription.next_billing_date)}となっております。<br />
                その後、自動で{nextPlanName()}へ変更となります。
              </Typography>
            ) : (
              <Typography variant="body1">
                {currentPlanName()}の次回更新日は{dateFormat(subscription.next_billing_date, 'yyyy年m月d日')}となっております。<br />
                <br />
                プラン内容をご変更される場合は、前日までに下記メンバーズサイトよりお願いいたします。
              </Typography>
            )}
            <ul>
              <li>
                <Typography variant="body1">ご利用プランの更新は更新日の午前8時に処理されます。</Typography>
              </li>
              <li>
                <Typography variant="body1">更新処理の時刻は予告なく変更となる場合がありますのでご了承ください。</Typography>
              </li>
            </ul>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={openMembersSite}>
              メンバーズサイトを開く
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

PlanUpdateDialog.propTypes = {
  subscription: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}