import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Config from 'commons/config'
import { copyToClipboard } from 'commons/utility'
import HowToInvitationCampaignDialog from 'components/invitation-campaign/HowToInvitationCampaignDialog'
import BaseComponent from 'components/parts/BaseComponent'
import CopyIcon from 'images/invitation-campaign/copy_icon.png'
import ImgPC01 from 'images/invitation-campaign/invitation_campaign_pc_01.png'
import ImgPC02 from 'images/invitation-campaign/invitation_campaign_pc_02.png'
import ImgSP01 from 'images/invitation-campaign/invitation_campaign_sp_01.png'
import ImgSP02 from 'images/invitation-campaign/invitation_campaign_sp_02.png'
import Komainu from 'images/invitation-campaign/komainu.png'
import PlusIcon from 'images/invitation-campaign/plus_icon.png'
import QuestionIcon from 'images/invitation-campaign/question_icon.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 640,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  plus: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 18,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
  },
  img: {
    width: '100%',
  },
  about: {
    marginTop: theme.spacing(2.5),
    cursor: 'pointer',
  },
  aboutText: {
    fontSize: 13,
    fontWeight: 600,
    color: '#616AFA',
    marginLeft: theme.spacing(0.5),
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(3),
  },
  myCode: {
    width: '100%',
    maxWidth: 505,
    height: 88,
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 1.12,
    color: '#1A1667',
    background: '#ffffff',
    borderRadius: 10,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  komainu: {
    marginBottom: theme.spacing(-3.25),
  },
  note: {
    maxWidth: 505,
    fontSize: 11,
    fontWeight: 300,
    color: '#707070',
    marginBottom: theme.spacing(6),
  },
}))

export default function InvitationCampaign (props) {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles()
  const [campaignUrl, setCampaignUrl] = useState('')
  const [openHowToInvitationCampaignDialog, setOpenHowToInvitationCampaignDialog] = useState(false)

  useEffect(() => {
    createCampaignCode()
  }, [])

  const createCampaignCode = async () => {
    try {
      const params = { campaign_type: 'invitation' }
      const res = await Api.createCampaignCode(params)
      setCampaignUrl(res.code.toUpperCase())
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const shareInvitationLink = async () => {
    try {
      await Api.addActionLog('click', 'share_button')
      const text = `一緒にAill goenを始めよう！
登録時に紹介コードを入れるとお互いに無料期間が付与されるよ！
紹介コード【${campaignUrl}】

登録はこちらから！
${Config.entrySiteUrl}`
      // iOS
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.shareToOtherApps.postMessage(text)
      // Android
      } else if (window.android) {
        window.android.shareToOtherApps(text)
      } else {
        try {
          await copyToClipboard(text)
          BaseComponent.showRequestSuccessMessage(props, 'コピーしました')
        } catch (error) {
          BaseComponent.showErrorMessage(props, error)
        }
      }
    } catch (error) {
      // noop
    }
  }

  const createImg01 = () => (
    <img className={classes.img} src={spMatches ? ImgSP01 : ImgPC01} width={spMatches ? 316 : 505} />
  )

  const createImg02 = () => (
    <img className={classes.img} src={spMatches ? ImgSP02 : ImgPC02} width={spMatches ? 316 : 505} />
  )

  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {createImg01()}
        <img className={classes.plus} src={PlusIcon} width={32} height={32} />
        <Typography className={classes.text}>さらに！！</Typography>
        {createImg02()}
        <Grid
          className={classes.about}
          container
          justifyContent="center"
          alignContent="center"
          onClick={() => setOpenHowToInvitationCampaignDialog(true)}
        >
          <img src={QuestionIcon} width={22} height={22} />
          <Typography className={classes.aboutText}>
            <u>紹介キャンペーンの利用方法を確認する</u>
          </Typography>
        </Grid>
        <Typography className={classes.title}>紹介コード</Typography>
        <img className={classes.komainu} src={Komainu} width={205} height={130} />
        <Grid className={classes.myCode} container wrap="nowrap" justifyContent="space-between" alignItems="center">
          {campaignUrl}
          <img src={CopyIcon} width={50} height={50} onClick={shareInvitationLink} />
        </Grid>
        <Typography className={classes.note}>
          ＊ご紹介いただいた場合も、お友達が既に入会されている、または入会されないなどの場合はキャンペーンの対象となりません<br />
          ＊お友達が紹介コードの入力を行うと、あなたとお友達それぞれに特典が付与されます<br />
          ＊お友達が入会審査を通過してから1ヶ月以内に紹介コードを入力する必要があります
        </Typography>
      </Grid>
      <HowToInvitationCampaignDialog
        open={openHowToInvitationCampaignDialog}
        onClose={() => setOpenHowToInvitationCampaignDialog(false)}
      />
    </div>
  )
}
