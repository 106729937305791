import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {
    width: 'fit-content',
    fontWeight: 300,
    color: '#414141',
    whiteSpace: 'pre-wrap',
  }
}))

export default function MatchingFilterItemLabel (props) {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.root}>
      {props.children}
    </Typography>
  )
}

MatchingFilterItemLabel.propTypes = {
  children: PropTypes.object,
}