import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import Komainu from 'images/free-term-extension-challenge/koma_good.png'
import SparklingLeft from 'images/free-term-extension-challenge/sparkling_left.png'
import SparklingRight from 'images/free-term-extension-challenge/sparkling_right.png'

const useStyles = makeStyles(theme => ({
  content: {
    width: 289,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    position: 'relative',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  box: {
    width: 200,
    height: 99,
    borderRadius: 5,
    background: '#ffffff',
    margin: 'auto',
    marginTop: theme.spacing(-2),
    padding: theme.spacing(2.25),
    '& p:nth-of-type(1)': {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1,
      color: '#FF6666',
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    '& p:nth-of-type(2)': {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1,
      color: '#FF6666',
      margin: 0,
    },
  },
  giftcard: {
    position: 'relative',
    marginBottom: theme.spacing(-2),
  },
  giftcardText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    position: 'absolute',
    bottom: 26,
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  achievedNote: {
    marginTop: theme.spacing(1.5),
    marginBottom: 0,
    textAlign: 'center',
  },
  largeText: {
    fontSize: 16,
  },
}))

export default function FreeTermExtensionChallengeAchievedDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="free-term-extension-challenge">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          チャレンジ達成！
        </DialogTitle>
        <DialogContent className={classes.content}>
          <img src={Komainu} width={86.49} />
          <Grid className={classes.box}>
            <Typography>無料トライアル期間</Typography>
            <Typography>14日延長</Typography>
          </Grid>
          <img src={SparklingLeft} width={26.57} style={{ position: 'absolute', top: 2, left: 16 }} />
          <img src={SparklingRight} width={26.57} style={{ position: 'absolute', top: 112, right: 16 }} />
          <Typography className={classes.achievedNote} variant="body2">
            ＊詳細はメンバーズサイト<br />
            「お支払い履歴」を確認ください
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeAchievedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
