import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'
import GuidanceTitleConfession from 'images/talk_assist_guidance/guidance_title_confession.png'
import GuidanceTitleDatingProposal from 'images/talk_assist_guidance/guidance_title_dating_proposal.png'
import GuidanceTitleDatingStop from 'images/talk_assist_guidance/guidance_title_dating_stop.png'
import GuidanceTitleDigging2 from 'images/talk_assist_guidance/guidance_title_digging2.png'
import GuidanceTitleFemale from 'images/talk_assist_guidance/guidance_title_female.png'
import GuidanceTitleMale from 'images/talk_assist_guidance/guidance_title_male.png'
import Kagura from 'images/talk_assist_guidance/kagura.png'
import KaguraIcon from 'images/talk_assist_guidance/kagura_icon.png'
import Sakaki from 'images/talk_assist_guidance/sakaki.png'
import SakakiIcon from 'images/talk_assist_guidance/sakaki_icon.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 321,
    borderRadius: 22,
    margin: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
  },
  serif: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
  },
  kagura: {
    position: 'absolute',
    bottom: -30,
    left: 2,
  },
  sakaki: {
    position: 'absolute',
    bottom: -30,
    right: 5,
  },
  kaguraMale: {
    position: 'absolute',
    top: 84,
    right: 19,
  },
  sakakiMale: {
    position: 'absolute',
    top: 108,
    left: 14,
  },
  kaguraFemale: {
    position: 'absolute',
    top: 122,
    right: 14,
  },
  sakakiFemale: {
    position: 'absolute',
    top: 144,
    left: 12,
  },
  button: {
    width: 194,
    height: 40,
    color: '#ffffff',
    background: '#616AFA',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      color: '#ffffff',
      background: '#616AFA',
    },
  },
  textButton: {
    height: 20,
    maxHeight: 20,
    color: '#616AFA',
    fontSize: 12,
    fontWeight: 600,
  },
  naviTo: {
    color: '#555555',
    fontSize: 10,
    lineHeight: 1,
    whiteSpace: 'pre',
    textAlign: 'left',
    marginLeft: theme.spacing(8),
    '-webkit-user-select': 'none',
  },
}))

const TALK_ASSIST_GUIDANCE = 'talk_assist_guidance'
const TALK_ASSIST_GUIDANCE_DIGGING2 = 'talk_assist_guidance_digging2'
const TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION = 'talk_assist_guidance_stop_dating_invitation'
const TALK_ASSIST_GUIDANCE_DATING_PROPOSAL = 'talk_assist_guidance_dating_proposal'
const TALK_ASSIST_GUIDANCE_CONFESSION = 'talk_assist_guidance_confession'

export default function TalkAssistGuidance (props) {
  const classes = useStyles()
  const { user, friend, message } = props
  if (!user) { return null }
  if (!friend) { return null }
  if (!message) { return null }

  const createTitle = () => {
    switch (message.message_type) {
      case TALK_ASSIST_GUIDANCE:
        if (user.sex === 'male') {
          return <img src={GuidanceTitleMale} width={200} />
        }
        if (user.sex === 'female') {
          return <img src={GuidanceTitleFemale} width={304} />
        }
        return null
      case TALK_ASSIST_GUIDANCE_DIGGING2:
        return <img src={GuidanceTitleDigging2} width={304} />
      case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
        return <img src={GuidanceTitleDatingStop} width={304} />
      case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
        return <img src={GuidanceTitleDatingProposal} width={304} />
      case TALK_ASSIST_GUIDANCE_CONFESSION:
        return <img src={GuidanceTitleConfession} width={294} />
      default:
        return null
    }
  }

  const createSerif = () => {
    switch (message.message_type) {
      case TALK_ASSIST_GUIDANCE:
        return user.sex === 'male' ? '女性側のムービーも見てね' : '聞きにくいことも代わりに聞くよ'
      case TALK_ASSIST_GUIDANCE_DIGGING2:
        return '応援で回答率UP！'
      case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
      case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
      case TALK_ASSIST_GUIDANCE_CONFESSION:
        return '女性編のムービーを見てみてね'
      default:
        return null
    }
  }

  const createKagura = () => {
    switch (message.message_type) {
      case TALK_ASSIST_GUIDANCE:
        if (friend.type === 'ai') {
          return <img className={classes.kagura} src={Kagura} width={55} />
        } else {
          const classname = user.sex === 'male' ? classes.kaguraMale : classes.kaguraFemale
          return <img className={classname} src={KaguraIcon} width={38} />
        }
      case TALK_ASSIST_GUIDANCE_DIGGING2:
        return <img src={KaguraIcon} width={38} style={{ position: 'absolute', top: 128, right: 28 }} />
      case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
      case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
      case TALK_ASSIST_GUIDANCE_CONFESSION:
        return <img src={KaguraIcon} width={38} style={{ position: 'absolute', top: 128, right: 18 }} />
      default:
        return null
    }
  }

  const createSakaki = () => {
    switch (message.message_type) {
      case TALK_ASSIST_GUIDANCE:
        if (friend.type === 'ai') {
          return <img className={classes.sakaki} src={Sakaki} width={56} />
        } else {
          const classname = user.sex === 'male' ? classes.sakakiMale : classes.sakakiFemale
          return <img className={classname} src={SakakiIcon} width={46} />
        }
      case TALK_ASSIST_GUIDANCE_DIGGING2:
      case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
      case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
      case TALK_ASSIST_GUIDANCE_CONFESSION:
        return <img src={SakakiIcon} width={46} style={{ position: 'absolute', top: 138, left: 14 }} />
      default:
        return null
    }
  }

  const createButton = () => {
    switch (message.message_type) {
      case TALK_ASSIST_GUIDANCE:
      case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
      case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
      case TALK_ASSIST_GUIDANCE_CONFESSION:
        return (
          <Button className={classes.button} variant="contained" onClick={openYoutube}>
            アシスト例を見る
          </Button>
        )
      case TALK_ASSIST_GUIDANCE_DIGGING2:
        return (
          <Button className={classes.textButton} variant="text" onClick={openYoutube}>
            <u>回答イメージを見る</u>
          </Button>
        )
      default:
        return null
    }
  }

  const openYoutube = async () => {
    try {
      await Api.addActionLog('click', message.message_type)
      switch (message.message_type) {
        case TALK_ASSIST_GUIDANCE:
          if (user.sex === 'male') {
            window.open('https://www.youtube.com/watch?v=8lwx_lWO6xY')
            break
          }
          if (user.sex === 'female') {
            window.open('https://www.youtube.com/watch?v=WnurqWmtpws')
            break
          }
          break
        case TALK_ASSIST_GUIDANCE_DIGGING2:
          window.open('https://www.youtube.com/watch?v=5P6Fkkuiabk')
          break
        case TALK_ASSIST_GUIDANCE_STOP_DATING_INVITATION:
          window.open('https://www.youtube.com/watch?v=IkJK8Z12wHM')
          break
        case TALK_ASSIST_GUIDANCE_DATING_PROPOSAL:
        case TALK_ASSIST_GUIDANCE_CONFESSION:
          window.open('https://www.youtube.com/watch?v=TNxC0qjPYQo')
          break
        default:
          break
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction='column'
        justifyContent="center"
        alignItems="center"
        style={{
          background: friend.type === 'ai' ? '#ffffff' : '#EFF0FF',
          border: friend.type === 'ai' ? 'solid 2px #5463F6' : 'dotted 2px #5463F6',
        }}
      >
        {createTitle()}
        <Typography className={classes.serif}>\ {createSerif()} /</Typography>
        {createButton()}
        {createKagura()}
        {createSakaki()}
      </Grid>
      <Typography className={classes.naviTo}>
        {friend.type === 'ai' ? '' : '自分だけに表示'}
      </Typography>
    </>
  )
}

TalkAssistGuidance.propTypes = {
  message: PropTypes.object,
  user: PropTypes.object,
  friend: PropTypes.object,
}
