import React from 'react'
import { isNullOrUndefined } from 'util'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Api from 'commons/api'
import Features from 'commons/features'
import * as Storage from 'commons/storage'
import { httpNotFound, isSecretQuestion, getFriendTopicSuggestion } from 'commons/utility'
import NaviPopover from 'components/first-navigation/NaviPopover'
import NaviPopper from 'components/first-navigation/NaviPopper'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ChatInput from 'components/parts/ChatInput.jsx'
import CommonChat from 'components/parts/CommonChat'
import NaviSpeech from 'components/parts/NaviSpeech'
import SecretQuestionRoom from 'components/parts/SecretQuestionRoom'
import firstFavNaviImg03 from 'images/Komainu/firstFavNaviImg03.png'
import FirstTalkNaviImg01 from 'images/Komainu/firstTalkNaviImg01.png'
import FirstTalkNaviImg02 from 'images/Komainu/firstTalkNaviImg02.png'
import FirstTalkNaviImg03 from 'images/Komainu/firstTalkNaviImg03.png'
import FirstTalkNaviImg04 from 'images/Komainu/firstTalkNaviImg04.png'
import NewFeatureDialog from './notifications/NewFeatureDialog'

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
  },
  dateContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '-webkit-user-select': 'none',
  },
  date: {
    display: 'inline-block',
    color: '#555555',
    fontSize: 10,
  },
  scroll: {
    overflow: 'auto',
    position: 'inherit',
    top: theme.styles.header.height,
    '-webkit-overflow-scrolling': 'touch',
  },
  warningDialog: {
    maxWidth: 500,
    maxHeight: 550,
    backgroundColor: '#423BC7',
  },
  warningTitle: {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  warningText: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  warningOkButton: {
    fontSize: 16,
    color: '#423BC7',
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  blurOn: {
    '& div.MuiBackdrop-root': {
      backdropFilter: 'blur(5px)',
    },
  },
  button: {
    width: 218,
    height: 40,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#ffffff',
    background: '#423BC7',
    marginTop: theme.spacing(2),
  },
  closedReasonMessage: {
    paddingTop: theme.spacing(1),
  }
})

export class Chat extends CommonChat {
  constructor (props) {
    super(props)

    this.toItemElement = this.toItemElement.bind(this)
    this.handleCancelFriend = this.handleCancelFriend.bind(this)
    this.toAIRoom = this.toAIRoom.bind(this)
    this.confirmOk = this.confirmOk.bind(this)
    this.confirmCansel = this.confirmCansel.bind(this)
    this.confirmNewFeature = this.confirmNewFeature.bind(this)
    this.closeNewFeature = this.closeNewFeature.bind(this)

    this.cable = null
    this.channel = null

    this.receivedMessages = []
    this.firstMessage = null
    this.lastMessage = null
    this.canFetchPastMessages = true
    this.noUpdateLastMessageId = false

    this._isMounted = false

    this.state = {
      scrollBottom: true,
      user: {},
      friend: null,
      room: null,
      displayItems: [],
      warningTitle: '',
      warningText: '',
      openNaviIntroDialog: false,
      openWarningDialog: false,
      openMessageMenuDialog: false,
      selectedChatId: 'false',
      openMessageDeleteConfirmDialog: false,
      targetAnchorEl: null,
      menuTargetMessage: {},
      visibleMenu: true,
      chatInputHeight: 56,
      openConfirmDialog: false,
      confirmMessage: '',
      chat_room_id: null,
      openSecretRoom: false,
      secretMessage: null,
      openSecretTutorial: false,
      memoOpen: false,
      memoText: '',
      aiFriend: null,
      openTalkNavi01: false,
      openTalkNavi01TargetEl: null,
      openTalkNavi02: false,
      openTalkNavi02TargetEl: null,
      openTalkNavi03: false,
      openTalkNavi03TargetEl: null,
      openAITalkNavi01: false,
      openAITalkNavi01TargetEl: null,
      openAITalkNavi02: false,
      openAITalkNavi02TargetEl: null,
      openAITalkNavi03: false,
      openAITalkNavi03TargetEl: null,
      openAITalkNavi04: false,
      openAITalkNavi04TargetEl: null,
      openFavNavi01: false,
      openFavNavi01TargetEl: null,
      openFavNavi02: false,
      openFavNavi02TargetEl: null,
      openUpdateTextConfirmDialog: false,
      templateMessage: Storage.getTemplateMessage(),
      openNewFeatureDialog: false,
      newFeatureDialogId: null,
    }
  }

  async componentWillReceiveProps (nextProps) {
    if (!isNullOrUndefined(nextProps.friend) && !isNullOrUndefined(this.state.friend)) {
      if (nextProps.friend.chat_room_id !== this.state.friend.chat_room_id) {
        this.props.setLoading(true)
        try {
          await this.patchLastMessageId()
          this.closeChat()
          this.setStateIfMounted({
            friend: nextProps.friend,
            chat_room_id: nextProps.friend.chat_room_id
          })

          const room = await this.initChat(nextProps.friend.chat_room_id)
          this.setState({ room })
          let secretMessage = []
          if (!isNullOrUndefined(this.props.newMessage)) {
            secretMessage = await this.getSecretMessage(nextProps.friend, this.props.newMessage)
            if (secretMessage?.length === 1) {
              this.setStateIfMounted({
                openSecretRoom: true,
                secretMessage: secretMessage[0],
              })
            }
            this.props.setNewMessage(null)
          }
        } catch (error) {
          this.handleApiError(error)
        } finally {
          this.props.setLoading(false)
        }
      } else {
        if (!isNullOrUndefined(this.props.newMessage)) {
          if (this.state.openSecretRoom) {
            if (this.props.newMessage.chat_room_id !== this.state.secretMessage.chat_room_id) {
              let secretMessage = await this.getSecretMessage(nextProps.friend, this.props.newMessage)
              if (secretMessage?.length === 1) {
                this.setStateIfMounted({ secretMessage: secretMessage[0] })
              }
            }
          } else {
            if (this.props.newMessage.chat_room_id !== this.state.chat_room_id) {
              let secretMessage = await this.getSecretMessage(nextProps.friend, this.props.newMessage)
              if (secretMessage?.length === 1) {
                this.setStateIfMounted({
                  openSecretRoom: true,
                  secretMessage: secretMessage[0],
                })
              }
            }
          }
          this.props.setNewMessage(null)
        }
      }
    }
  }

  async loadFriendDetail () {
    let friend = this.props.friend
    try {
      if (!friend) {
        friend = await this.loadCurrentFriendDetail()
        if (!friend) return null
      }
      this.setStateIfMounted({
        friend: friend,
        chat_room_id: friend.chat_room_id
      })
      return friend
    } catch (error) {
      if (!friend && httpNotFound(error)) {
        const strageFriend = Storage.getFriend()
        const nickName = `${strageFriend.nick_name}さんから`
        const msg = `${nickName || ''}友達解消されています。`
        this.showErrorMessage(msg, () => this.props.setScreen('Friends'))
      } else {
        this.handleApiError(error)
      }
    }
  }

  async loadNewFeatureDialog () {
    if (this.props.tutorialView) { return }
    if (this.state.friend?.type === 'ai') { return }
    const ids = [
      'ScheduledChat',
    ]
    for (const id of ids) {
      const feature = Features[id]
      // リリースがユーザーの登録日以前の機能は新規機能として表示しない
      if (new Date(feature.releaseDate) < new Date(this.props.user.created_at)) { continue }
      const dialogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(this.props, `NewFeature.${id}`)
      if (dialogDisyplayed) { continue }
      this.setStateIfMounted({
        openNewFeatureDialog: true,
        newFeatureDialogId: id,
      })

      break
    }
  }

  async componentDidMount () {
    this._isMounted = true
    this.props.setLoading(true)
    let friendDetail = null
    try {
      friendDetail = await this.loadFriendDetail()
      if (!friendDetail) {
        this.props.setScreen('Friends')
        return
      }
      const room = await this.initChat(friendDetail.chat_room_id)
      this.setState({ room })
      this.preChat() // NOTE: room情報の取得後に実行しないとphotoの描画に失敗する場合がある
      this.scrollToBottom()
      this.addVisitPageLog({ friend_user_id: friendDetail.id })
      this.patchLastMessageId()

      // 過去メッセージ取得のトリガーとしてYスクロールを監視
      let chatScroll = this.getMessagesContainer()
      chatScroll.on('scroll', _.throttle(this.handleScroll, 300))

      // 狛犬トークルーム取得
      this.setAiFriendRoom()
      this.loadNewFeatureDialog()
    } catch (error) {
      if (!friendDetail && httpNotFound(error)) {
        this.showErrorMessage(
          `${this.props.friend.nick_name}さんから友達解消されています。`,
          () => this.props.setScreen('Friends')
        )
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  async componentWillUnmount () {
    this._isMounted = false
    if (!this.state.chat_room_id) {
      this.props.setNewMessage(null)
      this.closeChat()
      return
    }
    try {
      await this.patchLastMessageId()
      await this.loadChatRoomAndUpdateChatRooms(this.state.chat_room_id)
      if (this.state.friend && this.state.friend.type !== 'ai') {
        await this.loadFriendAndUpdateFriends(this.state.friend.id)
      }
      this.props.setNewMessage(null)
      this.closeChat()
    } catch (error) {
      if (httpNotFound(error)) {
        // noop
      } else {
        this.handleApiError(error)
      }
    }
  }

  closeChat () {
    if (this.state.friend) {
      this.addLeavePageLog({ friend_user_id: this.state.friend.id })
    }

    if (this.channel) {
      this.channel.unsubscribe()
      this.channel = null
    }
    if (this.cable) {
      this.cable.disconnect()
      this.cable = null
    }

    $(window).off('scroll')
  }

  async handleCancelFriend () {
    this.props.setLoading(true)
    try {
      await this.loadFriends()
      this.noUpdateLastMessageId = true
      this.props.setScreen('Friends')
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  // 未回答アンケートチェック
  existNotAnsweredQuestion () {
    return this.receivedMessages.some(msg => {
      return (this.isNotAnsweredQuestion(msg) || this.isNotAnsweredConfirm(msg)) && !isSecretQuestion(msg)
    })
  }

  toItemElement (item, key) {
    if (item.getTime) {
      return this.createDateElement(item, key)
    }
    else if (item.navi_type === 'angel' || item.navi_type === 'devil') {
      return this.createNaviMessageElement(item, key)
    } else {
      if (item.user_id === this.props.userId) {
        // 自分のメッセージ
        return this.createMyMessageElement(item, key)
      } else {
        // 相手のメッセージ
        return this.createFriendMessageElement(item, key)
      }
    }
  }

  // 秘密の部屋メッセージ取得
  async getSecretMessage (friend, msg) {
    try {
      let secretMessages = await Api.getSecretRooms(friend.chat_room_id)
      if (!secretMessages) return []
      if (secretMessages.length === 0) return []

      let secretMessage = secretMessages.filter(message => {
        return message.secret_room_id === msg.chat_room_id
      })
      if (!secretMessage) return []
      if (!secretMessage.length === 1) return []

      return secretMessage
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async handleCloseSecretRoom (msg) {
    this.props.setLoading(true)
    try {
      let friend = await this.loadFriendAndUpdateFriends(this.state.friend.id)
      this.props.setFriend(friend)
      this.setStateIfMounted({ friend: friend })
      let updateMsg = await Api.getChatMessage(this.state.chat_room_id, msg.id)
      if (!isNullOrUndefined(updateMsg)) {
        this.updateMessage(updateMsg)
      }
    } catch (error) {
      if (httpNotFound(error)) {
        // noop
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
    this.setStateIfMounted({
      openSecretRoom: false,
      secretMessage: null,
    })
  }

  async setAiFriendRoom () {
    const ai_chat_room_id = this.props.user?.ai_chat_room_id
    if (!ai_chat_room_id) { return }
    const aiFriend = await BaseComponent.getAiFriend(this.props, ai_chat_room_id)
    if (aiFriend) { this.setStateIfMounted({ aiFriend: aiFriend }) }
  }

  checkIsNavigation () {
    return (this.state.openTalkNavi01 || this.state.openTalkNavi02 || this.state.openTalkNavi03) ? true : false
  }

  toAIRoom () {
    if (!this.state.aiFriend) { return }
    this.props.setFriend(this.state.aiFriend)
    if (!this.props.tutorialReadStates.includes('talk_room_ai')) {
      this.props.setTutorialView('talk-room-ai-01')
    }
    this.props.setScreen('Chat')
  }

  isEnabledChatInput = () => {
    const { isScheduledChatEditMode } = this.props
    if (isScheduledChatEditMode) { return false }
    const { friend } = this.props
    const { room } = this.state
    if (!room) { return false }
    if (room.closed === true) { return false }
    if (friend?.type === 'ai') { return false }
    if (this.checkIsNavigation()) { return false }
    if (this.state.openSecretRoom) { return false }
    return true
  }

  isEnabledMenu = () => {
    const { friend } = this.props
    const { room } = this.state
    if (!room) { return false }
    if (room.closed === true) { return false }
    if (friend?.type === 'ai') { return false }
    return true
  }

  async confirmOk () {
    const text = this.state.templateText
    await this.setState({ chatInputValue: text })
    Storage.setChatMessage(this.state.friend.id, text)
    this.changeInputRows()
    this.setState({ openUpdateTextConfirmDialog: false, templateText: null })
  }

  confirmCansel () {
    this.setState({ openUpdateTextConfirmDialog: false, templateText: null })
  }

  async closeNewFeature (id) {
    await BaseComponent.createReadStateDialogDisplayed(this.props, `NewFeature.${id}`)
    this.setState({
      openNewFeatureDialog: false,
      newFeatureDialogId: null
    })
  }

  async confirmNewFeature (id) {
    await this.closeNewFeature(id)
  }

  render () {
    if (isNullOrUndefined(this.state.friend)) { return <div /> }

    const { classes, isScheduledChatEditMode, friend, ...other } = this.props
    const { room } = this.state
    const chatInputEnabled = this.isEnabledChatInput()
    return (
      <React.Fragment>
        { this.createNavi() }
        { this.createMessageMenuDialog() }
        { this.createMessageDeleteConfirmDialog() }
        { this.createWarningDialog() }
        { this.createConfirmDialog() }
        { this.createSecretTutorial() }
        { this.createChatMemo() }
        {!isScheduledChatEditMode && <AppHeader
          {...this.props}
          title={this.state.friend.nick_name}
          toggleMenu={this.isEnabledMenu()}
          user={this.props.user}
          target={this.state.friend}
          hideBack={!this.state.visibleMenu}
          showFriendIcon={true}
          onBack={() => this.props.setScreen('Friends')}
          backWhite={this.state.visibleMenu}
          showMypage={!this.state.visibleMenu}
          isLover={!this.state.visibleMenu}
          onCancelFriend={this.handleCancelFriend}
          onChatMemo={this.handleChatMemo}
        />}
        {this.state.openSecretRoom && (
          <SecretQuestionRoom
            {...other}
            message={this.state.secretMessage}
            friend={this.state.friend}
            chat_room_id={this.state.secretMessage?.secret_room_id}
            open={this.state.openSecretRoom}
            onClose={() => this.handleCloseSecretRoom(this.state.secretMessage)}
          />
        )}
        <div
          className={classes.root}
          style={{
            position: isScheduledChatEditMode && 'static',
            pointerEvents: isScheduledChatEditMode && 'none',
            backgroundColor: friend?.type === 'ai' ? '#EFF0FF' : '#ffffff',
          }}
        >
          <div
            id="chat-scroll"
            className={classes.scroll}
            style={{
              bottom: chatInputEnabled ? this.state.chatInputHeight : 0,
              paddingBottom: chatInputEnabled ? 8 : 16,
            }}
          >
            {this.state.displayItems.map(this.toItemElement)}
            {room?.closed && (
              <NaviSpeech
                {...other}
                naviType="devil"
                message={{
                  id: 'closed-message',
                  message_type: 'closed',
                  message: `${this.state.friend.nick_name}さんとのトークルームを終了する？\n
                  終了するとトーク可能枠が1つ回復するよ。
                  終了するを押さない場合も、24時間後に自動で終了するよ。`
                }}
                secret={true}
                friend={this.state.friend}
                onConfirmSend={() =>null}
                onSecretRoom={() => null}
                onMemoOpen={() => null}
              />
            )}
          </div>
          {this.isEnabledChatInput() && (
            <>
              <ChatInput
                {...other}
                inputId="message"
                onSend={this.sendMessage}
                onSendScheduledMessage={this.sendScheduledMessage}
                friend={this.state.friend}
                setLoading={this.props.setLoading}
                replyToMessage={Storage.getReplyMessage(this.state.friend.chat_room_id)}
                templateMessage={this.state.templateMessage}
                changeInputRows={this.changeInputRows}
                chatRoomId={this.state.friend.chat_room_id}
              />
            </>
          )}
        </div>
        {/* フレンドトークルームナビ */}
        <div id="talk-room-tutorial-target" style={{ position: 'fixed', top: '25%', left: '50%' }} />
        <NaviPopover
          {...this.props}
          id="talk-room-01"
          open={this.props.tutorialView === 'talk-room-01' && this.state.friend?.type && this.state.friend.type !== 'ai'}
          anchorEl={document.getElementById('talk-room-tutorial-target')}
          title={'会話ナビについて'}
          text={'1. ここぞ！というポイントで\n「カグラ」と「サカキ」が出現！\n\n2. 2人同時に見えるメッセージと\n1人（自分自身）のみ見えるメッセージの2パターンによって会話をアシスト'}
          img={FirstTalkNaviImg01}
          position={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
          okButton
        />
        <NaviPopover
          {...this.props}
          id="talk-room-02"
          open={this.props.tutorialView === 'talk-room-02' && this.state.friend?.type && this.state.friend.type !== 'ai'}
          anchorEl={getFriendTopicSuggestion()}
          title={'初回のオススメ話題だよ！'}
          text={'活用すると、\nトークが続きやすいよ。'}
          img={FirstTalkNaviImg02}
          position={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
          okButton
          arrow
        />
        <NaviPopover
          {...this.props}
          id="talk-room-03"
          open={this.props.tutorialView === 'talk-room-03' && this.state.friend?.type && this.state.friend.type !== 'ai'}
          anchorEl={document.getElementById('talk-room-tutorial-target')}
          title={'狛犬お役立ちポイントを\n確認しよう！'}
          img={FirstTalkNaviImg03}
          child={
            <Button className={classes.button} variant="contained" onClick={this.toAIRoom}>
              狛犬トークルームへ
            </Button>
          }
          position={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
          canselButton
        />
        {/* AIトークルームナビ */}
        <NaviPopover
          {...this.props}
          id="talk-room-ai-01"
          open={this.props.tutorialView === 'talk-room-ai-01'}
          anchorEl={document.getElementById('talk-room-tutorial-target')}
          title={'AIキャラクターが\nあなたの恋を応援！'}
          text={'神楽（カグラ）と榊（サカキ）\n狛犬をよろしくね！'}
          img={FirstTalkNaviImg04}
          position={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
          okButton
        />
        <NaviPopover
          {...this.props}
          id="talk-room-ai-02"
          open={this.props.tutorialView === 'talk-room-ai-02'}
          anchorEl={document.getElementById('advice-01-1-3')}
          title={'成功ポイントを\nアドバイスするよ！'}
          text={'狛犬を活用すると進展確率UP！'}
          position={'top'}
          onClose={() => this.props.setTutorialView(null)}
          okButton
          arrow
        />
        <NaviPopover
          {...this.props}
          id="talk-room-ai-03"
          open={this.props.tutorialView === 'talk-room-ai-03'}
          anchorEl={document.getElementById('advice-01-1-5')}
          title={'トークアシストで\n会話が盛り上がる！'}
          text={'2人の状況に合わせて、\nアシストが進化。'}
          position={'top'}
          onClose={() => this.props.setTutorialView(null)}
          okButton
          arrow
        />
        <NaviPopper
          {...this.props}
          id="talk-room-ai-04"
          open={this.props.tutorialView === 'talk-room-ai-04'}
          anchorEl={document.getElementById('advice-01-1-5')}
          title={'さっそく狛犬のアシストを\n見てみよう！'}
          placement={'top'}
          onClose={() => this.props.setTutorialView(null)}
          arrow
          canselButton
        />
        {/* 好感度ナビ */}
        <div id="fav-update-tutorial-01-target" style={{ position: 'fixed', top: '8%', left: '50%' }} />
        <NaviPopper
          {...this.props}
          id="fav-update-01"
          open={this.props.tutorialView === 'fav-update-01' && this.state.friend?.type && this.state.friend.type !== 'ai'}
          anchorEl={document.getElementById('fav-update-tutorial-01-target')}
          title={'タップしよう！'}
          text={'トークメニュー内に\n好感度登録画面があるよ'}
          placement={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
        />
        <div id="fav-update-tutorial-02-target" style={{ position: 'fixed', top: '25%', left: '50%' }} />
        <NaviPopper
          {...this.props}
          id="fav-update-02"
          open={this.props.tutorialView === 'fav-update-02' && this.state.friend?.type && this.state.friend.type !== 'ai'}
          anchorEl={document.getElementById('fav-update-tutorial-02-target')}
          title={'タップしよう！'}
          text={'あなたのお相手に対する\n好感度を登録できるよ'}
          img={firstFavNaviImg03}
          placement={'bottom'}
          onClose={() => this.props.setTutorialView(null)}
        />
        <NewFeatureDialog
          open={this.state.openNewFeatureDialog}
          id={this.state.newFeatureDialogId}
          onConfirm={this.confirmNewFeature}
          onClose={this.closeNewFeature}
        />
      </React.Fragment>
    )
  }
}

Chat.propTypes = {
  classes: PropTypes.object.isRequired,
  isScheduledChatEditMode: PropTypes.bool,
}

export default withStyles(styles)(Chat)