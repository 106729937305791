import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Api from 'commons/api'
import Config from 'commons/config'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import TrialPlanBox from 'components/parts/TrialPlanBox'
import Komainu from 'images/Komainu/firstNaviKomainu.png'
import SpecialFreePlanImg from 'images/specialFreePlan.png'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  content: {
    '& p': {
      textAlign: 'left',
    },
    '& ul': {
      listStyle: '"＊"',
      padding: theme.spacing(1.5),
      margin: 0,
    },
  },
  komainu: {
    width: 170,
    height: 67,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
    objectFit: 'none',
    objectPosition: 'center top',
  },
}))

export default function FreePlanSuggestDialog (props) {
  const { open, onClose, subscription, user } = props
  const classes = useStyles()
  const userNickName = user ? user.nick_name : ''
  const [myPref, setMyPref] = useState(null)

  useEffect(() => {
    if (!open) { return }
    (async () => {
      BaseComponent.gaModalView('freeplansuggest')
      try {
        const res = await Api.getMyPref()
        setMyPref(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [open])

  if (!subscription) { return null }
  if (!myPref) { return null }

  const createText = () => {
    if (!subscription) { return null }
    if (subscription.content === Const.planTypes.STANDARD_INITIAL) {
      const endDate = addDate(new Date(subscription.next_billing_date), -1)
      return `ご入会後の無料トライアル期間は${dateFormat(endDate, 'yyyy年m月d日')}までになります。`
    }
    return `スタンダードプランの期間は${dateFormat(subscription.next_billing_date, 'yyyy年m月d日')}までになります。`
  }

  const freePlanName = () => {
    if (myPref.special_free_plan_target) {
      return '特別フリープラン'
    }
    return 'フリープラン'
  }

  const freePlanImg = () => {
    if (myPref.special_free_plan_target) {
      return (
        <>
          <br />
          <img className={classes.img} src={SpecialFreePlanImg} width={'100%'} />
        </>
      )
    }
    return (
      <>
        <img className={classes.komainu} src={Komainu} alt="" />
        <TrialPlanBox />
      </>
    )
  }

  const handleClose = () => {
    if (Config.deployMode === 'app') {
      const id = `Info.FreePlanSuggest_${subscription.next_billing_date}`
      onClose(id)
    } else {
      onClose()
    }
  }

  const openMembersSite = () => {
    window.open(Config.membersSiteUrl)
    handleClose()
  }

  return (
    <>
      <DialogThemeProvider color="primary">
        <Dialog open={open}>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogTitle disableTypography>
            {freePlanName()}のご案内
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography variant="body1">
              {createText()}<br />
              <br />
              {userNickName}様のご登録条件（エリアや年齢等）により、ご紹介できる異性の人数が少ない可能性がございます。<br />
              <br />
              無料でご利用いただける「{freePlanName()}」もございますので、ぜひご活用ください。<br />
              <br />
              {freePlanName()}中、任意のタイミングでスタンダードプランを選択することも可能です。<br />
              <br />
              より多くのエリアや年齢層の方々にもご満足いただけるサービスとなるよう、Aill一同取り組んで参ります。
            </Typography>
            {freePlanImg()}
            <Typography variant="body1">
              <ul>
                <li>{freePlanName()}への変更は、メンバーズサイトから可能です。</li>
                <li>ご変更がない場合、スタンダードプラン（有料）が継続となります。</li>
              </ul>
            </Typography>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={openMembersSite}>
              メンバーズサイトを開く
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

FreePlanSuggestDialog.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}