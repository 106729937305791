import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#707070',
    paddingBottom: theme.spacing(5),
  },
}))

export default function MatchingFilterDialog (props) {
  const { open, title, children, disabledSave, onSave, onCancel } = props
  const classes = useStyles()
  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
            {children}
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onSave} disabled={disabledSave}>
            保存する
          </Button>
          <Button variant="text" onClick={onCancel}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

MatchingFilterDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.object,
  disabledSave: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}