import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import FriendInfo from 'components/template-dialog/FriendInfo'
import SelectBox from 'components/template-dialog/SelectBox'
import AddButton from 'images/img_add_button.png'

const useStyles = makeStyles(theme => ({
  progressBar: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.375),
    height: '9px !important',
    borderRadius: 5,
    [`& .${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDCFE'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#423BC7'
    },
  },
  selectBox: {
    '& div.MuiSelect-select': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
      fontSize: 14,
    },
    '& svg.MuiSelect-icon': {
      color: '#423BC7',
      width: 15,
    }
  },
  menuItem: {
    fontSize: '14px !important',
  },
  box: {
    margin: theme.spacing(1.5)
  },
  addButton: {
    width: 'fit-content'
  },
}))

const MIN_COUNT = (type) => {
  if (type === 'self-introduction') { return 2 }
  if (type === 'to-message') { return 1 }
  return 0
}

const MAX_COUNT = (type) => {
  if (type === 'self-introduction') { return 3 }
  if (type === 'to-message') { return 1 }
  return 0
}

export default function SelectCategory (props) {
  const {
    type, selectedItems, onChangeTitle,
    onNext, onPrev, onClose, template, friend, master, isChubuTokai,
  } = props
  const [visibleCount, setVisibleCount] = useState(MIN_COUNT(type))
  const [nextButtonDisable, setNextButtonDisable] = useState(false)

  // 「内容」に値が入るたびに次へボタンのdisable制御処理
  useEffect(()=> {
    const items = selectedItems.filter(item => !item.isEmpty())
    setNextButtonDisable(items.length < MIN_COUNT(type))
  }, [selectedItems])

  const classes = useStyles()

  const createContent = () => {
    const elements = []
    const visibleItems = selectedItems.slice(0, visibleCount)
    for (const i in visibleItems) {
      elements.push(
        <SelectBox
          key={i}
          index={Number(i)}
          selectedItems={selectedItems}
          onChange={onChangeTitle}
          template={template}
          disabled={isChubuTokai && Number(i) === 0}
        />
      )
    }
    // カテゴリは3つまで
    if (visibleCount < MAX_COUNT(type)) {
      // プラスボタン追加
      elements.push(
        <Button
          key='add-button'
          className={classes.addButton}
          disableRipple
          onClick={() => setVisibleCount(visibleCount + 1)}
        >
          <img src={AddButton} />
        </Button>
      )
    }
    return elements
  }

  const next = () => {
    // 2個以下でエラー
    const items = selectedItems.filter(item => !item.isEmpty())
    if (items.length < MIN_COUNT(type)) { return }

    // 重複項目があればエラー
    const titles = items.map(i => i.title)
    const itemlistCheck = Array.from(new Set(titles))
    if (itemlistCheck.length !== titles.length) { return }

    // 次のページへ
    onNext && onNext()
  }

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        {type === 'self-introduction' ? '自己紹介文を作成' : '一言メッセージを作成'}
        <LinearProgress value={20} variant="determinate" className={classes.progressBar} />
        <Typography>
          記載したい「カテゴリ」と「内容」を<br />
          選んでください。<br />
          {MIN_COUNT(type) !== MAX_COUNT(type) && <>※2~3テーマ選択してください。<br /></>}
          ※文章はあとで編集可能です。
        </Typography>
      </DialogTitle>
      <DialogContent>
        {createContent()}
        <FriendInfo friend={friend} master={master} category={selectedItems[0].category} />
      </DialogContent>
      <DialogActions disableSpacing>
        <Button variant="contained" onClick={next} disabled={nextButtonDisable}>次へ</Button>
        {onPrev && <Button onClick={onPrev}>戻る</Button>}
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </React.Fragment>
  )
}
SelectCategory.propTypes = {
  type: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onChangeTitle: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onClose: PropTypes.func,
  template: PropTypes.object,
  friend: PropTypes.object,
  master: PropTypes.object,
  isChubuTokai: PropTypes.bool,
}
