import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 59.5,
    borderTop: '1px solid #00000029',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

export default function MatchingFilterItemContainer (props) {
  const classes = useStyles()
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      {props.children}
    </Grid>
  )
}

MatchingFilterItemContainer.propTypes = {
  children: PropTypes.object,
}