import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addSeconds } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import Benefit from 'images/first-approve/benefit.png'
import BGImg from 'images/first-approve/bg_full.png'
import Infografic from 'images/first-approve/infografic.png'
import Komainu from 'images/first-approve/koma_bubble_speech.png'
import People1 from 'images/first-approve/people_1.png'
import People3 from 'images/first-approve/people_3.png'
import Title from 'images/first-approve/title.png'

const useStyles = makeStyles(theme => ({
  content: {
    width: 328,
    backgroundColor: '#E8E1FF',
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    position: 'relative',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  box: {
    width: 267,
    height: 180,
    background: '#ffffff',
    borderRadius: 8,
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    '& p:nth-of-type(1)': {
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
    '& p:nth-of-type(2)': {
      fontSize: 11,
      lineHeight: 1,
      marginTop: theme.spacing(1),
    },
  },
  term: {
    width: 328,
    height: 34,
    background: '#A981F8',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 14,
      fontWeight :600,
      lineHeight: 1,
      letterSpacing: '1.4px',
      color: '#ffffff',
    },
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
  missionBox: {
    width: 267,
    height: 114,
    background: '#ffffff',
    borderRadius: 8,
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
    '& p': {
      fontWeight :600,
      lineHeight: 1,
    },
    '& p:nth-of-type(1)': {
      fontSize: 14,
      marginBottom: theme.spacing(1.25),
    },
    '& p:nth-of-type(2)': {
      fontSize: 24,
      marginBottom: theme.spacing(1),
    },
    '& img': {
      marginRight: 6.5,
      marginLeft: 6.5,
    },
  },
  achieved: {
    width: '100%',
    height: '100%',
    background: 'rgba(236, 226, 255, 0.8)', // #FEFFB2
    borderRadius: 12,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'absolute',
    top: 0,
    zIndex: 1,
    '& p': {
      fontWeight :600,
      lineHeight: 1,
    },
    '& p:nth-of-type(1)': {
      fontSize: 18,
      width: 64,
      height: 64,
      border: '3px solid #7A44E6',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    '& p:nth-of-type(2)': {
      fontSize: 11,

    },
  },
}))
export default function FirstApproveProgressDialog (props) {
  const { open, onClose, mission } = props
  const classes = useStyles()

  if (!mission) { return null }
  if (!mission?.end_at) { return null }

  const endAt = new Date(mission.end_at)

  const createAchievedFilter = () => (
    <Grid className={classes.achieved}>
      <Typography>達成!</Typography>
      <Typography>＊{dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} に追加紹介</Typography>
    </Grid>
  )

  const createMissionBox = (num, ) => {
    const achieved = num <= mission.progress
    return (
      <div className={classes.missionBox}>
        <Typography>「いいね！」返信 {num}回目</Typography>
        <Typography>+3人紹介</Typography>
        <Grid container justifyContent="center" alignItems="center">
          <img src={People1} width={22.34} />
          <img src={People1} width={22.34} />
          <img src={People1} width={22.34} />
        </Grid>
        {achieved && createAchievedFilter()}
      </div>
    )
  }

  return (
    <DialogThemeProvider color="first-approve">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogContent className={classes.content}>
          <img src={Title} width={312} style={{ marginBottom: 21 }} />
          <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
            <img src={People3} width={67.15} style={{ margin: 0, position: 'relative', top: 8, right: 4 }} />
            <img src={Benefit} width={154} style={{ margin: 0 }} />
            <img src={People3} width={67.15} style={{ margin: 0, position: 'relative', top: 8, left: 4 }} />
          </Grid>
          <div className={classes.box}>
            <Typography>「いいね！」返信1回で<br />+3人紹介！</Typography>
            <img src={Infografic} width={184} />
            <Typography>＊特典対象となる返信は最大3人まで</Typography>
          </div>
          <Grid className={classes.term} container justifyContent="center" alignItems="center">
            <Typography>{dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} まで</Typography>
          </Grid>
          <Typography className={classes.text}>チャレンジ</Typography>
          {createMissionBox(1)}
          {createMissionBox(2)}
          <img src={Komainu} width={232} />
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FirstApproveProgressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  mission: PropTypes.object,
}
