import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate, addSeconds } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import Benefit from 'images/free-term-extension-challenge/benefit.png'
import BGImg from 'images/free-term-extension-challenge/bg_challenge.png'
import FlagLeft from 'images/free-term-extension-challenge/flag_left.png'
import FlagRight from 'images/free-term-extension-challenge/flag_right.png'
import KomaSpeech from 'images/free-term-extension-challenge/koma_speech.png'
import SerifLeft from 'images/free-term-extension-challenge/serif_left.png'
import SerifRight from 'images/free-term-extension-challenge/serif_right.png'

const useStyles = makeStyles(theme => ({
  content: {
    width: 289,
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '& img': {
      maxWidth: 'unset',
      display: 'block',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '27px',
    margin: 0,
  },
  term: {
    width: 192,
    height: 25,
    background: '#ffffff',
    margin: 'auto',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(5.5),
    '& p': {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1,
      color: '#FF7240',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    '& p': {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1,
      color: '#ffffff',
      margin: 0,
    },
    '& img': {
      margin: 0,
      position: 'relative',
      top: -8,
    },
  },
  challenge: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  missionBox: {
    width: 216,
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
  },
  achievedFilter: {
    width: '100%',
    height: '100%',
    background: 'rgba(254, 255, 178, 0.9)', // #FEFFB2
    borderRadius: 12,
    position: 'absolute',
    zIndex: 1,
  },
  achievedText: {
    width: 64,
    height: 64,
    fontSize: 18,
    fontWeight: 600,
    color: '#FC7C4E',
    border: '3px solid #FC7C4E',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  missionText:{
    fontSize: 14,
    fontWeight: 600,
    color: '#FF7240',
    marginBottom: theme.spacing(1.5),
  },
  missionStep: {
    width: 32 ,
    height: 32,
    fontSize: 8,
    fontWeight: 600,
    color: '#707070',
    border: '1px dashed #656565',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  achievedStep: {
    width: 32,
    height: 32,
    fontSize: 9,
    fontWeight: 600,
    color: '#ffffff',
    background: '#FF7240',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(-20deg)',
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  note: {
    marginTop: theme.spacing(1),
  },
  detail: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  detailBox: {
    width: 216,
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  detailTitle: {
    color: '#FF7240',
    textAlign: 'left',
    margin: 0,
  },
  detailText: {
    fontWeight: 300,
    color: '#FF7240',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  achievedNote: {
    fontSize: 12,
    fontWeight: 300,
    marginTop: theme.spacing(1.5),
    marginBottom: 0,
  },
}))

export default function FreeTermExtensionChallengeDialog (props) {
  const { open, onClose, friendApplyMission, chatOpenMission } = props
  const { matchingState } = props
  const classes = useStyles()

  if (!friendApplyMission) { return null }
  if (!chatOpenMission) { return null }
  if (!matchingState) { return null }
  if (!matchingState.first_boost_end_at) { return null }

  // 期間
  const startAt = new Date(matchingState.first_boost_end_at)
  const endAt = new Date(friendApplyMission.end_at)
  // 進捗
  const friendApplyProgress = friendApplyMission.progress
  const chatOpenProgress = chatOpenMission.progress

  const createAchievedFilter = () => (
    <Grid className={classes.achievedFilter} container justifyContent="center" alignItems="center">
      <Typography className={classes.achievedText}>達成!</Typography>
    </Grid>
  )

  const createMissionBox = (type, achievedStep = 0) => {
    if (!(type === 'friendApply' || type === 'chatOpen')) { return }
    const missionLength = type === 'friendApply' ? 5 : 2
    const text = type === 'friendApply' ? '5人に「いいね！」送信で\n無料期間が7日延長！' : '2人とトーク開通*で\n無料期間が7日延長！'
    const IntArray = [...Array(missionLength)].map((_, i) => i + 1)
    return (
      <Grid className={classes.missionBox} container justifyContent="center" alignItems="center">
        {missionLength === achievedStep && createAchievedFilter()}
        <Typography className={classes.missionText}>{text}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          {IntArray.map(num => (
            <Typography key={num} className={`${num <= achievedStep ? classes.achievedStep : classes.missionStep}`}>
              {num <= achievedStep ? '達成!' : <><span style={{ fontSize: 11 }}>{num}</span>人目</>}
            </Typography>
          ))}
        </Grid>
      </Grid>
    )
  }

  const handleClose = () => {
    if (!onClose) { return }
    const totalProgress = friendApplyMission.progress + chatOpenMission.progress
    onClose(totalProgress)
  }

  const brSp = <><br />&nbsp;&nbsp;&nbsp;&nbsp;</>
  return (
    <DialogThemeProvider color="free-term-extension-challenge">
      <Dialog open={open}>
        <DialogCloseButton onClick={handleClose} />
        <DialogContent className={classes.content}>
          <Grid container justifyContent="center" alignItems="center">
            <img src={FlagLeft} width={29.37} style={{ marginRight: 8 }} />
            <Typography className={classes.title}>
              無料トライアル期間<br />
              延長チャレンジ
            </Typography>
            <img src={FlagRight} width={29.37} style={{ marginLeft: 8 }}/>
          </Grid>
          <Grid className={classes.term} container justifyContent="center" alignItems="center">
            <Typography>
              {dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} まで
            </Typography>
          </Grid>
          <Grid className={classes.subtitle} container justifyContent="center" alignItems="center">
            <img src={SerifLeft} width={23.32} />
            <Typography>2つのチャレンジ達成で</Typography>
            <img src={SerifRight} width={23.32} />
          </Grid>
          <img src={Benefit} width={233.16} style={{ marginBottom: 23 }} />
          <img src={KomaSpeech} width={158} style={{ marginBottom: 23 }} />
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ1</Typography>
          {createMissionBox('friendApply', friendApplyProgress)}
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ2</Typography>
          {createMissionBox('chatOpen', chatOpenProgress)}
          <Typography className={classes.note} variant="body2">
            *いいね！送信・いいね！返信どちらも対象
          </Typography>
          <Typography className={classes.detail} variant="subtitle1">
            チャレンジ詳細
          </Typography>
          <div className={classes.detailBox}>
            <Typography className={classes.detailTitle} variant="body1">対象期間</Typography>
            <Typography className={classes.detailText} variant="body1">
              {dateFormat(startAt, 'yyyy/mm/dd')} ~ {dateFormat(addDate(endAt, -1), 'yyyy/mm/dd')}
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">チャレンジ内容</Typography>
            <Typography className={classes.detailText} variant="body1">
              1.&nbsp;対象期間内に5人以上に{brSp}「いいね！」を送信
            </Typography>
            <Typography className={classes.detailText} variant="body1">
              2.&nbsp;対象期間内に2人以上と{brSp}トーク開通
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">特典内容</Typography>
            <Typography className={classes.detailText} variant="body1">
              チャレンジの達成状況に合わせ、A・Bどちらかの特典が付与されます。
            </Typography>
            <Typography className={classes.detailText} variant="body1">
              A.&nbsp;チャレンジ1・2の{brSp}どちらか達成の場合、{brSp}無料トライアル期間が{brSp}7日間延長
            </Typography>
            <Typography className={classes.detailText} variant="body1">
              B.&nbsp;チャレンジ1・2を{brSp}どちらも達成の場合、{brSp}無料トライアル期間が{brSp}14日間延長
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">特典適用時期</Typography>
            <Typography className={classes.detailText} variant="body1">
              無料トライアル期間終了後から
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  friendApplyMission: PropTypes.object,
  chatOpenMission: PropTypes.object,
  matchingState: PropTypes.object,
}
