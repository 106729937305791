import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FreeTermExtensionChallengeAchievedDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeAchievedDialog'
import FreeTermExtensionChallengeDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeDialog'
import BGImg from 'images/free-term-extension-challenge/bg_banner.png'
import FlagLeft from 'images/free-term-extension-challenge/flag_left.png'
import FlagRight from 'images/free-term-extension-challenge/flag_right.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    fontWeight: 600,
    color: '#ffffff',
    background: '#FC7C4E',
    paddingTop: theme.spacing(2.5),
    position: 'relative',
    boxSizing: 'border-box',
    backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  img: {
    position: 'absolute',
    top: 6,
    left: 40,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(0.75),
  },
  title: {
    width: 'fit-content',
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    borderBottom: '2px dashed #FEFF6D',
    margin: 'auto',
  },
}))

export default function FreeTermExtensionChallengeBanner (props) {
  const { missions, ...others } = props
  const classes = useStyles()
  const [openFreeTermExtensionChallengeDialog, setOpenFreeTermExtensionChallengeDialog] = useState(false)
  const [openFreeTermExtensionChallengeAchievedDialog, setOpenFreeTermExtensionChallengeAchievedDialog] = useState(false)

  // フレンド申請ミッション抽出
  const friendApplyMission = missions.find(mission => mission.mission_type === 'friend_apply')
  // トーク開通ミッション抽出
  const chatOpenMission = missions.find(mission => mission.mission_type === 'chat_open')

  const onClick = () => {
    // 達成したミッション抽出
    const achievedMissions = missions.filter(mission => mission.achieved)
    // 全てのミッションをクリアしている場合、達成済みダイアログ表示
    // 未達成のミッションがある場合、進捗ダイアログ表示
    const achievedAll = missions.length === achievedMissions.length
    achievedAll ? setOpenFreeTermExtensionChallengeAchievedDialog(true) : setOpenFreeTermExtensionChallengeDialog(true)
  }

  return (
    <>
      <div className={classes.root} onClick={onClick}>
        <Grid container justifyContent="center" alignItems="center">
          <img src={FlagLeft} width={16.05} />
          <Typography className={classes.text}>
            無料トライアル期間 延長チャレンジ
          </Typography>
          <img src={FlagRight} width={16.05} />
        </Grid>
        <Typography className={classes.title}>
          チャレンジ達成で、無料期間+14日！
        </Typography>
      </div>
      <FreeTermExtensionChallengeDialog
        open={openFreeTermExtensionChallengeDialog}
        onClose={() => setOpenFreeTermExtensionChallengeDialog(false)}
        friendApplyMission={friendApplyMission}
        chatOpenMission={chatOpenMission}
        {...others}
      />
      <FreeTermExtensionChallengeAchievedDialog
        open={openFreeTermExtensionChallengeAchievedDialog}
        onClose={() => setOpenFreeTermExtensionChallengeAchievedDialog(false)}
        {...others}
      />
    </>
  )
}

FreeTermExtensionChallengeBanner.propTypes = {
  missions: PropTypes.array,
}
